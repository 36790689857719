import React from "react";

const IconLockViewfinder = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M21,8.75A.75.75,0,0,1,20.25,8V4.5c0-.589-.161-.75-.75-.75H16a.75.75,0,0,1,0-1.5h3.5A2.067,2.067,0,0,1,21.75,4.5V8A.75.75,0,0,1,21,8.75ZM3.75,8V4.5c0-.589.161-.75.75-.75H8a.75.75,0,0,0,0-1.5H4.5A2.067,2.067,0,0,0,2.25,4.5V8a.75.75,0,0,0,1.5,0Zm5,13A.75.75,0,0,0,8,20.25H4.5c-.589,0-.75-.161-.75-.75V16a.75.75,0,0,0-1.5,0v3.5A2.067,2.067,0,0,0,4.5,21.75H8A.75.75,0,0,0,8.75,21Zm13-1.5V16a.75.75,0,0,0-1.5,0v3.5c0,.589-.161.75-.75.75H16a.75.75,0,0,0,0,1.5h3.5A2.067,2.067,0,0,0,21.75,19.5Z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M7,16.143V11.857A1.894,1.894,0,0,1,9.143,9.714h5.714A1.894,1.894,0,0,1,17,11.857v4.286a1.894,1.894,0,0,1-2.143,2.143H9.143A1.894,1.894,0,0,1,7,16.143Z"
        />
        <path
          fill={fill}
          d="M10.083,9.714V8.667a1.917,1.917,0,0,1,3.834,0V9.714h.94a3.372,3.372,0,0,1,.56.047V8.667a3.417,3.417,0,0,0-6.834,0V9.761a3.372,3.372,0,0,1,.56-.047Z"
        />
        <path
          fill={fill}
          d="M13.27,13.333a1.25,1.25,0,0,0-1.25-1.25h-.01a1.246,1.246,0,0,0-1.245,1.25,1.228,1.228,0,0,0,.485.963v1.037a.75.75,0,0,0,1.5,0V14.321A1.23,1.23,0,0,0,13.27,13.333Z"
        />
      </g>
    </svg>
  );
};

export default IconLockViewfinder;
