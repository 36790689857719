import React from "react";
import withPurchase from "../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../components/ui";
import { formattedNumber } from "../../../../utils/formattedNumber";

const Result = ({ type, state, mobile }) => {
  return (
    <>
      <img
        src={require(`../../../../assets/img/generator/bank/sberbank/delivered/sberbank-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi
        type={type}
        mode={"dark"}
        {...mobile}
        colorBattery={state.theme === "light" ? "#0b8a5f" : "#054d35"}
      />

      <p className="amount">
        {formattedNumber(state.amount || 0.0).replace(/,/, ".")} ₽
      </p>
      <p className="full-name">{state.fullName || "____________________"}</p>
      <p className="full-name v2">{state.fullName || "____________________"}</p>
      <p className="last-card">{state.lastCard || "0000"}</p>
      <p className="recipient-card">{state.recipientCard || "0000"}</p>
    </>
  );
};

export default withPurchase(Result, {
  className: `result-img sberbank delivered`,
});
