import React from "react";

const IconLeaf = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M5.793,16.58A6.9,6.9,0,0,1,8.664,7.19c1.794-.96,3.609-1.15,5.8-1.9a5.973,5.973,0,0,0,2.9-2.2.189.189,0,0,1,.341.04c.7,1.89,3.519,10.64-2.336,14.84-2.957,2.12-6.556.87-8.07.17A3.839,3.839,0,0,1,5.793,16.58Z"
        />
        <path
          fill={fill}
          d="M6.294,21.75a.753.753,0,0,1-.734-.9A21.526,21.526,0,0,1,13.71,9.133a.75.75,0,1,1,.9,1.2A20.408,20.408,0,0,0,7.028,21.155.751.751,0,0,1,6.294,21.75Z"
        />
      </g>
    </svg>
  );
};

export default IconLeaf;
