import React from "react";

const IconPapersText = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          d="M9.942,17.407q-3.282-.579-2.7-3.862L8.581,5.929l0-.007-3.789.667q-3.283.583-2.7,3.867l1.545,8.756q.565,3.284,3.855,2.7l6.567-1.156c1.534-.277,2.445-1,2.689-2.155l-.007-.012c-.078-.011-.149-.011-.229-.025Z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M19.209,3.244,12.644,2.087q-3.283-.579-3.862,2.7l-.2,1.138L7.238,13.545q-.578,3.283,2.7,3.862l6.566,1.157c.08.014.151.014.229.025,2.044.281,3.261-.621,3.633-2.729l1.543-8.754Q22.492,3.824,19.209,3.244Z"
        />
        <path
          fill={fill}
          d="M17.859,11.654a.713.713,0,0,1-.13-.011l-6.566-1.158a.75.75,0,0,1,.26-1.477l6.565,1.158a.75.75,0,0,1-.129,1.488ZM19.3,7.773a.75.75,0,0,0-.609-.869L12.123,5.747a.75.75,0,0,0-.26,1.477l6.566,1.158a.732.732,0,0,0,.131.011A.749.749,0,0,0,19.3,7.773ZM15.554,13.9a.75.75,0,0,0-.609-.869l-4.1-.724a.75.75,0,0,0-.26,1.478l4.1.723a.715.715,0,0,0,.13.012A.75.75,0,0,0,15.554,13.9Z"
        />
      </g>
    </svg>
  );
};

export default IconPapersText;
