import React from "react";
import withPurchase from "../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../components/ui";
import { formattedNumber } from "../../../../utils/formattedNumber";

const Result = ({ type, state, mobile }) => {
  return (
    <>
      <img
        src={require(`../../../../assets/img/generator/bank/sberbank/card/sberbank-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <p className="amount">{formattedNumber(state.amount || 0.0)} ₽</p>
      <p className="last-card">{state.lastCard || "0000"}</p>
      <p className="last-bill">{state.lastBill || "0000"}</p>
    </>
  );
};

export default withPurchase(Result, {
  className: `result-img sberbank card`,
});
