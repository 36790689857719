import React from "react";
import CoinsList from "./CoinsList";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

function Result({ type, state, mobile, coins, coinsValues, balance }) {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/htx/wallet/main/htx-main-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <div className="balance-asset">${balance.toFixed(2)}</div>
      <div className="balance-asset v2">${balance.toFixed(2)}</div>

      <div className="pnl-asset">
        {state.generalPnlUsd || "+$0.00"}
        <span>/{state.generalPnlRate || "+0.00%"}</span>
      </div>

      <div className="pnl-asset v2">
        {state.generalPnlUsd || "+$0.00"}/{state.generalPnlRate || "+0.00%"}
      </div>

      <CoinsList coins={coins} coinsValues={coinsValues} />
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img htx wallet main",
});
