import React from "react";
import { Layout } from "../components/Layout";
import Banner from "../components/Home/Banner";
import WalletInfo from "../components/Home/WalletInfo";
import ExchangeInfo from "../components/Home/ExchangeInfo";
import Advantages from "../components/Home/Advantages";
import Other from "../components/Home/Other";

const Home = () => {
  return (
    <Layout section={"home"}>
      <Banner />
      <ExchangeInfo />
      <WalletInfo />
      <Other />
      <Advantages />
    </Layout>
  );
};

export default Home;
