import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Dropdown, Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { DocumentsContext } from "../contexts/DocumentsProvider";
import getDocName from "../utils/getDocTitle";
import { IconAngleDownSquare, IconCompiling } from "../utils/Icons";

const BreadcrumbNavigation = () => {
  const { t } = useTranslation();
  const basePath = "breadcrumb.";

  const history = useHistory();
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter(Boolean);

  const id = pathSnippets[pathSnippets.length - 1];

  const { getProductByApiId } = useContext(DocumentsContext);
  const product = getProductByApiId(Number(id));

  const title = getDocName(product);

  const sections = [
    {
      key: "exchange",
      label: t(`${basePath}list.sections.exchange`),
      subSections: [
        { key: "binance", label: "Binance" },
        { key: "bybit", label: "ByBit" },
        { key: "okx", label: "OKX" },
        { key: "htx", label: "HTX" },
      ],
    },
    {
      key: "wallets",
      label: t(`${basePath}list.sections.wallets`),
      subSections: [
        { key: "exodus", label: "Exodus" },
        { key: "trustwallet", label: "Trust Wallet" },
      ],
    },
    {
      key: "bank",
      label: t(`${basePath}list.sections.bank.title`),
      subSections: [
        {
          key: "sberbank",
          label: t(`${basePath}list.sections.bank.subSections.sberbank`),
        },
        {
          key: "alfa",
          label: t(`${basePath}list.sections.bank.subSections.alfa`),
        },
      ],
    },
    {
      key: "all-docs",
      label: t(`${basePath}list.sections.allDocs`),
    },
  ];

  const currentSection =
    sections.find((section) => section.key === pathSnippets[0]) || {};
  const currentSubSection =
    currentSection.subSections?.find((sub) => sub.key === pathSnippets[1]) ||
    {};

  const handleMenuClick = (path) => {
    history.push(path);
  };

  const sectionMenu = (
    <Menu>
      {sections.map((section) => (
        <Menu.Item
          key={section.key}
          onClick={() => handleMenuClick(`/${section.key}/`)}
        >
          {section.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="breadcrumb">
      <span className="title">
        <IconCompiling width="20" height="20" fill="#ababab" />
        {t(`${basePath}title`)}
      </span>
      <Breadcrumb separator={false}>
        <Breadcrumb.Item href="/">
          <span>{t(`${basePath}list.home`)}</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Dropdown overlay={sectionMenu}>
            <span>
              {currentSection.label || t(`${basePath}list.selectSection`)}
              <IconAngleDownSquare width="16" height="16" fill="#ababab" />
            </span>
          </Dropdown>
        </Breadcrumb.Item>
        {currentSubSection.key && (
          <Breadcrumb.Item>{currentSubSection.label}</Breadcrumb.Item>
        )}
        {pathSnippets[2] && <Breadcrumb.Item>{title}</Breadcrumb.Item>}
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbNavigation;
