import React from "react";
import { Button, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { Header } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";
import AuthModal from "./Auth/Auth";
import { languages } from "../i18n";
import { useLanguage } from "../contexts/LanguageContext";
import { IconLogIn, IconUser } from "../utils/Icons";
import useAuth from "../hooks/useAuth";
import { DropdownBtn, SpinUi } from "./ui/index";

const langIcons = {
  "ru-RU": require("../assets/img/flags/ru.png"),
  "en-US": require("../assets/img/flags/en.png"),
};

const NavBar = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "navBar.";

  const { changeLanguage } = useLanguage();
  const activeLang = localStorage.getItem("i18nextLng");
  const { user, isUserLoading, logout, showAuthModal, setAuthShowModal } =
    useAuth();

  const languagesArr = languages.map((item, index) => ({
    key: index,
    label: (
      <span className="item-lang">
        <img src={langIcons[item]} alt="" />
        {t(`${basePath}lang.${item}`)}
      </span>
    ),
  }));

  const onLangChange = ({ key }) => {
    const lang = languages[+key];
    localStorage.setItem("i18nextLng", lang);
    changeLanguage(lang);
  };

  const onUserNavClick = ({ key }) => {
    if (key === "1") {
      logout();
    }
  };

  const items = [
    {
      key: "0",
      label: <Link to="/profile">{t(`${basePath}dropDown.profile`)}</Link>,
      icon: <IconUser width="18" height="18" fill="#ababab" />,
    },
    {
      key: "1",
      label: t(`${basePath}dropDown.logout`),
      icon: <IconLogIn width="18" height="18" fill="#ababab" />,
    },
  ];

  const balances = user?.balances?.[0]?.balance;

  return (
    <>
      <Header className="nav-bar">
        <Link to="/" className="logo">
          <img src={require("../assets/img/logo.png")} alt="" />
          <span>scanly</span>
        </Link>
        <div className="right-side">
          {!isUserLoading && !user && (
            <Button
              type="dashed"
              onClick={() => {
                setAuthShowModal(true);
              }}
            >
              {t(`${basePath}auth`)}
              <IconLogIn width="20" height="20" fill="#00c39a" />
            </Button>
          )}

          {isUserLoading && <SpinUi />}

          {user && (
            <>
              <Dropdown
                menu={{ items, onClick: onUserNavClick }}
                trigger={["click"]}
                className="user-menu-btn"
              >
                <DropdownBtn>
                  <div>{user.login}</div>
                </DropdownBtn>
              </Dropdown>

              <div className="balance">
                Баланс:
                <s>$ {Number(balances).toFixed(2)}</s>
              </div>
            </>
          )}

          <Dropdown
            menu={{ items: languagesArr, onClick: onLangChange }}
            trigger={["click"]}
          >
            <DropdownBtn>{t(`${basePath}lang.${activeLang}`)}</DropdownBtn>
          </Dropdown>
        </div>
      </Header>
      <AuthModal
        showAuthModal={showAuthModal}
        setAuthShowModal={setAuthShowModal}
      />
    </>
  );
};

export default NavBar;
