import React from "react";
import CurrencyList from "./CurrencyList";
import withPurchase from "../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../components/ui";
import { formattedNumber } from "../../../../utils/formattedNumber";

function Result({ type, state, mobile, coins, coinsValues, balance }) {
  return (
    <>
      <img
        src={require(`../../../../assets/img/generator/wallets/trustwallet/main/main-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <div className="name-wallet">{state.nameWallet || "___________"}</div>

      <div className="amount">
        {formattedNumber(balance || 0, { max: 2 })} $
      </div>

      <CurrencyList
        coins={coins}
        coinsValues={coinsValues}
        mode={state.theme}
      />
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img trust main",
});
