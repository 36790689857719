import React from "react";
import { sortedCoins } from "../../../../../utils/SortedCoins";

const CoinsList = ({ coins, coinsValues }) => {
  if (!coins?.length) {
    return null;
  }

  const getSrc = (coin) => coin?.icon_okx || coin?.icon_bybit;

  return (
    <ul className="coin-list">
      {sortedCoins(coins, coinsValues).map((coin) => {
        return (
          <li className="item" key={coin.key}>
            <div className="info">
              <div className="icon">
                <img className="icon-img" src={getSrc(coin)} alt="" />
              </div>
              <div className="title">{coin.value}</div>
            </div>
            <div className="balance">
              <span className="usdt">
                ${coinsValues[coin.key]?.totalPrice || 0}
              </span>
              <span className="value">
                {coinsValues[coin.key]?.amount || 0}
              </span>
            </div>
            <div className={`pnl`}>
              <span
                className={`usd ${
                  coin.pnlUsd?.charAt(0) === "-" ? "red" : "green"
                }`}
              >
                {coin.pnlUsd || "+$0.00"}
              </span>
              <span
                className={`rate ${
                  coin.pnlRate?.charAt(0) === "-" ? "red" : "green"
                }`}
              >
                {coin.pnlRate || "+0.00%"}
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default CoinsList;
