import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

function Result({ type, state, mobile }) {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/htx/mail/deposit/htx-deposit-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <div className="time">
        {state?.time ? dayjs(state?.time).format("HH:mm") : "00:00"}
      </div>
      <div className="contents">
        Уважаемый пользыватель,
        <br />
        Вы успешно сделали депозит в размере{" "}
        <b>
          {state.amount || 0} <s>{state.currency}</s>
        </b>{" "}
        на своем биржевом аккаунте.
        <br />
        Команда HTX
        <br />
        Это автоматическое сообщение, не отвечайте.
      </div>
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img htx mail deposit",
});
