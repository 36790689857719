import React from "react";

const IconSlidersVertical = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M5,22a1,1,0,0,1-1-1V16a1,1,0,0,1,2,0v5A1,1,0,0,1,5,22Z"
        />
        <path fill={fill} d="M7,17H3a1,1,0,0,1,0-2H7a1,1,0,0,1,0,2Z" />
        <path
          fill={fill}
          opacity={0.3}
          d="M5,13a1,1,0,0,1-1-1V3A1,1,0,0,1,6,3v9A1,1,0,0,1,5,13Z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M12,22a1,1,0,0,1-1-1V12a1,1,0,0,1,2,0v9A1,1,0,0,1,12,22Z"
        />
        <path fill={fill} d="M14,9H10a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z" />
        <path
          fill={fill}
          opacity={0.3}
          d="M12,9a1,1,0,0,1-1-1V3a1,1,0,0,1,2,0V8A1,1,0,0,1,12,9Z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M19,22a1,1,0,0,1-1-1V16a1,1,0,0,1,2,0v5A1,1,0,0,1,19,22Z"
        />
        <path fill={fill} d="M21,17H17a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z" />
        <path
          fill={fill}
          opacity={0.3}
          d="M19,13a1,1,0,0,1-1-1V3a1,1,0,0,1,2,0v9A1,1,0,0,1,19,13Z"
        />
      </g>
    </svg>
  );
};

export default IconSlidersVertical;
