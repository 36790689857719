import React from "react";

const IconRadiation = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M16.06,20.01a8.82,8.82,0,0,1-8.12,0,.507.507,0,0,1-.189-.7l2.439-4.32a3.467,3.467,0,0,0,3.62,0l2.439,4.32A.507.507,0,0,1,16.06,20.01Z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M21,11.25a.5.5,0,0,1-.51.53h-5A3.487,3.487,0,0,0,13.62,8.9l2.629-4.64a.492.492,0,0,1,.7-.18A9.254,9.254,0,0,1,21,11.25Z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M10.38,8.9a3.487,3.487,0,0,0-1.87,2.88h-5A.5.5,0,0,1,3,11.25a9.254,9.254,0,0,1,4.05-7.17.492.492,0,0,1,.7.18Z"
        />
        <circle fill={fill} cx="12" cy="12" r="1.25" />
      </g>
    </svg>
  );
};

export default IconRadiation;
