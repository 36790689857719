import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

function Result({ type, state, mobile, amount }) {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/okx/wallet/deposit/okx-deposit-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />
      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <div className="amount">
        +&nbsp;&nbsp;{amount || 0}&nbsp;&nbsp;{state?.currency}
      </div>
      <div className="info">
        <div className="blockchain">{state?.blockchain || "______"}</div>
        <div className="type">Пополнение</div>
        <div className="status">Получено</div>
        <div className="domain">
          {state?.addressDomain || "_______________"}
        </div>
        <div className="transactionId">
          {state?.transactionID || "_______________"}
        </div>
        <div className="date">
          {state?.time
            ? dayjs(state?.time).format("DD.MM.YYYY, HH:mm:ss")
            : "2024-01-01 00:00:00"}
        </div>
      </div>
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img okx wallet deposit",
});
