import { useEffect, useState } from "react";
import { binanceApi } from "../api";

export const useCurrencyCourse = (currencySymbol) => {
  const [course, setCourse] = useState({});

  const fixUSDT = currencySymbol === "USDT" ? "USDC" : currencySymbol;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await binanceApi.get(`/ticker/24hr?symbol=${fixUSDT}USDT`);
        setCourse(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [fixUSDT]);

  return course;
};
