import React from "react";

const IconBolt = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M18.384,9.426A1.476,1.476,0,0,0,17,8.5l-2.857.07,1.715-3.976A1.5,1.5,0,0,0,14.479,2.5H10A1.882,1.882,0,0,0,8.2,3.843L5.578,10.525A1.5,1.5,0,0,0,7,12.5l2.824-.066-2.885,8.4a.5.5,0,0,0,.827.516L18.059,11.061A1.479,1.479,0,0,0,18.384,9.426Z"
        />
      </g>
    </svg>
  );
};

export default IconBolt;
