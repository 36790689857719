import React from "react";

const IconIDcard = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M3,16.5v-9a2.652,2.652,0,0,1,3-3H18a2.652,2.652,0,0,1,3,3v9a2.652,2.652,0,0,1-3,3H6A2.652,2.652,0,0,1,3,16.5Z"
        />
        <path
          fill={fill}
          d="M6.856,9.65a2.15,2.15,0,1,1,2.15,2.15A2.152,2.152,0,0,1,6.856,9.65ZM9.642,13H8.358A2.687,2.687,0,0,0,5.5,15.7V16a.5.5,0,0,0,.5.5h6a.5.5,0,0,0,.5-.5v-.3A2.687,2.687,0,0,0,9.642,13Z"
        />
        <path
          fill={fill}
          d="M18,10.688H14a.75.75,0,0,1,0-1.5h4a.75.75,0,0,1,0,1.5Z"
        />
        <path
          fill={fill}
          d="M17.95,14.75h-3a.75.75,0,0,1,0-1.5h3a.75.75,0,0,1,0,1.5Z"
        />
      </g>
    </svg>
  );
};

export default IconIDcard;
