import { useQuery } from "@tanstack/react-query";
import { scanlyApi, SCANLY_API_ROUTES } from "../api";

const useUsers = ({ params, settings = {} }) => {
    const queryFn = async () => {
        return await scanlyApi.get(
            SCANLY_API_ROUTES.USERS,
            { params: { ...params, search: params?.search || undefined }
        })  
    };

    return useQuery({
        queryKey: ["useUsers", params?.page, params?.id, params?.search],
        queryFn,
        refetchOnWindowFocus: false,
        ...settings,
    });
};

export default useUsers;