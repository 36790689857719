import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Typography, Row, Col } from "antd";
import useMessage from "../../../../hooks/useMessage";
import useBoostBalance from "../../../../hooks/useBoostBalance";
import { AlertUi, InputNumberUi } from "../../../../components/ui";

const { Title } = Typography;

const BoostBalance = ({ data, refetch }) => {
  const { t } = useTranslation();
  const basePath = "user.admin.boost.";

  const [form] = Form.useForm();
  const [balance, setBalance] = useState(0);

  const { showSuccessMessage, showErrorMessage } = useMessage();

  const onSuccess = () => {
    refetch();
    setBalance(0);
    form.resetFields();
    showSuccessMessage(t(`${basePath}boostSuccess`));
  };

  const onError = (err) => {
    console.error(err);
    showErrorMessage(t(`${basePath}boostError`));
  };

  const { mutate, isPending } = useBoostBalance({ onSuccess, onError });

  const onFinish = () => {
    mutate({ balance });
  };

  return (
    <div className="box boost">
      <Title level={4}>{t(`${basePath}title`)}</Title>

      <Form
        form={form}
        name="user-balance"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="balance">
              {t(`${basePath}balance`)}
              <span>{`$ ${data.balance}`}</span>
            </div>
          </Col>

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <InputNumberUi
              name="balance"
              label={t(`${basePath}amount`)}
              placeholder={100}
              onChange={(value) => setBalance(value)}
              errorMess={t(`formMain.message.requiredField`)}
            />
          </Col>

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <AlertUi type="info" text={t(`${basePath}info`)} />
          </Col>

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="btn-group mt-30">
              <Button
                loading={isPending}
                type="dashed"
                htmlType="submit"
                disabled={!balance}
              >
                {t(`${basePath}btn`)}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BoostBalance;
