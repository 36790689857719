import React, { useEffect, useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import { Layout } from "../../components/Layout";
import useAuth from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";
import gsap from "gsap";
import { useTranslation } from "react-i18next";
import {
  IconAlarmClock,
  IconDollarReceive,
  IconPlusCircle,
} from "../../utils/Icons";
import { BINANCE_ARRAY } from "../../constants/ExchangeArray";
import { ActionValue } from "../../components/ui";
import PurchaseHistoryItems from "./components/PurchaseHistoryItems";
import usePurchaseHistory from "../../hooks/usePurchaseHistory";

const { Title, Text } = Typography;

const Profile = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "user.profil.";
  const history = useHistory();

  const onRefillClick = (currency) =>
    history.push(`refill${currency ? `?currency=${currency}` : ""}`);

  const { user } = useAuth();
  const { data } = usePurchaseHistory();

  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    let mouseX = 0;
    let mouseY = 0;

    const handleEvent = (e) => {
      mouseX = (e.clientX / sizes.width) * 2 - 1;
      mouseY = -(e.clientY / sizes.height) * 2 + 1;

      gsap.to(".user-banner img", {
        x: -mouseX * 15,
        y: mouseY * 15,
      });
    };

    window.addEventListener("mousemove", handleEvent);

    return () => {
      window.removeEventListener("mousemove", handleEvent);
    };
  });

  useEffect(() => {
    const updateGreeting = () => {
      const currentHour = new Date().getHours();

      if (currentHour < 12) {
        setGreeting(t(`${basePath}welcome.morning`));
      } else if (currentHour < 18) {
        setGreeting(t(`${basePath}welcome.afternoon`));
      } else {
        setGreeting(t(`${basePath}welcome.evening`));
      }
    };

    updateGreeting();

    const intervalId = setInterval(updateGreeting, 60000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filter = (items) => items?.filter(item => !item.delete);

  return (
    <Layout section={"user"} exact={false}>
      <div className="layout cabinet">
        <Row>
          <Col xl={16} lg={24} md={24} sm={24} xs={24}>
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="box banner">
                  <div className="info">
                    <Title level={3}>
                      {greeting}, {user?.login}
                    </Title>
                    <div className="balance">
                      <Text>{t(`${basePath}balance`)}:</Text>
                      {user?.balances.map(({ currency, balance }) => (
                        <ActionValue
                          value={`$ ${balance}`}
                          action={() => onRefillClick(currency)}
                          btnTxt={t(`${basePath}refill`)}
                          icon={
                            <IconDollarReceive
                              width="22"
                              height="22"
                              fill="#00c39a"
                            />
                          }
                        />
                      ))}
                    </div>
                    <div className="purchase">
                      <Text>
                        {t(`${basePath}quantityPurchases`)}:
                        <s>{data?.data?.data.length}</s>
                      </Text>
                    </div>
                  </div>
                  <div className="user-banner">
                    <img
                      src={require("../../assets/img/banner-box.svg").default}
                      alt=""
                    />
                  </div>
                </div>
              </Col>

              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className="box top soon">
                  <Title level={4}>{t(`${basePath}top`)}</Title>
                  <div className="items">
                    {BINANCE_ARRAY.slice(0, 3).map((item, i) => (
                      <div className="card-item main" key={`binance-${i}`}>
                        <div className="icon">
                          <span></span>
                          <i>{item.icon}</i>
                        </div>
                        <div className="info">
                          <h5>{item.title}</h5>
                          <span>
                            {t(`card.os`)}: {item.system}
                          </span>
                        </div>
                        <div className="price">
                          <div className="award">
                            <img
                              src={require(`../../assets/img/icon/top-${i}.svg`)}
                              alt=""
                            />
                          </div>
                          <span>
                            {t(`card.price`)}: <s>$10</s>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="coming">
                    <Text>
                      <IconAlarmClock width="26" height="26" fill="#00c39a" />
                      Coming soon
                    </Text>
                  </div>
                </div>
              </Col>

              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className="box propose soon">
                  <Title level={4}>{t(`${basePath}propose.title`)}</Title>
                  <div className="add-document">
                    <div className="icon">
                      <IconPlusCircle
                        width="70px"
                        height="70px"
                        fill="#00c39a"
                      />
                      <svg
                        className="circle"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 120 120"
                      >
                        <circle
                          cx="60"
                          cy="60"
                          r="50"
                          className="dotted"
                        ></circle>
                      </svg>
                    </div>
                    <Button type="dashed">{t(`${basePath}propose.btn`)}</Button>
                  </div>
                  <div className="coming">
                    <Text>
                      <IconAlarmClock width="26" height="26" fill="#00c39a" />
                      Coming soon
                    </Text>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xl={8} lg={24} md={24} sm={24} xs={24}>
            <div className="box operations">
              <Title level={4}>{t(`${basePath}lastPurchases`)}</Title>
              <PurchaseHistoryItems filter={filter} slice={[0, 10]} />
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Profile;
