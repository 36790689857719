import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row } from "antd";
import useAuth from "../../../../hooks/useAuth";
import useMessage from "../../../../hooks/useMessage";
import getDocName from "../../../../utils/getDocTitle";
import usePurchase from "../../../../hooks/usePurchase";
import { DocumentsContext } from "../../../../contexts/DocumentsProvider";
import SignUpModal from "../../../../components/Modal/SignUpModal";
import PurchaseConfirmModal from "../../../../components/Modal/PurchaseConfirmModal";
import { CRYPTOCURRENCY_ARRAY } from "../../../../constants/CryptocurrencyArray";
import withLayout from "../../../../hoc/withLayout";
import DynamicForm from "./DynamicForm";
import Result from "./Result";
import {
  TitlePageUi,
  HeaderPhoneFormUi,
  SwitchUi,
  SpinUi,
  CollapseUi,
} from "../../../../components/ui";
import {
  IconAndroid,
  IconApple,
  IconExchangeHorizontal,
  IconFileText,
  IconiPhone,
  IconLightbulb,
  IconMoon,
  IconTrustWallet,
} from "../../../../utils/Icons";

const Generator = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "wallets.form.";
  const { type, id } = useParams();
  const { user } = useAuth();

  const { getProductByApiId, isLoading } = useContext(DocumentsContext);
  const product = getProductByApiId(Number(id));

  const [mobile, setMobile] = useState({
    battery: 50,
    charge: false,
    mobNetwork: 1,
    mobConnect: "none-connect",
    timeScreen: null,
    eSim: false,
    additional: null,
  });

  const [state, setState] = useState({
    theme: "light",
    amount: null,
    currency: CRYPTOCURRENCY_ARRAY[0],
  });

  const [transferList, setTransferList] = useState([
    {
      date: null,
      amount: null,
      wallet: null,
      type: null,
      today: false,
    },
  ]);

  const { showErrorMessage } = useMessage();

  const onChangeTheme = (checked) => {
    setState((prevState) => ({
      ...prevState,
      theme: checked ? "light" : "dark",
    }));
  };

  const title = getDocName(product);

  // Purchase start >>>>>>>>>

  const onCancel = () => setConfirmModalOpen(false);

  const errorForm = (err) => {
    showErrorMessage(
      t(
        err?.response?.data?.message
          ? `apiErrors.${err?.response?.data?.message}`
          : `formMain.message.error`
      )
    );
  };

  const {
    showWatermark,
    mutate,
    processing,
    signUpModalOpen,
    closeSignUpModal,
    openSignUpModal,
    confirmModalOpen,
    setConfirmModalOpen,
  } = usePurchase({ product, onError: errorForm });

  const onConfirm = () => {
    onCancel();
    mutate({
      id,
      type,
      state,
      mobile,
      transferList,
    });
  };

  const successForm = () => setConfirmModalOpen(true);

  // Purchase end <<<<<<<<<

  if (isLoading || !product) {
    return (
      <div className="spin-loader">
        <SpinUi size="54" />
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <TitlePageUi
            name={title}
            addonIcon={
              <IconTrustWallet width="24" height="24" fill="#00c39a" />
            }
            suffixIcon={
              <div className="price">
                {t(`formMain.price`)}
                {product.price_with_discount ? (
                  <div className="price-value">
                    <s>${product.price}</s>{" "}
                    <i>${product.price_with_discount}</i>
                  </div>
                ) : (
                  <div className="price-value">${product.price}</div>
                )}
              </div>
            }
          />
        </Col>
      </Row>
      <div className="custom-row">
        <div className="form box">
          <Form
            name="twh"
            layout="vertical"
            onFinish={user ? successForm : openSignUpModal}
            onFinishFailed={errorForm}
            autoComplete="off"
          >
            <Row align="middle">
              <Col lg={24} md={24} sm={24} xs={24}>
                <h6 className="text-center-sm mb-30">{t(`formMain.title`)}</h6>
              </Col>

              <Col lg={12} md={12} sm={12} xs={24}>
                <div className="system_title">
                  <p>{t(`formMain.system`)}</p>
                  <span>
                    {type === "ios" ? (
                      <s>
                        iOS <IconApple width="22" height="22" fill="#00c39a" />
                      </s>
                    ) : (
                      <s>
                        Android{" "}
                        <IconAndroid width="22" height="22" fill="#00c39a" />
                      </s>
                    )}
                  </span>
                </div>
              </Col>

              <Col lg={12} md={12} sm={12} xs={24}>
                <SwitchUi
                  name="theme-switch"
                  label={t(`formMain.theme.title`)}
                  beforeLabel={t(`formMain.theme.dark`)}
                  afterLabel={t(`formMain.theme.light`)}
                  checkedIcon={
                    <IconLightbulb width="15" height="15" fill="#000" />
                  }
                  unCheckedIcon={
                    <IconMoon width="15" height="15" fill="#fff" />
                  }
                  onChange={onChangeTheme}
                />
              </Col>
            </Row>

            <CollapseUi
              defaultActiveKey={["1", "2"]}
              className="form-collapse"
              items={[
                {
                  key: 1,
                  label: (
                    <>
                      <IconiPhone width="20" height="20" fill="#ababab" />
                      {t(`formMain.formCollapse.phone`)}
                    </>
                  ),
                  children: (
                    <HeaderPhoneFormUi
                      type={type}
                      setMobileValue={(field, value) =>
                        setMobile((prevState) => ({
                          ...prevState,
                          [field]: value,
                        }))
                      }
                    />
                  ),
                },
                {
                  key: 2,
                  label: (
                    <>
                      <IconFileText width="20" height="23" fill="#ababab" />
                      {t(`formMain.formCollapse.content`)}
                    </>
                  ),
                  children: (
                    <Row className="w-full">
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <h6 className="subtitle">
                          <IconExchangeHorizontal
                            width="24"
                            height="24"
                            fill="#00c39a"
                          />
                          {t(`${basePath}trust.list.title`)}
                        </h6>
                        <DynamicForm
                          transferList={transferList}
                          setTransferList={setTransferList}
                        />
                      </Col>
                    </Row>
                  ),
                },
              ]}
            />

            <div className="btn-group mt-30">
              <div className="btn-persv">
                <Button htmlType="submit">{t(`formMain.btn.png`)}</Button>
              </div>
            </div>
          </Form>
        </div>

        {/* Result Component */}
        <Result
          id={id}
          type={type}
          state={state}
          mobile={mobile}
          transferList={transferList}
          processing={processing}
          showWatermark={showWatermark}
        />

        {/* Modal Unregistered User */}
        <SignUpModal showModal={signUpModalOpen} onCancel={closeSignUpModal} />

        {/* Modal Purchase Confirm */}
        <PurchaseConfirmModal
          product={product}
          showModal={confirmModalOpen}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      </div>
    </>
  );
};

export default withLayout(Generator, "gen-page");
