import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

const Result = ({ type, state, mobile }) => {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/binance/mail/deposit//binance-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <h4 className="time-arrival">
        [Binance] {state.currency} Deposit Confirmed <br />-{" "}
        {state.fullDate
          ? dayjs(state.fullDate).format("YYYY-MM-DD HH:mm:ss")
          : "2024-01-01 00:00:00"}
        (UTC)
        <span>Входящие</span>
      </h4>
      <h6 className="time-mail">
        {state.timeMail ? dayjs(state.timeMail).format("HH:mm") : "00:00"}
      </h6>
      <h5 className="status">{state.currency} Deposit Successful</h5>
      <p className="text">
        Your deposit of {state.amount || 0} {state.currency} is now available in
        your Binance account. Log in to check your balance. Read our{" "}
        <span>FAQs</span> if you are running into problems.
      </p>
      <p className="text v2">
        Don't recognize this activity? Please <span>reset your password</span>{" "}
        and contact <span>customer support</span> immediately.
      </p>
      <p className="text v3">
        This is an automated message, please do not reply.
      </p>
    </>
  );
};

export default withPurchase(Result, {
  className: "result-img binance mail deposit ",
});
