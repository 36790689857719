import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Radio, Space, Button, Form } from "antd";
import { InputUi, SpinUi } from "../../../../components/ui";
import useUsers from "../../../../hooks/useUsers";

const FilterUserDropdown = ({ setPurchaseParams, purchaseParams }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [userId, setUserID] = useState(null);
  const [params, setParams] = useState({
    page_size: 5,
  });

  const onChange = (value) => {
    setParams({ ...params, search: value.target.value });
  };

  const { data, isFetching } = useUsers({
    params,
  });

  const onFilter = () => {
    setPurchaseParams({ ...purchaseParams, user_id: userId });
  };

  const users = data?.data?.data;

  const onReset = () => {
    setPurchaseParams({ ...purchaseParams, user_id: undefined });
    setParams({ ...params, search: undefined });
    setUserID(null);
    form.resetFields();
  };

  return (
    <div className="filter-user-dropdown">
      <div>
        <Form form={form} name="search" layout="vertical" autoComplete="off">
          <InputUi
            name="user"
            type="test"
            placeholder={t("user.admin.purchaseList.table.user")}
            onChange={onChange}
            required={false}
          />
        </Form>
      </div>
      {isFetching && <SpinUi />}
      {!isFetching && Boolean(users.length) && (
        <Radio.Group
          onChange={(evt) => setUserID(evt.target.value)}
          value={userId}
        >
          <Space direction="vertical">
            {users.map((user) => (
              <Radio value={user.id}>{user.login}</Radio>
            ))}
          </Space>
        </Radio.Group>
      )}
      <div className="actions">
        <Button onClick={onReset} size="small" type="dashed">
          {t("shared.reset")}
        </Button>
        <Button
          disabled={isFetching || !users.length || !userId}
          onClick={onFilter}
          size="small"
          type="primary"
        >
          {t("shared.filter")}
        </Button>
      </div>
    </div>
  );
};

export default FilterUserDropdown;
