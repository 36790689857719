import React from "react";

const IconAngleRight2 = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={width}
      width={height}
      viewBox="0 0 512 512"
      {...props}
    >
      <polygon
        fill={fill}
        points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 "
      />
    </svg>
  );
};

export default IconAngleRight2;
