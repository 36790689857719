import React from "react";
import { Route, Redirect } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { SpinUi } from "../index";

const PrivateRoute = ({ children, additionalRedirectRule, ...rest }) => {
  const { user, token, isUserLoading } = useAuth();
  const additionalRedirect =
    typeof additionalRedirectRule !== "undefined"
      ? additionalRedirectRule()
      : false;
  const isRedirect = additionalRedirect || !token;

  if (isUserLoading && !user) {
    return (
      <div className="page-loader-wrapper">
        <SpinUi size={54} />
      </div>
    );
  }

  if (user && token && !isRedirect) {
    return <Route {...rest}>{children}</Route>;
  }

  if (isRedirect) {
    return <Redirect to="/" />;
  }

  return null;
};

export default PrivateRoute;
