import { IconAngleDown } from "../../../utils/Icons";

const DropdownBtn = ({ children, className, ...props }) => {
  return (
    <button type="button" className={`dropdown-btn ${className}`} {...props}>
      {children}
      <span className="arrow">
        <IconAngleDown width="14" height="14" fill="#00c39a" />
      </span>
    </button>
  );
};

export default DropdownBtn;
