import React from "react";
import CoinsList from "./CoinsList";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

function Result({ type, state, mobile, balance, coins, coinsValues }) {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/okx/wallet/balance/okx-balance-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <div className="value">
        <span>
          {`${balance.toFixed(2)}`.replace(".", ",")}
          <span className="currency">USD</span>
        </span>
      </div>

      <CoinsList coins={coins} coinsValues={coinsValues} mode={state.theme} />
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img okx wallet balance",
});
