import { useState } from "react";
import { Col, Row, Table, Tooltip, Input } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IconEdit,
  IconRadiation,
  IconTimesSquare,
  IconUsersGroup,
} from "../../../../utils/Icons";

const { Search } = Input;

const UserList = ({ users, params, setParams, isLoading, total }) => {
  // Translation
  const { t } = useTranslation();
  const basePath = "user.admin.usersList.table.";

  const [search, setSearch] = useState(null);
  const searchHandle = (search) => {
    setSearch(search);
    setParams((prevParams) => ({ ...prevParams, search }));
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (id) => <span># {id}</span>,
    },
    {
      title: t(`${basePath}login`),
      dataIndex: "login",
      key: "login",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: t(`${basePath}balance`),
      dataIndex: "balance",
      key: "balance",
      render: (balance) => `$ ${Number(balance).toFixed(2)}`,
    },
    {
      title: t(`${basePath}actions.title`),
      dataIndex: "actions",
      key: "actions",
      width: 100,
      fixed: "right",
      render: (login, record) => (
        <span className="edit">
          <Tooltip placement="top" title={t(`${basePath}actions.tooltip`)}>
            <Link to={`users/${record.id}`}>
              <IconEdit width="20" height="20" fill="#00a3e1" />
            </Link>
          </Tooltip>
        </span>
      ),
    },
  ];

  const onPaginationChange = (page) => {
    setParams({ ...params, page });
  };

  return (
    <Row>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <div className="title-head">
          <h4>
            <i>
              <IconUsersGroup width="24" height="24" fill="#00c39a" />
            </i>
            <b>{t(`${basePath}title`)}</b>
          </h4>
          <Search
            placeholder={t(`${basePath}search`)}
            enterButton
            allowClear={{
              clearIcon: (
                <IconTimesSquare width="14" height="14" fill="#d94c48" />
              ),
            }}
            value={search}
            onSearch={searchHandle}
            onChange={(evt) => searchHandle(evt.target.value)}
          />
          <s></s>
        </div>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Table
          loading={{
            spinning: isLoading,
            indicator: <IconRadiation width="46" height="46" fill="#00c39a" />,
          }}
          dataSource={users}
          columns={columns}
          pagination={
            !isLoading
              ? {
                  pageSize: params.page_size,
                  current: params.page,
                  total: total,
                  showSizeChanger: false,
                  onChange: onPaginationChange,
                  position: ["bottomCenter"],
                }
              : false
          }
          scroll={{ x: 1300 }}
        />
      </Col>
    </Row>
  );
};

export default UserList;
