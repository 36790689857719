import React from "react";

const IconHTX = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 136 220"
      {...props}
    >
      <g>
        <path
          fill={fill}
          d="M88.9,72.1C89.5,43,73,17.7,64.4,11.6c0,0-0.8-0.4-0.7,0.7c0,0,0,0,0,0C62.8,67.2,34.5,82,19.2,102.2
		c-34.1,44.8-5.6,95.8,31.2,105.5c0.6,0.2,2.3,0.7,5.6,1.3c1.7,0.4,2.2-1.1,0.9-3.2c-4.4-7.6-12.2-20.6-13.8-37.2
		C39.6,130.1,88.2,105.9,88.9,72.1z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M107.4,90.7c-0.3-0.2-0.7-0.2-0.7,0.2c-0.8,7-8,21.5-17.5,35c-32,45.7-15.9,66.4-3.4,80.8
		c2.3,2.7,3.5,2.1,4.7,0.2c1.1-1.8,2.9-4.1,10.3-7.6c1.2-0.6,29.1-15.4,32.1-49.2C135.8,117.4,114.8,96.7,107.4,90.7z"
        />
      </g>
    </svg>
  );
};

export default IconHTX;
