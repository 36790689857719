import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Tabs, Input, Col, Empty } from "antd";
import { DocumentsContext } from "../contexts/DocumentsProvider";
import { Layout } from "../components/Layout";
import getDocName from "../utils/getDocTitle";
import { CardUi, ModalUi } from "../components/ui";
import {
  IconApple,
  IconBank,
  IconDocumentList,
  IconMoreHorizontal,
  IconScanner,
  IconTimesSquare,
  IconWallet,
} from "../utils/Icons";

import BinanceList from "./Exchange/Binance/BinanceList";
import BybitList from "./Exchange/Bybit/BybitList";
import OKXList from "./Exchange/OKX/OKXList";
import HTXList from "./Exchange/HTX/HTXList";
import TrustList from "./Wallets/TrustWallet/TrustList";
import ExodusList from "./Wallets/Exodus/ExodusList";
import SberbankList from "./Bank/Sberbank/SberbankList";
import AlfaList from "./Bank/Alfa/AlfaList";
import { SearchContext } from "../contexts/SearchProvider";

const { Search } = Input;

const AllDocs = () => {
  const { t } = useTranslation();
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [search, setSearch] = useState(null);

  const searchHandle = (value) => setSearch(value);

  const { products, getAllProducts } = useContext(DocumentsContext);
  const allProducts = getAllProducts(products);

  const { searchTerm } = useContext(SearchContext);

  useEffect(() => {
    setSearch(searchTerm);
  }, [searchTerm]);

  const [showModal, setShowModal] = useState(false);
  const productsSearch = !search
    ? []
    : allProducts.reduce((acc, product) => {
        if (
          product.name_en.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
          product.name_ru.toLowerCase().indexOf(search.toLowerCase()) >= 0
        ) {
          return [...acc, product];
        }
        return acc;
      }, []);

  const [exchangeData, setExchangeData] = useState(productsSearch[0]);

  return (
    <Layout section="all-docs">
      <Row>
        <Col lg={24} md={24} sm={24} xs={24} className="mb-15">
          <div className="title-head">
            <h4>
              <i>
                <IconDocumentList width="24" height="24" fill="#00c39a" />
              </i>
              <b>{t(`userNavBar.document`)}</b>
            </h4>
            <Search
              placeholder={t(`userNavBar.search`)}
              enterButton
              allowClear={{
                clearIcon: (
                  <IconTimesSquare width="14" height="14" fill="#d94c48" />
                ),
              }}
              value={search}
              onSearch={searchHandle}
              onChange={(evt) => searchHandle(evt.target.value)}
            />
            <s></s>
          </div>
        </Col>
      </Row>
      {Boolean(search && !productsSearch.length) && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
      {!search ? (
        <Tabs
          className="big-size"
          type="card"
          activeKey={activeTabKey}
          onChange={setActiveTabKey}
        >
          <Tabs.TabPane
            key="1"
            tab={
              <>
                <IconScanner width="20" height="20" fill="#fff" />
                {t(`home.itemList.exchanges.title`)}
              </>
            }
          >
            <Row>
              <BinanceList />
              <BybitList />
              <OKXList />
              <HTXList />
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            key="2"
            tab={
              <>
                <IconWallet width="20" height="20" fill="#fff" />
                {t(`home.itemList.wallet.title`)}
              </>
            }
          >
            <Row>
              <TrustList />
              <ExodusList />
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            key="3"
            tab={
              <>
                <IconBank width="20" height="20" fill="#fff" />
                {t(`bank.title`)}
              </>
            }
          >
            <Row>
              <SberbankList />
              <AlfaList />
            </Row>
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <Row>
          {productsSearch
            .filter((item) => item.active && !item.delete)
            .map((item, i) => (
              <Col
                lg={8}
                md={12}
                sm={24}
                xs={24}
                key={i}
                onClick={() => {
                  setExchangeData(item);
                  setShowModal(true);
                }}
              >
                <CardUi
                  type="main"
                  title={getDocName(item)}
                  price={item.price}
                  discountPrice={item.price_with_discount}
                  icon={item.icon}
                  preImg={item.preImg}
                />
              </Col>
            ))}
          <ModalUi
            title={getDocName(exchangeData)}
            showModal={showModal}
            setShowModal={() => {
              setShowModal(false);
            }}
          >
            <p>{t("bank.modal.label")}</p>
            <Row>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Link
                  to={`${exchangeData?.modal?.ios}/${exchangeData?.api_id}`}
                  className="system-item-modal"
                >
                  <IconApple width="18" height="18" fill="#00c39a" />
                  <h6>iOS</h6>
                  <span>
                    <IconMoreHorizontal width="24" height="24" fill="#2b2c2f" />
                  </span>
                </Link>
              </Col>
            </Row>
          </ModalUi>
        </Row>
      )}
    </Layout>
  );
};

export default AllDocs;
