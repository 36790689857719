import React from "react";

const IconTinkoff = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 102.2 93.3"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.4}
          d="M67.7,56.8H36.2v11.9c0,4.8,3.5,7.9,8.7,7.9c4.3,0,5.5,0.2,7.1,1.8c1.1-1.1,2-1.5,3.9-1.7l0-0.2l1-3.1
	c-0.1-0.1-1.7-1.9-1.7-1.9c-0.6,0.2-1.2,0.3-2.1,0.3c-0.9,0-2-0.1-3.1-0.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0.1l-0.2,0.3
	l-0.4,0.6l-0.7-0.2l-3-0.7l-0.4,0.7l0.1,0.1l0.4,0.4l-0.2,0.5c-0.2,0.7-0.9,1.5-1.2,1.7l-0.8,0.8l-0.7-0.9L42,74.2
	c-0.5-0.6-0.7-1.5-0.4-2.5c0,0,0,0,0,0L40.7,72l-0.4-0.8l-0.3-0.6c-0.5-1.2-0.1-2.7,1-3.5l2.4-1.4l2.3,0.5c0.1-0.2,0.1-1,0.2-1.2
	c0,0-0.1,0-0.1,0l-1.3,0.5l-1.3-0.9c0,0-0.5-0.5,0-1l1.2-1.9l-3.2-4l4.8,2l0.3-0.4l0.7-0.8l0.7,0.7c0.2,0.2,0.3,0.4,0.4,0.7
	c1.3,0.5,3.7,2.1,3.9,4.6l-0.7,0.3v0c0.2,0.2,0.3,0.3,0.4,0.3l3.5,0.3c0.1,0,0.2-0.1,0.3-0.1c0.4-0.1,1.1-0.4,1.8-0.4
	c0.6,0,1.2,0.1,1.8,0.4c0.2-0.1,0.5-0.1,0.8-0.2l0.2,0c1.8,0,2.8,1.4,3.5,2.5c0.1,0.2,0.2,0.4,0.4,0.5c0.6,0.9,1.5,2.3,1.5,2.3
	l0.4,0.6l-0.5,0.5c-0.4,0.4-0.9,0.9-1.8,0.9c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.2c0,0.2-0.1,0.4-0.1,0.6l-0.6,3.2
	c3.6-0.9,5.9-3.6,5.9-7.6L67.7,56.8z M36.2,55.8h24.5c-1.2-1.3-1.2-2.9-0.9-3.9c-0.3-0.2-0.6-0.4-0.9-0.6c-1,0.7-3.9,2.8-4.6,3.4
	l-0.2,0.1l-9.6-1.7h-0.2c-0.2,0-0.6,0-0.9-0.1c-0.1,0.5-0.6,0.8-1.1,0.7c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.2-0.2-0.5-0.2-0.7l0-0.2
	c-1-0.7-1.9-2.3-1.5-3.9l0-0.2l0.1-0.1c0.1-0.1,0.3-0.2,0.4-0.3L40,47.9c-0.3,0-0.4-0.3-0.4-0.5c0-0.3,0.3-0.4,0.5-0.4l1.7,0.3
	l0.2-5.9l2.5-2.6l1.5,3.3l-1.6,5.6l1.5,0.2c0.3,0,0.4,0.3,0.4,0.5c0,0.2-0.2,0.4-0.5,0.4l-0.1,0l-1.2-0.2c0,0,0,0.1,0.1,0.1
	c0.1,0.2,0.3,0.4,0.6,0.5l7.3,0.1c0.3-0.3,1.1-1.1,1.9-1.9c-1.4-0.5-2.4-1.8-2.6-3.3c-1-0.4-1.7-1.1-2.1-1.9c-0.5-1-0.3-2.1-0.2-2.6
	c-1-0.4-2-1-3.1-2h-11c0.5,1.3,0.9,2.6,0.9,4L36.2,55.8L36.2,55.8z"
        />
        <path
          fill={fill}
          d="M51.9,17.4l-7-2.8c0.6-1.4,3.3-2.6,7-2.6c3.7,0,6.4,1.2,7,2.6L51.9,17.4z M57.1,26.1l-4.7,2.5V18.5l6.4-2.2
	L57.1,26.1L57.1,26.1z M60.1,28.7l2.1,1.2h0l0.5,0.3c-1.2,0.3-2.1,0.9-2.9,1.7l-0.1,0.1c-0.2,0.2-0.4,0.4-0.5,0.6l-0.1,0.1
	c-0.2,0.2-0.3,0.4-0.5,0.7l-0.1,0.1c-0.2,0.3-0.4,0.6-0.5,0.8c-1.5,2.6-4,4.2-6.1,4.2c-2.2,0-4.6-1.6-6.1-4.2
	c-0.2-0.3-0.3-0.6-0.5-0.8l-0.1-0.1c-0.1-0.2-0.3-0.4-0.5-0.6c0-0.1-0.1-0.1-0.1-0.1c-0.2-0.2-0.3-0.4-0.5-0.6c0,0-0.1-0.1-0.1-0.1
	c-0.8-0.8-1.8-1.4-2.9-1.7l0.5-0.3h0l2.2-1.2l2.2-1.3l5.9,3.2l0,0l0,0l5.9-3.2L60.1,28.7z M68.5,37.7c-0.5,1.3-0.9,2.6-0.9,4l0,8.8
	c-1.8-0.3-2.1-2.3-2.1-2.4l0-0.4l-0.4,0c-0.7-0.1-1.3-0.4-1.8-0.9c-0.9-0.9-0.9-2.4-0.9-2.4c0,0.1-1,1.5,0.1,3
	c0.5,0.6,1.2,1,2.1,1.2c0.2,1.1,1,2.7,3,2.9v4.3h-5.4c-2.5-1.4-1.4-3.9-1.4-4l0.2-0.5l-0.5-0.2c-2.7-1-2-3.7-1.9-3.8l0.1-0.4
	l-0.4-0.2c-2.2-1-1.9-3.8-1.9-4c-0.7,0.6-0.9,1.7-0.5,2.8c-0.3,0.3-0.6,0.6-1,1c-1.3-0.3-2.3-1.5-2.4-2.8l0-0.3l-0.3-0.1
	c-0.9-0.3-1.5-0.8-1.8-1.5c-0.3-0.8-0.2-1.5-0.1-1.9c0.5,0.1,1.1,0.2,1.6,0.2h0c0.3,0,0.5,0,0.8,0c1.5-0.1,3.1-0.8,4.8-2.4
	L68.5,37.7z M61.7,76.2l0.6-3.2l0.1-0.6c0.4,0.1,0.8,0.2,1.2,0.2c0.9,0,1.5-0.5,1.8-0.9l0.5-0.5l-0.4-0.6c0-0.1-0.9-1.4-1.5-2.3
	c-0.1-0.2-0.3-0.4-0.4-0.6c-0.7-1.1-1.7-2.5-3.5-2.5l-0.2,0c-0.3,0-0.5,0.1-0.8,0.2C58.6,65.2,58,65,57.4,65c-0.6,0-1.2,0.1-1.8,0.4
	c-0.1,0-0.2,0.1-0.3,0.1l-3.5-0.3c0,0-0.2-0.1-0.4-0.3v0l0.7-0.3c-0.2-2.5-2.6-4-3.9-4.6c-0.1-0.3-0.2-0.5-0.4-0.7l-0.7-0.7
	l-0.7,0.8l-0.3,0.4l-4.8-2l3.2,4l-1.2,1.9c-0.5,0.5,0,1,0,1l1.3,0.9l1.3-0.5c0,0,0.1,0,0.1,0c-0.1,0.2-0.1,1-0.2,1.2l-2.3-0.5
	L41,67.1c-1.3,0.9-1.4,2.4-1,3.5l0.3,0.6l0.4,0.8l0.9-0.4c0,0,0,0,0,0c-0.3,1-0.2,1.9,0.4,2.5l0.5,0.6l0.7,0.9l0.8-0.8
	c0.2-0.2,0.9-1,1.2-1.7l0.2-0.5l-0.4-0.4l-0.1-0.1l0.4-0.7l3,0.7l0.7,0.2l0.4-0.6l0.2-0.3c0-0.1,0-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0
	c1,0.2,2.1,0.3,3.1,0.3c0.9,0,1.4,0,2.1-0.2c0,0,1.6,1.8,1.7,1.9l-1,3.1l0,0.2c-1.9,0.2-2.8,0.6-3.9,1.7c-1.6-1.6-2.7-1.8-7.1-1.8
	c-5.2,0-8.7-3-8.7-7.9V56.8h31.5l0,11.8C67.7,72.6,65.4,75.3,61.7,76.2L61.7,76.2z M42.4,69.5l0.2,0.4c-0.1,0.4-1.3,0.9-1.3,0.9
	l-0.3-0.6c-0.3-0.8-0.1-1.8,0.6-2.3l2-1.2l2.1,0.4c0,0.7,0.4,1.2,0.4,1.2l-2.2-0.4l-1.3,0.9C42.3,68.9,42.3,69.2,42.4,69.5
	L42.4,69.5z M61.4,72.8l-0.7,3.5c-0.4,0.1-0.9,0.1-1.4,0.1l0.7-3.4c0.2-1-2.5-1.3-3.8-3.3c-0.7,0.7-1.9,1-3.1,1
	c-1.1,0-2.3-0.2-2.9-0.3c-0.2,0-0.3,0-0.5,0c-0.4,0-0.8,0.1-1,0.5l-0.2,0.3l-3.7-0.9l-0.9,1.5c-0.1,0.2-0.1,0.5,0.1,0.7l0.3,0.3
	c-0.2,0.5-0.9,1.4-0.9,1.4l-0.5-0.7c-0.4-0.5-0.4-1.4,0.1-2.2l1.5-2.5l3.1,0.5l-0.5-1.1c-0.7-1.5,0.3-3.3,0.4-4.4
	c0,0.3-0.3,0.4-0.7,0.4c-0.3,0-0.7-0.1-0.9-0.1c-0.1,0-0.2,0-0.2,0l-0.8,0.5L44,64.1c-0.1-0.1-0.1-0.4,0.1-0.6l1.6-1.7l-1.8-2.1
	l2.6,1.3l0.8-1c0.2,0.2,0.1,0.8,0.1,0.8c0.8,0,3,1.3,3.2,4.2c0,0.1,0,0.2,0,0.3l0.1,0.1c0.2,0.2,0.6,0.8,1.2,0.8l3.5,0.4h0
	c0.4,0,1.2-0.5,2.2-0.5c0.5,0,1.1,0.1,1.8,0.5c0,0,0.3-0.3,0.8-0.3c0,0,0.1,0,0.1,0c1.6,0,2.3,1.4,3.1,2.6c0.6,0.9,1.5,2.3,1.5,2.3
	c-0.3,0.4-0.6,0.6-1.1,0.6c-0.2,0-0.3,0-0.5,0c-1.7-0.3-1.9-3.8-2.9-4.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.4,0.3-0.4,0.3
	c0.4,1.2,0.2,1.7,0.7,2.6c0.4,0.7,1,1.2,1.3,1.5C61.6,71.9,61.5,72.5,61.4,72.8L61.4,72.8z M58.1,76.5c-0.5,0-0.9,0-1.3,0l1-3.2
	c0.2-0.4-0.7-1-1-1.5c0.5,0.3,1.8,1,2.5,1.3C58.9,74.2,58.5,75.4,58.1,76.5L58.1,76.5z M42.7,47.3l0.1-5.6l1.3-1.3l0.8,1.7l-1.6,5.4
	L42.7,47.3L42.7,47.3z M43.6,49.1c0.2,0.4,0.5,0.7,0.8,0.9c0,0.5-0.1,1.1-0.2,1.6c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.1-0.1,0.2
	c-0.4,0-1.1,0-1.8-0.3c-0.7-0.3-1.7-1.5-1.5-2.9c0.3-0.2,1-0.6,1.6-0.6C43,48.4,43.4,48.6,43.6,49.1L43.6,49.1z M58.2,50.6
	c-1,0.7-3.5,2.5-4.4,3.2l-8.7-1.6l0.1-0.2c0.1-0.5,0.2-1.1,0.2-1.8l7.3,0.1l0.1-0.1c0.1-0.1,2.4-2.4,3.6-3.7c0.3,0.4,0.6,0.7,1.1,1
	C57.5,48.3,57.5,49.6,58.2,50.6L58.2,50.6z M46.4,37.7c1.1,1,2.1,1.7,3.1,2c-0.1,0.5-0.3,1.5,0.2,2.6c0.4,0.8,1.1,1.5,2.1,1.9
	c0.1,1,0.8,2.6,2.6,3.3c-0.8,0.8-1.6,1.6-1.9,1.9l-7.3-0.1c-0.2-0.1-0.4-0.3-0.6-0.5c0,0,0-0.1-0.1-0.1l1.2,0.2l0.1,0
	c0.2,0,0.4-0.2,0.5-0.4c0-0.3-0.1-0.5-0.4-0.5l-1.5-0.2l1.6-5.6l-1.5-3.3l-2.5,2.6l-0.2,5.9l-1.7-0.3c-0.3-0.1-0.5,0.1-0.5,0.4
	c0,0.3,0.1,0.5,0.4,0.5l0.7,0.1c-0.3,0.1-0.4,0.3-0.4,0.3l-0.1,0.1l0,0.2c-0.4,1.6,0.5,3.2,1.5,3.9l0,0.2c0,0.3,0,0.5,0.2,0.7
	c0.2,0.3,0.5,0.4,0.8,0.4c0.4,0,0.8-0.3,0.9-0.7c0.4,0,0.7,0.1,0.9,0.1h0.2l9.6,1.7l0.2-0.1c0.8-0.5,3.7-2.7,4.6-3.4
	c0.3,0.2,0.6,0.4,0.9,0.6c-0.3,1-0.3,2.6,0.9,3.9H36.2V41.7c0-1.4-0.4-2.8-0.9-4L46.4,37.7z M45,16.3l6.4,2.2v10l-4.7-2.5L45,16.3
	L45,16.3z M60.6,15.1c0-2.5-4.1-4.7-8.7-4.7h0c-4.5,0-8.7,2.2-8.7,4.7l0.1,0.6l1.8,10.4l-1.8,1.1L38.6,30h0c0,0-1.1,0.5-1.6,0.9
	c-1.8,1.2-2.2,3.1-1.1,3.8c0.3-1.5,1.4-3,3.6-3c1.6,0,2.8,0.8,3.8,2c0.3,0.3,0.5,0.6,0.7,1c0.3,0.4,0.5,0.8,0.8,1.2H32.7
	c0.9,1.8,1.7,3.7,1.7,5.8v26.9c0,6.5,5,9.6,10.4,9.6c4.2,0,4.7-0.2,7.1,2.1c2.3-2.3,2.9-2.1,7.1-2.1c5.4,0,10.4-3.1,10.4-9.6V41.7
	c0-2.1,0.9-4,1.7-5.8H59c0.3-0.4,0.5-0.8,0.8-1.2c0.2-0.4,0.5-0.7,0.7-1c1-1.2,2.2-2,3.8-2c2.2,0,3.4,1.6,3.6,3
	c1-0.8,0.7-2.6-1.1-3.8c-0.5-0.3-1.5-0.9-1.5-0.9h0l-4.7-2.8l-1.8-1.1l1.8-10.4L60.6,15.1z M11.1,27.8c1-1,2-1.3,2.9-1.3
	c1.4,0,2.4,0.7,2.4,0.7c-3.7,2-4.5,5.2-6.6,5.9c-0.3,0.1-0.7,0.2-1.1,0.2c-1.6,0-3.1-1-3.6-2.2C5.2,31.1,8.1,31,11.1,27.8L11.1,27.8
	z M8.8,34.6c0.5,0,1-0.1,1.5-0.2c1.3-0.4,2.2-1.5,3.1-2.6c0.9-1.1,1.9-2.4,3.7-3.3l1.8-1l-1.7-1.2c-0.1-0.1-1.4-0.9-3.1-0.9
	c-1.4,0-2.7,0.6-3.8,1.7c-2.6,2.7-5,2.9-5.1,2.9L3.3,30L4,31.7C4.8,33.4,6.8,34.6,8.8,34.6L8.8,34.6z M17.1,49.4
	c-0.2-4.8,1-8.7,3-11.9c-1.1,3.3-0.9,7.4,2.7,11.3c0,0-7.3-8.7,2.9-17.7c4.1-3.4,8.5-5.5,10.8-7c-8.3,2.7-12.3-0.3-12.3-0.3
	c2.6,2.3,6.1,2.3,8.2,1.9c-0.6,0.3-1.2,0.6-1.8,0.9c-7,3.8-13.2,10-14.3,18.7v-0.1C14.6,53.8,11,55.9,11,55.9
	c2.9-1.4,4.7-5.2,5.1-6.5c0.5,7.5,4.1,12.7,8.4,17.6c2.2,2.6,3,5.1,3,5.1S27,69.3,25.1,67C21.2,62.2,17.4,56.2,17.1,49.4L17.1,49.4z
	 M49.5,31.9v1.7c0.6-0.7,1.7-0.6,1.7-0.6v2.5c0,0,0,1.2-0.5,1.7h2.5c-0.4-0.5-0.5-1.7-0.5-1.7V33c0,0,1.1-0.1,1.7,0.6v-1.7H49.5
	L49.5,31.9z M48.5,21.7c0.8,0.6,1.2,0.7,1.3,0.7l0.3,0.1V21l-2.9-1.2C47.2,19.7,47.2,20.7,48.5,21.7L48.5,21.7z M55.4,21.7
	c1.3-1,1.3-2,1.3-2L53.8,21v1.6l0.3-0.1C54.1,22.4,54.6,22.3,55.4,21.7L55.4,21.7z M89.8,26.5c0.9,0,1.9,0.3,2.9,1.3
	c3.1,3.1,5.9,3.3,5.9,3.3c-0.5,1.2-2,2.2-3.6,2.2c-0.4,0-0.7-0.1-1.1-0.2c-2.1-0.7-2.9-3.9-6.6-5.9C87.4,27.3,88.5,26.5,89.8,26.5
	L89.8,26.5z M86.8,28.4c1.8,0.9,2.8,2.2,3.7,3.3c0.9,1.1,1.7,2.2,3.1,2.6c0.5,0.2,1,0.2,1.5,0.2c2,0,4-1.2,4.7-2.9l0.8-1.7l-1.8-0.1
	c-0.1,0-2.5-0.2-5.1-2.9c-1.1-1.1-2.4-1.7-3.8-1.7c-1.7,0-3,0.9-3.2,1L85,27.4L86.8,28.4z M87.7,45.3L87.7,45.3
	c-1.1-8.6-7.3-14.8-14.3-18.6c-0.6-0.3-1.2-0.6-1.8-0.9c2.1,0.3,5.5,0.4,8.2-1.9c0,0-4,3-12.3,0.3c2.3,1.5,6.7,3.6,10.8,7
	c10.2,8.9,2.9,17.7,2.9,17.7c3.7-3.8,3.8-7.9,2.7-11.2c1.9,3.2,3.2,7.1,3,11.9c-0.3,6.9-4.1,12.8-8,17.6c-1.9,2.3-2.3,5.2-2.3,5.2
	s0.8-2.5,3-5.1c4.3-5,7.9-10.2,8.4-17.6c0.4,1.4,2.3,5.1,5.1,6.5C92.9,55.9,89.3,53.8,87.7,45.3L87.7,45.3z M32.4,78
	c-10.6,0-10.3-9.1-10.3-9.1s-0.7,2.7-3.7,3.4c-0.4,0.1-0.7,0.1-1.1,0.1c-0.9,0-1.8-0.2-1.8-0.2s1.5-3.3,0-7.8
	c-1.3-3.8-1.1-6.6-1.1-6.6s-1.4,2.2-5,2.2h0c-2.9,0-4.2-2.2-4.2-2.2c7.1-2.9,6.4-12.4,6.4-12.4S10.6,46,8.9,46H8.7
	c-3.9,0-5.2-4.2-5.2-4.2h0.1c0.7,0,6.5-0.2,10.6-5.4c2.7-3.4,4.9-6.4,9.2-7.6l0.4-0.1l0.1-0.4c0.1-0.7-0.3-1.3-1.4-2
	c-6-3.9-1.9-9-1.9-9s1.7,4,5.6,4l0.3,0c0.4,0,0.9,0,1.3-0.1l0.7-0.1c0,0-1.1-3,1.6-5.7c2-2.1,4.4-2.4,5.8-2.4c0.7,0,1.1,0.1,1.1,0.1
	c-1.3,1.7-2.3,4.6-0.8,6.4c1.3,1.5,4.6,1.6,4.6,3.9c0,1-0.9,2.1-2.5,3.1c-4.9,3.1-9.7,7.1-11.7,12.6C24,45.9,26.9,50.4,30.9,53
	c0,0-1.3,1.2-3.1,1.3c-0.4,0-0.7,0.1-1.1,0.1c-3.9,0-5.9-1.8-5.9-1.8s0.3,2.5,2.3,4.9c1.9,2.2,4.3,3.4,8,3.5c0,0,0.1,1.7-1.5,3.2
	c-1.1,0.9-3.5,1.3-3.5,1.3s4.6,0.9,5.1,7.7C31.5,76.7,32.4,78,32.4,78L32.4,78z M33.5,77.3c0,0-0.7-1.2-1-4.3
	c-0.3-3.7-1.7-5.9-3.1-7.2c0.4-0.2,0.8-0.4,1.1-0.7c2.1-1.8,2-4,1.9-4.3l-0.1-1.2l-1.2,0c-3.1-0.1-5.3-1-7.1-3
	c-0.4-0.4-0.7-0.9-1-1.4c1,0.3,2.2,0.5,3.6,0.5c0.4,0,0.8,0,1.2-0.1c1.4-0.1,2.8-0.7,3.9-1.6l1.2-1.1L31.6,52
	c-3.1-2-6.3-5.8-3.8-12.4c1.6-4.3,5.2-8.2,11.2-12c2-1.3,3.1-2.8,3.1-4.2c0-2.2-1.8-3.1-3.2-3.7c-0.7-0.3-1.3-0.6-1.7-1
	c-1-1.2-0.2-3.5,0.8-4.8l1.2-1.6l-2-0.4c-0.1,0-0.6-0.1-1.4-0.1c-1.5,0-4.4,0.4-6.7,2.8c-2,2-2.2,4.2-2.1,5.6c-0.2,0-0.4,0-0.6,0
	h-0.2c-2.9,0-4.3-3.1-4.4-3.2l-0.8-2l-1.3,1.6c-0.1,0.1-2,2.5-1.6,5.6c0.3,2,1.6,3.8,3.8,5.2c0.2,0.2,0.4,0.3,0.5,0.4
	c-4.1,1.3-6.4,4.3-8.9,7.4l-0.4,0.4c-3.7,4.7-9,4.9-9.6,4.9l-1.8-0.1l0.5,1.7c0.1,0.2,1.7,5.1,6.4,5.1l0.2,0c0.5,0,1-0.1,1.4-0.2
	c-0.2,2.6-1.2,7.6-5.6,9.4l-1.3,0.6l0.7,1.3c0.1,0.1,1.7,2.9,5.4,2.9c1.3,0,2.6-0.3,3.8-0.9c0.1,1.2,0.4,2.7,1,4.5
	c1.3,4,0.1,6.8,0,6.9L13.7,73l1.5,0.4c0.1,0,1,0.3,2.1,0.3c1.5,0,2.9-0.5,4.1-1.5c0.4,1.2,1.1,2.7,2.3,3.9c2,2.1,5,3.2,8.8,3.2
	l2.3,0L33.5,77.3z M46.3,61.9c-0.2,0-0.3,0.1-0.3,0.3c0,0,0,0,0,0c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.1,0.3-0.3
	C46.6,62.1,46.5,61.9,46.3,61.9z M60.9,86.8l-1.4-4c1.7-0.5,4.6-1.6,6-2.4l-0.9,2.9l3,1C65.7,85.2,63.5,86,60.9,86.8L60.9,86.8z
	 M51.9,90.3c-3.5,0-7.4-0.8-8.6-1.2l1.8-4.8c1.5,0.4,4.5,0.9,6.7,0.9H52c2.2,0,5.3-0.4,6.7-0.9l1.8,4.8
	C59.3,89.5,55.4,90.3,51.9,90.3L51.9,90.3z M36.4,84.3l3-1l-0.9-2.9c1.3,0.7,4.3,1.9,6,2.4l-1.4,4C40.4,86,38.2,85.2,36.4,84.3
	L36.4,84.3z M67.6,83.4l-1.8-0.7l0.5-1.9l0.5-2c0,0-3.2,1.7-7.7,3.1c-1.3,0.4-2.3,0.5-2.3,0.5v1.4c0,0-2.4,0.4-4.9,0.4h-0.1
	c-2.4,0-4.9-0.4-4.9-0.4v-1.4c0,0-1-0.2-2.3-0.5c-4.5-1.3-7.7-3.1-7.7-3.1l0.5,2l0.5,1.9l-1.8,0.7l-2,0.8c2.3,1.1,5.5,2.7,8.4,3.5
	L42,89.6c0,0,3.7,1.7,9.9,1.7v0H52v0c6.2,0,9.9-1.7,9.9-1.7l-0.7-1.9c2.9-0.8,6.1-2.4,8.4-3.5L67.6,83.4z M44.4,7.1l0.8,0.1l0.4-0.8
	l1-2.2l1.9,1l0.7,0.4l2.7-2.7L54,5l0.6,0.6l0.7-0.4l1.9-1l1.1,2.2l0.3,0.8l0.8-0.1L60.3,7c-0.4,0.8-0.6,1.6-0.8,2.2
	c-2.1-1.2-4.8-1.8-7.5-1.8c-2.7,0-5.4,0.7-7.5,1.8c-0.2-0.8-0.5-1.5-0.8-2.2L44.4,7.1L44.4,7.1z M51.9,8.6c3.7,0,6.6,1.2,8.3,2.5
	c0,0,0.5-2.9,2.1-5.5l-3,0.3l-1.6-3.3l-3,1.5l-2.9-2.9L49,4.2l-3-1.5L44.5,6l-3-0.3c1.7,2.6,2.1,5.5,2.1,5.5
	C45.3,9.8,48.2,8.6,51.9,8.6L51.9,8.6z M98.7,57.7c0,0-1.3,2.2-4.2,2.2h0c-3.5,0-5-2.2-5-2.2s0.2,2.8-1.1,6.6c-1.5,4.6,0,7.8,0,7.8
	s-0.8,0.2-1.8,0.2c-0.4,0-0.7,0-1.1-0.1c-3-0.7-3.7-3.4-3.7-3.4S82.1,78,71.5,78h-0.1c0,0,0.9-1.3,1.2-4.9c0.5-6.7,5.1-7.7,5.1-7.7
	s-2.4-0.3-3.5-1.3c-1.6-1.5-1.5-3.2-1.5-3.2c3.7-0.1,6.2-1.3,8-3.5c1.2-1.4,2-3.1,2.3-4.9c0,0-2,1.8-5.9,1.8c-0.3,0-0.7,0-1.1-0.1
	C74.3,54.2,73,53,73,53c4-2.6,6.9-7.1,4.4-13.9c-2-5.5-6.8-9.5-11.7-12.6c-1.6-1-2.5-2.1-2.5-3.1c0-2.3,3.2-2.4,4.6-3.9
	c1.5-1.8,0.6-4.7-0.8-6.4c0,0,0.4-0.1,1.2-0.1c1.4,0,3.8,0.4,5.8,2.4c2.6,2.7,1.5,5.7,1.5,5.7l0.7,0.1c0.4,0.1,0.8,0.1,1.3,0.1
	l0.3,0c3.8,0,5.6-4,5.6-4s4.1,5-1.9,9c-1,0.7-1.5,1.3-1.4,2l0.1,0.4l0.4,0.1c4.4,1.2,6.6,4.2,9.3,7.6c4.1,5.2,9.9,5.4,10.6,5.4h0.1
	c0,0-1.3,4.2-5.2,4.2c-0.1,0-0.1,0-0.2,0c-1.8-0.1-2.7-0.8-2.7-0.8S91.6,54.8,98.7,57.7L98.7,57.7z M95.2,47.3
	c4.8,0,6.4-4.9,6.4-5.1l0.5-1.7l-1.8,0.1h0c-0.6,0-5.9-0.1-9.6-4.9l-0.4-0.4c-2.4-3.1-4.7-6.1-8.9-7.4c0.2-0.1,0.3-0.3,0.5-0.4
	c2.2-1.4,3.5-3.2,3.8-5.2c0.5-3.1-1.5-5.5-1.6-5.6L82.9,15l-0.8,2c-0.1,0.1-1.5,3.2-4.4,3.2h-0.2c-0.2,0-0.4,0-0.6,0
	c0.1-1.4-0.2-3.5-2.1-5.6c-2.3-2.4-5.2-2.8-6.7-2.8c-0.8,0-1.3,0.1-1.4,0.1l-2,0.4l1.2,1.6c1,1.3,1.9,3.6,0.8,4.8
	c-0.3,0.4-1,0.7-1.7,1c-1.3,0.6-3.2,1.5-3.2,3.7c0,1.4,1.1,2.9,3.1,4.2c5.9,3.7,9.6,7.6,11.2,12c2.4,6.6-0.7,10.4-3.8,12.4l-1.4,0.9
	l1.2,1.1c0.2,0.1,1.7,1.5,3.9,1.6c0.4,0,0.8,0.1,1.2,0.1c1.4,0,2.6-0.2,3.6-0.5c-0.3,0.5-0.6,1-1,1.4c-1.8,2.1-4,3-7.1,3l-1.2,0
	l-0.1,1.2c0,0.2-0.1,2.4,2,4.3c0.3,0.3,0.7,0.5,1.1,0.7C73,67,71.6,69.2,71.4,73c-0.2,3.1-1,4.3-1,4.3l-1.3,2l2.3,0
	c3.8,0,6.8-1.1,8.8-3.2c1.1-1.1,1.8-2.4,2.3-3.9c1.1,1,2.6,1.5,4.1,1.5c1.1,0,2-0.2,2.1-0.3l1.5-0.4l-0.7-1.4
	c-0.1-0.1-1.3-2.9,0-6.9c0.6-1.7,0.9-3.3,1-4.5c0.9,0.5,2.2,0.9,3.8,0.9c3.7,0,5.3-2.8,5.4-2.9l0.7-1.3l-1.3-0.6
	c-4.4-1.8-5.4-6.7-5.6-9.4c0.4,0.1,0.9,0.1,1.4,0.2L95.2,47.3z"
        />
      </g>
    </svg>
  );
};

export default IconTinkoff;
