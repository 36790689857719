import { IconBinance, IconBybit, IconHTX, IconOKX } from "../utils/Icons";

export const BINANCE_ARRAY = [
  {
    id: 1,
    api_id: 1,
    title: "Binance (Спотовый кошелек)",
    system: "Android | iOS",
    icon: <IconBinance width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/binance/wallet/main/preview.png"),
    modal: {
      ios: "/exchange/binance/wallet/main/ios",
      android: "/exchange/binance/wallet/main/android",
    },
  },
  {
    id: 2,
    api_id: 2,
    title: "Binance (Пополнение)",
    system: "Android | iOS",
    icon: <IconBinance width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/binance/wallet/deposit/preview.png"),
    modal: {
      ios: "/exchange/binance/wallet/deposit/ios",
      android: "/exchange/binance/wallet/deposit/android",
    },
  },
  {
    id: 3,
    api_id: 3,
    title: "Binance (Вывод средств)",
    system: "Android | iOS",
    icon: <IconBinance width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/binance/wallet/withdrawal/preview.png"),
    modal: {
      ios: "/exchange/binance/wallet/withdrawal/ios",
      android: "/exchange/binance/wallet/withdrawal/android",
    },
  },
  {
    id: 4,
    api_id: 4,
    title: "Binance (Пополнение 'Email')",
    system: "Android | iOS",
    icon: <IconBinance width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/binance/mail/deposit/preview.png"),
    modal: {
      ios: "/exchange/binance/mail/deposit/ios",
      android: "/exchange/binance/mail/deposit/android",
    },
  },
  {
    id: 5,
    api_id: 5,
    title: "Binance (Вывод средств 'Email')",
    system: "Android | iOS",
    icon: <IconBinance width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/binance/mail/withdrawal/preview.png"),
    modal: {
      ios: "/exchange/binance/mail/withdrawal/ios",
      android: "/exchange/binance/mail/withdrawal/android",
    },
  },
];

export const BYBIT_ARRAY = [
  {
    id: 1,
    api_id: 6,
    title: "Bybit (Аккаунт финансиро...)",
    system: "Android | iOS",
    icon: <IconBybit width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/bybit/wallet/main/preview.png"),
    modal: {
      ios: "/exchange/bybit/wallet/main/ios",
      android: "/exchange/bybit/wallet/main/android",
    },
  },
  {
    id: 2,
    api_id: 7,
    title: "Bybit (Активы)",
    system: "Android | iOS",
    icon: <IconBybit width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/bybit/wallet/assets/preview.png"),
    modal: {
      ios: "/exchange/bybit/wallet/assets/ios",
      android: "/exchange/bybit/wallet/assets/android",
    },
  },
  {
    id: 3,
    api_id: 8,
    title: "Bybit (Пополнение)",
    system: "Android | iOS",
    icon: <IconBybit width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/bybit/wallet/deposit/preview.png"),
    modal: {
      ios: "/exchange/bybit/wallet/deposit/ios",
      android: "/exchange/bybit/wallet/deposit/android",
    },
  },
  {
    id: 4,
    api_id: 9,
    title: "Bybit (Вывод средств)",
    system: "Android | iOS",
    icon: <IconBybit width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/bybit/wallet/withdrawal/preview.png"),
    modal: {
      ios: "/exchange/bybit/wallet/withdrawal/ios",
      android: "/exchange/bybit/wallet/withdrawal/android",
    },
  },
  {
    id: 5,
    api_id: 10,
    title: "Bybit (Пополнение 'Email')",
    system: "Android | iOS",
    icon: <IconBybit width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/bybit/mail/deposit/preview.png"),
    modal: {
      ios: "/exchange/bybit/mail/deposit/ios",
      android: "/exchange/bybit/mail/deposit/android",
    },
  },
  {
    id: 6,
    api_id: 11,
    title: "Bybit (Вывод средств 'Email')",
    system: "Android | iOS",
    icon: <IconBybit width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/bybit/mail/withdrawal/preview.png"),
    modal: {
      ios: "/exchange/bybit/mail/withdrawal/ios",
      android: "/exchange/bybit/mail/withdrawal/android",
    },
  },
];

export const OKX_ARRAY = [
  {
    id: 1,
    api_id: 12,
    title: "OKX (Основной кошелек)",
    system: "Android | iOS",
    icon: <IconOKX width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/okx/wallet/balance/preview.png"),
    modal: {
      ios: "/exchange/okx/wallet/balance/ios",
      android: "/exchange/okx/wallet/balance/android",
    },
  },
  {
    id: 2,
    api_id: 13,
    title: "OKX (Пополнение)",
    system: "Android | iOS",
    icon: <IconOKX width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/okx/wallet/deposit/preview.png"),
    modal: {
      ios: "/exchange/okx/wallet/deposit/ios",
      android: "/exchange/okx/wallet/deposit/android",
    },
  },
  {
    id: 3,
    api_id: 14,
    title: "OKX (Вывод средств)",
    system: "Android | iOS",
    icon: <IconOKX width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/okx/wallet/withdrawal/preview.png"),
    modal: {
      ios: "/exchange/okx/wallet/withdrawal/ios",
      android: "/exchange/okx/wallet/withdrawal/android",
    },
  },
  {
    id: 4,
    api_id: 15,
    title: "OKX (Пополнение 'Email')",
    system: "Android | iOS",
    icon: <IconOKX width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/okx/mail/deposit/preview.png"),
    modal: {
      ios: "/exchange/okx/mail/deposit/ios",
      android: "/exchange/okx/mail/deposit/android",
    },
  },
  {
    id: 5,
    api_id: 16,
    title: "OKX (Вывод средств 'Email')",
    system: "Android | iOS",
    icon: <IconOKX width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/okx/mail/withdrawal/preview.png"),
    modal: {
      ios: "/exchange/okx/mail/withdrawal/ios",
      android: "/exchange/okx/mail/withdrawal/android",
    },
  },
];

export const HTX_ARRAY = [
  {
    id: 1,
    api_id: 17,
    title: "HTX (Активы)",
    system: "Android | iOS",
    icon: <IconHTX width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/htx/wallet/main/preview.png"),
    modal: {
      ios: "/exchange/htx/wallet/main/ios",
      android: "/exchange/htx/wallet/main/android",
    },
  },
  {
    id: 2,
    api_id: 18,
    title: "HTX (Пополнение)",
    system: "Android | iOS",
    icon: <IconHTX width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/htx/wallet/deposit/preview.png"),
    modal: {
      ios: "/exchange/htx/wallet/deposit/ios",
      android: "/exchange/htx/wallet/deposit/android",
    },
  },
  {
    id: 3,
    api_id: 19,
    title: "HTX (Вывод средств)",
    system: "Android | iOS",
    icon: <IconHTX width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/htx/wallet/withdrawal/preview.png"),
    modal: {
      ios: "/exchange/htx/wallet/withdrawal/ios",
      android: "/exchange/htx/wallet/withdrawal/android",
    },
  },
  {
    id: 4,
    api_id: 20,
    title: "HTX (Пополнение 'Email')",
    system: "Android | iOS",
    icon: <IconHTX width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/htx/mail/deposit/preview.png"),
    modal: {
      ios: "/exchange/htx/mail/deposit/ios",
      android: "/exchange/htx/mail/deposit/android",
    },
  },
  {
    id: 5,
    api_id: 21,
    title: "HTX (Вывод средств 'Email')",
    system: "Android | iOS",
    icon: <IconHTX width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/exchange/htx/mail/withdrawal/preview.png"),
    modal: {
      ios: "/exchange/htx/mail/withdrawal/ios",
      android: "/exchange/htx/mail/withdrawal/android",
    },
  },
];
