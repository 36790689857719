import React from "react";
import { Col, Row, Tabs } from "antd";
import Login from "./elements/Login";
import Register from "./elements/Register";
import { useTranslation } from "react-i18next";
import { ModalUi } from "../ui";
import { IconLogIn, IconUserPlusBottom } from "../../utils/Icons";

const Auth = ({ showAuthModal, setAuthShowModal }) => {
  const { t } = useTranslation();
  const basePath = "auth.";

  const close = () => {
    setAuthShowModal(false);
  };

  return (
    <ModalUi
      title={t(`${basePath}title`)}
      showModal={showAuthModal}
      setShowModal={() => {
        setAuthShowModal(false);
      }}
    >
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Tabs
            defaultActiveKey="1"
            type="card"
            items={[
              {
                key: "1",
                label: (
                  <>
                    <IconLogIn width="20" height="20" fill="#fff" />
                    {t(`${basePath}login.title`)}
                  </>
                ),
                children: <Login close={close} />,
              },
              {
                key: "2",
                label: (
                  <>
                    <IconUserPlusBottom width="20" height="20" fill="#fff" />
                    {t(`${basePath}register.title`)}
                  </>
                ),
                children: <Register close={close} />,
              },
            ]}
          />
        </Col>
      </Row>
    </ModalUi>
  );
};

export default Auth;
