import { useMutation } from "@tanstack/react-query";
import { scanlyApi, SCANLY_API_ROUTES } from "../../../../api";

const useSignUp = () => {
    return useMutation({
        mutationKey: ["sign-up"],
        mutationFn: async ({ password, login, email }) => {
            try {
                await scanlyApi.post(SCANLY_API_ROUTES.SIGN_UP, { password, login, email });
            } catch (err) {
                throw err;
            }
        }
    });
};

export default useSignUp;