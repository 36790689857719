import { toPng } from "html-to-image";

const useDownload = () => {
  const downloadImage = async (idImg, nameImg) => {
    const promise = new Promise(async (res, rej) => {
      return setTimeout(async () => {
        try {
          const dataUrl = await toPng(document.getElementById(idImg));
          const link = document.createElement('a')
          link.download = `${nameImg}.png`
          link.href = dataUrl
          link.click()
          res();
        } catch (error) {
          rej(error)
        }
      }, 300);
    });

    await promise;
  };

  return { downloadImage };
};

export default useDownload;
