import { Spin } from "antd";
import React from "react";
import { IconRadiation } from "../../../utils/Icons";

const SpinUi = ({ size = "34" }) => {
  return (
    <Spin
      indicator={
        <IconRadiation
          width={size}
          height={size}
          fill="#00c39a"
          className="spin"
        />
      }
    />
  );
};

export default SpinUi;
