import dayjs from "dayjs";
import { useEffect } from "react";

export const useCustomLocale = (locale, monthsShort) => {
  useEffect(() => {
    dayjs.updateLocale(locale, { monthsShort });
    return () => {
      dayjs.updateLocale("ru");
    };
  }, [locale, monthsShort]);
};
