import React from "react";

const IconWIFIiOS = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512 512"
      {...props}
    >
      <g>
        <path
          fill={fill}
          d="M256.1 96c-79.9 0-155.7 29.9-213.9 84.1L32 189.6l9.9 9.8 32.3 32 9.3 9.2 9.6-8.8c44.5-40.9 102.3-63.5 162.8-63.5 60.5 0 118.4 22.6 162.8 63.5l9.6 8.8 9.3-9.2 32.3-32 9.9-9.8-10.2-9.5C411.6 125.9 335.7 96 256.1 96z"
        />
        <path
          fill={fill}
          d="M397.4 256.4c-38.8-35.1-88.9-54.4-141.1-54.4h-8.9l-.1.2c-49.2 2-96 21.1-132.6 54.2l-10.5 9.5 10.1 10 32.7 32.4 9.1 9 9.6-8.4c25.3-22.2 57.4-34.5 90.3-34.5 33.1 0 65.2 12.3 90.5 34.5l9.6 8.4 9.1-9 32.7-32.4 10.1-10-10.6-9.5z"
        />
        <path
          fill={fill}
          d="m256.2 416 9.6-9.5 52.8-52.2 10.6-10.5-11.6-9.5c-15.4-11.4-32.4-20-61.5-20-29 0-44.9 9.4-61.5 20l-11.5 9.5 10.6 10.5 52.8 52.2 9.7 9.5z"
        />
      </g>
    </svg>
  );
};

export default IconWIFIiOS;
