import { useMutation } from '@tanstack/react-query';
import { useTranslation } from "react-i18next";

import useMessage from "../hooks/useMessage";
import { SCANLY_API_ROUTES, scanlyApi } from '../api';


const useUpdateUserSelf = ({ onSuccess }) => {
    const { showErrorMessage, showSuccessMessage } = useMessage();
    const { t } = useTranslation();

    const mutationFn = async (variables) => {
        try {
            await scanlyApi.patch(SCANLY_API_ROUTES.USER, variables);
            showSuccessMessage(t('user.settings.updateSuccess'));
            onSuccess();
        } catch(err) {
            showErrorMessage(err?.response?.data?.message || err);
            console.error(err?.response?.data?.message || err);
        }
    }

    return useMutation({
        mutationFn,
        mutationKey: "updateProduct"
    })
};

export default useUpdateUserSelf;
