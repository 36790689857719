import { IconExodus, IconTrustWallet } from "../utils/Icons";

export const TRUST_ARRAY = [
  {
    id: 1,
    api_id: 22,
    title: "Trust Wallet (Главная)",
    system: "Android | iOS",
    icon: <IconTrustWallet width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/wallets/trustwallet/main/preview.png"),
    modal: {
      ios: "/wallets/trustwallet/main/ios",
      android: "/wallets/trustwallet/main/android",
    },
  },
  {
    id: 2,
    api_id: 23,
    title: "Trust Wallet (Монета)",
    system: "Android | iOS",
    icon: <IconTrustWallet width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/wallets/trustwallet/coin/preview.png"),
    modal: {
      ios: "/wallets/trustwallet/coin/ios",
      android: "/wallets/trustwallet/coin/android",
    },
  },
  {
    id: 3,
    api_id: 24,
    title: "Trust Wallet (Перевод)",
    system: "Android | iOS",
    icon: <IconTrustWallet width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/wallets/trustwallet/transfer/preview.png"),
    modal: {
      ios: "/wallets/trustwallet/transfer/ios",
      android: "/wallets/trustwallet/transfer/android",
    },
  },
  {
    id: 4,
    api_id: 25,
    title: "Trust Wallet (История)",
    system: "Android | iOS",
    icon: <IconTrustWallet width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/wallets/trustwallet/history/preview.png"),
    modal: {
      ios: "/wallets/trustwallet/history/ios",
      android: "/wallets/trustwallet/history/android",
    },
  },
];

export const EXODUS_ARRAY = [
  {
    id: 1,
    api_id: 26,
    title: "Exodus (Монета)",
    system: "Android | iOS",
    icon: <IconExodus width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/wallets/exodus/coin/preview.png"),
    modal: {
      ios: "/wallets/exodus/coin/ios",
      android: "/wallets/exodus/coin/android",
    },
  },
  {
    id: 2,
    api_id: 27,
    title: "Exodus (Получено)",
    system: "Android | iOS",
    icon: <IconExodus width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/wallets/exodus/received/preview.png"),
    modal: {
      ios: "/wallets/exodus/received/ios",
      android: "/wallets/exodus/received/android",
    },
  },
  {
    id: 3,
    api_id: 28,
    title: "Exodus (Отправлено)",
    system: "Android | iOS",
    icon: <IconExodus width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/wallets/exodus/sent/preview.png"),
    modal: {
      ios: "/wallets/exodus/sent/ios",
      android: "/wallets/exodus/sent/android",
    },
  },
];
