import React from "react";

const IconSberbank = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 46.1 50"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.4}
          d="M41.3,9.6c1.1,1.4,2,3,2.8,4.6L23.2,29.9l-8.8-5.6v-6.7l8.7,5.5L41.3,9.6z"
        />
        <path
          fill={fill}
          d="M5.4,23.8c0-0.3,0-0.6,0-0.9l-5.3-0.3c0,0.4,0,0.8,0,1.1c0,6.4,2.6,12.2,6.8,16.4l3.8-3.8
		C7.4,33.2,5.4,28.8,5.4,23.8z"
        />
        <path
          fill={fill}
          d="M23.1,6c0.3,0,0.6,0,0.9,0l0.3-5.4c-0.4,0-0.8,0-1.1,0c-6.3,0-12.1,2.6-16.3,6.8l3.8,3.8C13.8,8,18.3,6,23.1,6
		z"
        />
        <path
          fill={fill}
          d="M23.1,41.7c-0.3,0-0.6,0-0.9,0L21.9,47c0.4,0,0.8,0,1.1,0c6.3,0,12.1-2.6,16.3-6.8l-3.8-3.8
		C32.4,39.7,28,41.7,23.1,41.7z"
        />
        <path
          fill={fill}
          d="M33.1,9.1l4.5-3.3c-3.9-3.2-9-5.2-14.5-5.2V6C26.8,6,30.3,7.1,33.1,9.1z"
        />
        <path
          fill={fill}
          d="M46.1,23.8c0-1.4-0.1-2.8-0.4-4.2l-4.9,3.7c0,0.1,0,0.3,0,0.5c0,5.3-2.3,10-5.8,13.2l3.6,4
		C43.2,36.9,46.1,30.7,46.1,23.8z"
        />
        <path
          fill={fill}
          d="M23.1,41.7c-5.2,0-9.9-2.3-13.1-5.9l-3.9,3.6c4.2,4.7,10.3,7.6,17,7.6V41.7z"
        />
        <path
          fill={fill}
          d="M11.3,10.6l-3.6-4C3,10.9,0.1,17,0.1,23.8h5.3C5.4,18.6,7.7,13.9,11.3,10.6z"
        />
      </g>
    </svg>
  );
};

export default IconSberbank;
