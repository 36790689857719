import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

function Result({ type, state, mobile }) {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/okx/mail/withdrawal/okx-withdrawal-mail-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <h4 className="time-arrival">
        {state?.currency} успешно выведены и отправлены
        <span>Входящие</span>
      </h4>
      <div className="time">
        {state?.timeMail ? dayjs(state?.timeMail).format("HH:mm") : "00:00"}
      </div>
      <div className="mail-title">
        {state?.currency} успешно выведены и отправлены
      </div>
      <div className="mail-text">
        Вы вывели сумму в размере{" "}
        <span className="bold">
          {`${state?.amount || 0}`.replace(".", ",")} {state?.currency}
        </span>{" "}
        в{" "}
        <span className="bold">
          {state?.date
            ? dayjs(state?.date).format("DD.MM.YYYY, HH:mm:ss")
            : "2024-01-01 00:00:00"}{" "}
          {state?.utc ? `(${state?.utc})` : "___"}
        </span>
        .
      </div>
      <div className="address">{state?.addressDomain || "_______________"}</div>
      <div className="txId">{state?.transactionID || "_______________"}</div>
      <div className="withdrawalId">
        {state?.reference || "_______________"}
      </div>
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img okx mail withdrawal",
});
