import React from "react";
import { Form, TimePicker } from "antd";
import { IconClockSquare, IconTimesSquare } from "../../../utils/Icons";

const TimePickerUi = ({ name, label, format, errorMess, ...props }) => {
  return (
    <Form.Item name={name} label={label} rules={[{ required: true, message: errorMess }]}>
      <TimePicker
        showNow={false}
        format={format}
        suffixIcon={<IconClockSquare width="20" height="20" fill="#00c39a" />}
        clearIcon={<IconTimesSquare width="20" height="20" fill="#d94c48" />}
        {...props}
      />
    </Form.Item>
  );
};

export default TimePickerUi;
