import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_EN } from "./translations/en/en";
import { TRANSLATIONS_RU } from "./translations/ru/ru";

export const languages = ["ru-RU", "en-US"];

i18n.use(initReactI18next).init({
  fallbackLng: "ru-RU",
  resources: {
    "ru-RU": {
      translation: TRANSLATIONS_RU,
    },
    "en-US": {
      translation: TRANSLATIONS_EN,
    },
  },
});

if (localStorage.getItem("i18nextLng") && languages.some((i) => i === localStorage.getItem("i18nextLng"))) {
  i18n.changeLanguage(localStorage.getItem("i18nextLng"));
} else {
  localStorage.setItem("i18nextLng", languages.at(0));
  i18n.changeLanguage(languages.at(0));
}

export default i18n;
