export const NETWORK_ARRAY = [
  {
    key: 1,
    title: "Bitcoin",
    value: "BTC",
  },
  {
    key: 2,
    title: "Ethereum",
    value: "ETH",
  },
  {
    key: 3,
    title: "Binance Smart Chain",
    value: "BSC",
  },
  {
    key: 4,
    title: "Solana",
    value: "SOL",
  },
  {
    key: 5,
    title: "Cardano",
    value: "ADA",
  },
  {
    key: 6,
    title: "Tron",
    value: "TRX",
  },
  {
    key: 6,
    title: "Tron",
    value: "TRC-20",
  },
  {
    key: 7,
    title: "Ethereum",
    value: "ERC-20",
  },
  {
    key: 8,
    title: "Binance Smart Chain",
    value: "BEP-20",
  },
  {
    key: 9,
    title: "Avalanche",
    value: "ARC-20",
  },
  {
    key: 10,
    title: "NEO",
    value: "NEP-5",
  },
];
