import React from "react";

const IconAngleRightSmall = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fill}
        d="M10,17a1,1,0,0,1-.707-1.707L12.586,12,9.293,8.707a1,1,0,1,1,1.414-1.414l4,4a1,1,0,0,1,0,1.414l-4,4A1,1,0,0,1,10,17Z"
      />
    </svg>
  );
};

export default IconAngleRightSmall;
