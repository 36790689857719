import { useTranslation, Trans } from "react-i18next";
import { Button } from "antd";
import dayjs from "dayjs";

import { ModalUi, SpinUi } from "../ui";
import useUsers from "../../hooks/useUsers";
import useEditPurchase from "../../hooks/useEditPurchase";
import useMessage from "../../hooks/useMessage";
import getDocName from "../../utils/getDocTitle";

const PurchaseRestoreModal = ({ showModal, onCancel, purchase, refetch }) => {
  const { showErrorMessage, showSuccessMessage } = useMessage();
  const { t } = useTranslation();

  const { data, isLoading } = useUsers({
    params: { id: purchase?.user_id },
    settings: { cacheTime: 0 },
  });
  const title = getDocName(purchase?.doc);
  const basePath = "user.admin.purchaseList.table.";

  const user = data?.data?.data[0]?.login;
  const onSuccess = () => {
    refetch();
    onCancel();
    showSuccessMessage(t(`${basePath}modal.restoreSuccess`));
  };

  const onError = () => {
    showErrorMessage(t(`${basePath}modal.restoreError`));
  };

  const { mutate } = useEditPurchase({
    onSuccess,
    onError,
  });

  const onConfirm = () => {
    mutate({ deleted: 0, id: purchase.id });
  };

  const createdAt = purchase?.createdAt;

  return (
    <ModalUi
      showModal={showModal}
      onCancel={onCancel}
      title={t(`${basePath}modal.titleRestore`)}
    >
      {isLoading ? (
        <div className="d-flex justify-center">
          <SpinUi size={54} />
        </div>
      ) : (
        <p className="delete-purchase-msg">
          <Trans
            values={{
              user,
              time: dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss"),
              title,
            }}
            i18nKey={`${basePath}modal.textRestore`}
          >
            s
          </Trans>
        </p>
      )}

      <div className="d-flex justify-center gap-[15] w-100">
        <Button type="dashed" className="cancel" onClick={onCancel}>
          {t("purchaseConfirmModalModal.cancel")}
        </Button>

        <Button type="dashed" disabled={isLoading} onClick={onConfirm}>
          {t("purchaseConfirmModalModal.confirm")}
        </Button>
      </div>
    </ModalUi>
  );
};

export default PurchaseRestoreModal;
