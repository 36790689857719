import React, { useState } from "react";
import { Menu } from "antd";
import useAuth from "../hooks/useAuth";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Settings from "../components/Modal/SettingsModal";
import {
  IconDollarReceive,
  IconHome,
  IconLogIn,
  IconSettings,
  IconShoppingCart,
  IconLockViewfinder,
  IconDocumentList,
} from "../utils/Icons";

const SideBar = ({ isActive }) => {
  // Translation
  const { t } = useTranslation();
  const basePath = "sideBar.";
  const location = useLocation();

  const { logout, user } = useAuth();
  const [showModalSettings, setShowModalSettings] = useState(false);

  const onLogout = () => {
    logout();
  };

  const isAdmin = user?.groups?.split(", ")?.includes("admin");

  const items = [
    {
      label: <Link to="/profile">{t(`${basePath}menu.profil`)}</Link>,
      path: "/profile",
      key: "1",
      icon: <IconHome width="25" height="25" fill="#00c39a" />,
    },
    {
      label: <Link to="/refill">{t(`${basePath}menu.refill`)}</Link>,
      path: "/refill",
      key: "2",
      icon: <IconDollarReceive width="25" height="25" fill="#00c39a" />,
    },
    {
      label: <Link to="/history">{t(`${basePath}menu.history`)}</Link>,
      path: "/history",
      key: "3",
      icon: <IconShoppingCart width="25" height="25" fill="#00c39a" />,
    },
    {
      label: <Link to="/all-docs">{t(`${basePath}menu.document`)}</Link>,
      path: "/all-docs",
      key: "4",
      icon: <IconDocumentList width="25" height="25" fill="#00c39a" />,
      className: "main",
    },
    {
      label: (
        <Link
          onClick={() => {
            setShowModalSettings(true);
          }}
        >
          {t(`${basePath}menu.setting`)}
        </Link>
      ),
      key: "6",
      icon: <IconSettings width="25" height="25" fill="#00c39a" />,
    },
    isAdmin && {
      label: <Link to="/admin">{t(`${basePath}menu.admin`)}</Link>,
      path: "/admin",
      key: "7",
      icon: <IconLockViewfinder width="25" height="25" fill="#00c39a" />,
    },
    {
      label: (
        <Link to="/" onClick={onLogout}>
          {t(`${basePath}menu.logout`)}
        </Link>
      ),
      key: "8",
      icon: <IconLogIn width="25" height="25" fill="#00c39a" />,
    },
  ];

  const selectedKey = items.find((item) => item.path === location.pathname);
  const resultItems = items.reduce(
    (acc, item) => (item ? [...acc, item] : acc),
    []
  );

  return (
    <>
      <div className={`side-bar ${isActive ? "active" : ""}`}>
        <Link to="/">
          <img
            src={require("../assets/img/logo.png")}
            className="logo"
            alt=""
          />
        </Link>
        <Menu
          className="side-bar-menu"
          defaultSelectedKeys={["1"]}
          selectedKeys={[selectedKey?.key]}
          mode="inline"
          theme="dark"
          inlineCollapsed={true}
          items={resultItems}
        />
      </div>

      {/* Modal Settings */}
      <Settings
        showModalSettings={showModalSettings}
        setShowModalSettings={setShowModalSettings}
      />
    </>
  );
};

export default SideBar;
