import { Watermark } from "antd";
import SpinUi from "../components/ui/elements/SpinUi";

const withPurchase =
  (Component, { className }) =>
  (props) => {
    const { id, showWatermark, processing, type, state } = props;

    return (
      <div className="result">
        {processing && (
          <div className="result-spin">
            <SpinUi />
          </div>
        )}
        <Watermark
          content={!showWatermark && "SCANLY"}
          font={{
            fontSize: 48,
            color: state.theme === "dark" ? "#ffffff14" : "#00000014",
          }}
        >
          <div
            id={`doc-image-${type}-${id}`}
            className={`${className} ${id} ${state.theme}`}
          >
            <Watermark
              content={showWatermark && "SCANLY"}
              font={{
                fontSize: 48,
                color: state.theme === "dark" ? "#ffffff14" : "#00000014",
              }}
            >
              <Component {...props} />
            </Watermark>
          </div>
        </Watermark>
      </div>
    );
  };

export default withPurchase;
