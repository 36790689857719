import React from "react";

const IconAngleRight = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          d="M9,20a1,1,0,0,1-.707-1.707L14.586,12,8.293,5.707A1,1,0,0,1,9.707,4.293l7,7a1,1,0,0,1,0,1.414l-7,7A1,1,0,0,1,9,20Z"
        />
      </g>
    </svg>
  );
};

export default IconAngleRight;
