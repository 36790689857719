import { useQuery } from "@tanstack/react-query";
import { scanlyApi, SCANLY_API_ROUTES } from "../api";

const usePurchaseHistory = ({ params } = {}) => {
    const queryFn = async () => {
        return await scanlyApi.get(SCANLY_API_ROUTES.SALE, { params })  
    };
    
    return useQuery({
        queryKey: ["usePurchaseHistory", params?.page, params?.user_id],
        queryFn,
    });
};

export default usePurchaseHistory;
