import React from "react";

const IconFilter = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M4,4.5V6.757a1.5,1.5,0,0,0,.439,1.061l5.122,5.121A1.5,1.5,0,0,1,10,14v4l4,3V14a1.5,1.5,0,0,1,.439-1.061l5.122-5.121A1.5,1.5,0,0,0,20,6.757V4.5A1.5,1.5,0,0,0,18.5,3H5.5A1.5,1.5,0,0,0,4,4.5Z"
        />
      </g>
    </svg>
  );
};

export default IconFilter;
