import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Input, Typography, Row, Col } from "antd";
import { InputUi, SwitchUi, SelectUi } from "../../../../components/ui";
import useUpdateUserByAdmin from "../../../../hooks/useUpdateUserByAdmin";
import { IconEyeSlash, IconEye } from "../../../../utils/Icons";

const { Title } = Typography;

const EditInfo = ({ data, refetch }) => {
  const { t } = useTranslation();
  const basePath = "user.admin.editUser.";

  const [form] = Form.useForm();

  const setDefaultValues = (data) => {
    form.setFieldsValue({
      login: data.login,
      email: data.email,
      phone: data.phone,
      password: "",
      confirm: "",
      active: data.active,
      groups: data.groups.split(", "),
    });
  };

  const { mutate, isPending } = useUpdateUserByAdmin(data.id, async () => {
    const { data } = await refetch();
    setDefaultValues(data?.data?.data[0]);
  });

  const onChange = (evt) => {
    if (["password"].includes(evt.target.name)) {
      form.validateFields();
    }

    setState((prevState) => ({
      ...prevState,
      [evt.target.name]: evt.target.value,
    }));
  };

  const [state, setState] = useState({
    login: data.login,
    email: data.email,
    phone: data.phone,
    password: "",
    confirm: "",
    active: data.active,
    groups: data.groups.split(", "),
  });

  const successForm = () => {
    const newData = {};
    for (const prop in state) {
      const value = state[prop];
      if (
        data[prop] !== state[prop] &&
        !["password", "groups", "confirm"].includes(prop)
      ) {
        newData[prop] = value;
      }

      if (prop === "password" && state.password) {
        newData[prop] = value;
      }

      if (prop === "groups" && state.groups.join(", ") !== data.groups) {
        newData[prop] = value.join(", ");
      }
    }
    mutate(newData);
  };

  useEffect(() => {
    setDefaultValues(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="box edit-form mb-30">
      <Title level={4}>{t(`${basePath}title`)}</Title>

      <Form
        form={form}
        name="register"
        layout="vertical"
        onFinish={successForm}
        autoComplete="off"
        onChange={onChange}
      >
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <InputUi
              name="login"
              label={t(`${basePath}name.label`)}
              placeholder={t(`${basePath}name.placeholder`)}
              errorMess={t(`${basePath}name.error`)}
            />
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <InputUi
              name="email"
              type="email"
              label={t(`${basePath}email.label`)}
              placeholder={t(`${basePath}email.placeholder`)}
              moreRules={{
                type: "email",
                message: t(`${basePath}email.valid`),
              }}
              errorMess={t(`${basePath}email.error`)}
            />
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <InputUi
              name="phone"
              label={t(`${basePath}phone.label`)}
              placeholder="+38 093 123 45 67"
              errorMess={t(`${basePath}phone.error`)}
              required={false}
              moreRules={{
                pattern: /\+\s*(\d\s*){12}/,
                message: t(`${basePath}phone.errorFormat`),
              }}
            />
          </Col>

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <SelectUi
              name="groups"
              mode="multiple"
              label={t(`${basePath}groups.label`)}
              onChange={(value) =>
                onChange({ target: { name: "groups", value } })
              }
              options={[
                {
                  value: "user",
                  label: "User",
                },
                {
                  value: "admin",
                  label: "Admin",
                },
              ]}
              errorMess={t(`formMain.message.requiredField`)}
            />
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <InputUi
              name="password"
              type="password"
              label={t(`${basePath}pass.label`)}
              placeholder="*******"
              errorMess={t(`${basePath}pass.error`)}
              required={false}
            />
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="confirm"
              label={t(`${basePath}passConfirm.label`)}
              dependencies={["password"]}
              rules={[
                {
                  required: false,
                  message: t(`${basePath}passConfirm.error`),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t(`${basePath}passConfirm.valid`))
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="*******"
                name="confirm"
                iconRender={(visible) =>
                  visible ? (
                    <IconEyeSlash width="20" height="20" fill="#fff" />
                  ) : (
                    <IconEye width="20" height="20" fill="#fff" />
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <SwitchUi
              name="active"
              label={t(`${basePath}userVisibility`)}
              beforeLabel={t(`${basePath}hidden`)}
              afterLabel={t(`${basePath}active`)}
              checkedIcon={<IconEye width="15" height="15" fill="#000" />}
              unCheckedIcon={
                <IconEyeSlash width="15" height="15" fill="#fff" />
              }
              onChange={(value) =>
                onChange({ target: { name: "active", value } })
              }
            />
          </Col>

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="btn-group mt-30">
              <Button type="dashed" htmlType="submit" loading={isPending}>
                {t(`${basePath}btn`)}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditInfo;
