import React from "react";
import dayjs from "dayjs";
import { IconWIFIiOS } from "../../../utils/Icons";

const HeaderPhoneUi = ({
  type,
  mode,
  timeScreen,
  battery,
  charge,
  mobConnect,
  mobNetwork,
  eSim,
  additional,
  colorBattery,
}) => {
  const renderAdditionalIcon = additional && additional !== "none" && (
    <img
      src={require(`../../../assets/img/icon/${additional}.png`)}
      alt={additional}
    />
  );

  const renderNetwork =
    mobNetwork === "5" ? (
      <div className="airplane">
        <img
          src={require("../../../assets/img/icon/airplane.png")}
          alt="airplane"
        />
      </div>
    ) : (
      <div className={`mobile-network net-${mobNetwork} e-sim-${eSim}`}>
        {Array.from({ length: 4 }).map((_, index) => (
          <span key={index} />
        ))}
        {eSim &&
          Array.from({ length: 4 }).map((_, index) => <span key={index + 4} />)}
      </div>
    );

  const renderChargeIcon = charge && (
    <img
      className="charge"
      src={require("../../../assets/img/icon/charger-ios.png")}
      alt="charger"
    />
  );

  return (
    <div className={`header-phone ${type} ${mode} ${mobConnect}`}>
      <h6 className={`time-screen ${additional ? "add" : ""}`}>
        {timeScreen
          ? timeScreen?.format
            ? timeScreen.format("HH:mm")
            : dayjs(timeScreen).format("HH:mm")
          : "00:00"}
        {additional && (
          <div className={`additional ${additional}`}>
            {renderAdditionalIcon}
          </div>
        )}
      </h6>

      {renderNetwork}

      {type === "ios" && (
        <h6 className="connection">
          <s>
            {mobConnect === "WIFI" ? (
              <IconWIFIiOS width="68" height="68" fill="#000" />
            ) : (
              mobConnect
            )}
          </s>
        </h6>
      )}

      <div className={`battery ${charge && "charge"}`}>
        <span
          style={{ width: `${battery}%` }}
          className={charge ? "green" : battery <= 20 ? "red" : ""}
        />
        <i style={{ color: charge ? "#fff" : colorBattery || "" }}>
          {battery}
          {battery < 100 && renderChargeIcon}
        </i>
      </div>
    </div>
  );
};

export default HeaderPhoneUi;
