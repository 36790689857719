import React from "react";
import { IconEye } from "../../../utils/Icons";
import { useTranslation } from "react-i18next";
import { Popover } from "antd";

const CardUi = ({
  type = "main" | "second",
  title,
  icon,
  price,
  preImg,
  date,
  discountPrice,
  priceEditable,
  ...props
}) => {
  // Translation
  const { t } = useTranslation();
  const basePath = "card.";

  const contentPopover = (
    <div className="pre-img">
      <img src={preImg} alt={title} />
    </div>
  );

  return (
    <div className={`card-item ${type}`} {...props}>
      <div className="icon">
        <span></span>
        <i>{icon}</i>
      </div>
      <div className="info">
        <h5>{title}</h5>
        <span>
          {t(`${basePath}os`)}: iOS {type === "second" && `| Date: ${date}`}
        </span>
      </div>

      {type === "main" && (
        <div className="preview">
          <Popover content={contentPopover}>
            <IconEye width="20" height="20" fill="#00c39a" />
          </Popover>
        </div>
      )}

      <div className="price">
        {t(`${basePath}price`)}:
        {discountPrice ? (
          <span className="price-value">
            <s>${price}</s> <span>${discountPrice}</span>
          </span>
        ) : (
          <span className="price-value">${price}</span>
        )}
      </div>
    </div>
  );
};

export default CardUi;
