import React from "react";

const IconPlusCircle = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <circle fill={fill} opacity={0.2} cx="12" cy="12" r="10" />
        <path
          fill={fill}
          d="M15.5,11.25H12.75V8.5a.75.75,0,0,0-1.5,0v2.75H8.5a.75.75,0,0,0,0,1.5h2.75V15.5a.75.75,0,0,0,1.5,0V12.75H15.5a.75.75,0,0,0,0-1.5Z"
        />
      </g>
    </svg>
  );
};

export default IconPlusCircle;
