import { useState, useEffect } from "react";
import axios from "axios";

export const useHistoricalCourse = (currencySymbol) => {
  const [historicalData, setHistoricalData] = useState({
    labels: [],
    data: [],
  });

  const fixUSDT = currencySymbol === "USDT" ? "USDC" : currencySymbol;

  useEffect(() => {
    const fetchHistoricalData = async () => {
      const symbol = `${fixUSDT}USDT`;
      try {
        const response = await axios.get(
          "https://api.binance.com/api/v3/klines",
          {
            params: {
              symbol,
              interval: "1h",
              limit: 24,
            },
          }
        );

        const historicalData = response.data.map((entry) => ({
          timestamp: entry[0],
          price: parseFloat(entry[4]),
        }));

        const labels = historicalData.map((entry) =>
          new Date(entry.timestamp).toLocaleDateString(undefined, {
            month: "short",
          })
        );

        const data = historicalData.map((entry) => entry.price);

        setHistoricalData({ labels, data });
      } catch (error) {
        console.error("Error fetching historical data:", error);
      }
    };

    fetchHistoricalData();
  }, [fixUSDT]);

  return historicalData;
};
