import { useMutation } from "@tanstack/react-query";
import { scanlyApi, SCANLY_API_ROUTES } from "../api";

const useEditPurchase = ({ onSuccess = () => {}, onError = () => {} } = {}) => {
    const mutation = useMutation({
        mutationFn: async ({ id, summa, deleted }) => {
            await scanlyApi.patch(SCANLY_API_ROUTES.EDIT_SALE_BY_ID`${id}`, { summa, 'delete': deleted })
        },
        onSuccess,
        onError,
    });

    return mutation;

};

export default useEditPurchase;