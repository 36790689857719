import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../components/ui";
import { monthsMedium } from "../../../../utils/monthsLists";
import { useCustomLocale } from "../../../../hooks/useCustomLocale";
import { formattedNumber } from "../../../../utils/formattedNumber";

function Result({ type, state, mobile, coin }) {
  useCustomLocale("ru", monthsMedium);

  return (
    <>
      <img
        src={require(`../../../../assets/img/generator/wallets/trustwallet/transfer/transfer-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <h2
        className={`currency ${state.operation === "rece" ? "rece" : "send"}`}
      >
        {state.operation === `rece` ? "+" : "-"}
        {formattedNumber(state.amount || 0, { max: 20 })} {state.currency.value}
      </h2>
      <h6 className="total">
        <b>&asymp;</b>{" "}
        {formattedNumber(state.amount * coin.lastPrice || 0.0, { min: 2 })} $
      </h6>
      <h6 className="date">
        {state.date ? dayjs(state.date).format("D MMM YYYY") : "1 янв. 2023"}{" "}
        г., {state.time ? dayjs(state.time).format("HH:mm") : "00:00"}
      </h6>
      <h6
        className={`status ${
          state.status === "В обработке" ? "processing" : ""
        }`}
      >
        {state.status || "_______________"}
      </h6>
      <h6 className="operation">
        {state.operation === "rece" ? "Получено" : "Отправлено"}
      </h6>
      <h6 className="receiver">
        {state.wallet?.slice(0, 7)}
        {"..."}
        {state.wallet?.slice(state.wallet?.length - 7)}
      </h6>
      <h6 className="commission">{state.commission || "0 TRX (0,00$)"}</h6>
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img trust transfer",
});
