import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import { IconAnglesRight, IconScanner } from "../../utils/Icons";
import { useTranslation } from "react-i18next";

const ExchangeInfo = () => {
  const { t } = useTranslation();
  const basePath = "home.itemList.exchanges.";

  return (
    <div className="сategories">
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="title-section">
            <span>
              <IconScanner width="24" height="24" fill="#00c39a" />
            </span>
            <h2>{t(`${basePath}title`)}</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <p>{t(`${basePath}descFirst`)}</p>
          <div className="platforms">
            <Link
              to={{ pathname: "/exchange", state: { tab: "1" } }}
              className="item"
            >
              <img
                src={require("../../assets/img/brands/binance.svg").default}
                alt=""
              />
            </Link>
            <Link
              to={{ pathname: "/exchange", state: { tab: "2" } }}
              className="item"
            >
              <img
                src={require("../../assets/img/brands/bybit.svg").default}
                alt=""
              />
            </Link>
            <Link
              to={{ pathname: "/exchange", state: { tab: "3" } }}
              className="item"
            >
              <img
                src={require("../../assets/img/brands/okx.svg").default}
                alt=""
              />
            </Link>
            <Link
              to={{ pathname: "/exchange", state: { tab: "4" } }}
              className="item"
            >
              <img
                src={require("../../assets/img/brands/htx.svg").default}
                alt=""
              />
            </Link>
          </div>
          <p>{t(`${basePath}descSecond`)}</p>

          <div className="d-flex mt-30 justify-center-md">
            <div className="btn-persv">
              <Link to="/exchange" className="ant-btn ant-btn-default">
                {t(`home.itemList.btn`)}
                <IconAnglesRight width="15" height="15" fill="#fff" />
              </Link>
            </div>
          </div>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <div className="image">
            <img
              src={
                require("../../assets/img/cryptocurrenc-exchanges.svg").default
              }
              alt=""
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ExchangeInfo;
