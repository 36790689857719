import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { scanlyApi, SCANLY_API_ROUTES } from "../api";

const useBoostBalance = ({ onSuccess, onError }) => {
    const { id } = useParams();

    const mutation = useMutation({
        mutationFn: async ({ balance }) => {
            await scanlyApi.post(SCANLY_API_ROUTES.BOOST_BALANCE, {user_id: +id, amount: balance})
        },
        onSuccess,
        onError,
        mutationKey: ["boost-balance", id],
    });

    return mutation;
};

export default useBoostBalance;
