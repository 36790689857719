import React, { useState } from "react";
import { Button, Form } from "antd";
import { useTranslation } from "react-i18next";

import { useLogin } from "./hooks";
import { InputUi } from "../../ui";
import useMessage from "../../../hooks/useMessage";


const Login = ({ close }) => {
  const { t } = useTranslation();
  const basePath = "auth.login.";
  const [form] = Form.useForm();
  const [state, setState] = useState({});
  const loginMutation = useLogin();
  const { showErrorMessage, showSuccessMessage } = useMessage();

  const successForm = () => {
    loginMutation.mutate(
      state,
      {
        onError: (err) => showErrorMessage(t(`apiErrors.${err.response.data.message}`)),
        onSuccess: () => {
          form.resetFields();
          close();
          showSuccessMessage(t("auth.messages.successLogin"));
      }
    });
  };

  const errorForm = () => {
    console.error("Error");
  };

  const onChange = (evt) => {
    setState((prevState) => ({...prevState, [evt.target.name]: evt.target.value}))
  }

  return (
    <div>
      <Form
        form={form}
        name="email"
        layout="vertical"
        onFinish={() => successForm()}
        onFinishFailed={() => errorForm()}
        autoComplete="off"
        onChange={onChange}
      >
        <InputUi
          name="email"
          label={t(`${basePath}name.label`)}
          placeholder={t(`${basePath}name.placeholder`)}
          errorMess={t(`${basePath}name.error`)}
        />

        <InputUi
          name="password"
          type="password"
          label={t(`${basePath}pass.label`)}
          placeholder="*******"
          errorMess={t(`${basePath}pass.error`)}
        />

        <div className="btn-group mt-30">
          <div className="btn-persv">
            <Button
              htmlType="submit"
              loading={loginMutation.isPending}
            >{t(`${basePath}btn`)}</Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Login;
