import React from "react";
import { Layout } from "../../components/Layout";
import AdminBar from "../../components/AdminBar";

const Admin = () => {
  return (
    <Layout section={"user"} exact={false}>
      <div className="layout admin main">
        <AdminBar />
        <img src={require("../../assets/img/admin.svg").default} alt="" />
      </div>
    </Layout>
  );
};

export default Admin;
