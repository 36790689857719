import React from "react";
import withPurchase from "../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../components/ui";
import { formattedNumber } from "../../../../utils/formattedNumber";

const Result = ({
  type,
  state,
  mobile,
  transferList,
  mainSum,
  decimalPart,
}) => {
  return (
    <>
      <img
        src={require(`../../../../assets/img/generator/bank/alfa/card/alfa-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <div className="balance">
        {formattedNumber(mainSum)}
        <span>,{decimalPart} ₽</span>
      </div>
      <div className="last-bill">{state.currentAccount || "0000"}</div>
      <div className="cards">
        {transferList &&
          transferList.map((item, i) => (
            <div className="item" key={i}>
              <img
                src={require(`../../../../assets/img/generator/bank/alfa/card/${
                  item.type || "mir"
                }.png`)}
                alt=""
              />
              <p>{item.lastCard || "0000"}</p>
            </div>
          ))}
      </div>
    </>
  );
};

export default withPurchase(Result, {
  className: `result-img alfa card`,
});
