import React from "react";

const IconBinance = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      {...props}
    >
      <g>
        <path
          fill={fill}
          d="M14.6782 20.1697L23.9997 10.8486 33.3262 20.1746 38.7501 14.7507 23.9997 0 9.25428 14.7458 14.6782 20.1697zM10.8479 23.9988L5.42408 18.575 0 23.9991 5.42381 29.4229 10.8479 23.9988zM23.9997 37.1507L14.6782 27.8296 9.24634 33.2463 23.9997 47.9993 38.7501 33.2489 38.7527 33.2459 33.3258 27.825 23.9997 37.1507zM42.5759 29.4254L48 24.0014 42.5762 18.5775 37.1521 24.0016 42.5759 29.4254z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M29.5036 23.9968H29.5013L29.5063 23.9995L29.5036 24.0025L23.9997 29.5064L18.5008 24.0074L18.4932 23.9995L23.9997 18.4929L29.5036 23.9968Z"
        />
      </g>
    </svg>
  );
};

export default IconBinance;
