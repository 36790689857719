import React from "react";

const IconAngleDown = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          d="M12,17a1,1,0,0,1-.707-.293l-7-7A1,1,0,0,1,5.707,8.293L12,14.586l6.293-6.293a1,1,0,0,1,1.414,1.414l-7,7A1,1,0,0,1,12,17Z"
        />
      </g>
    </svg>
  );
};

export default IconAngleDown;
