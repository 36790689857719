import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import { Layout } from "../../components/Layout";

import PurchaseHistoryItems from "./components/PurchaseHistoryItems";

const { Title } = Typography;

const PurchaseHistory = () => {
  const { t } = useTranslation();
  const basePath = "user.history.";
  const filter = (items) => items?.filter(item => !item.delete);

  return (
    <Layout section={"user"} exact={false}>
      <div className="layout history">
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="box">
              <Title level={4}>{t(`${basePath}title`)}</Title>
              <PurchaseHistoryItems filter={filter} />
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default PurchaseHistory;
