import React from "react";
import { Form, InputNumber } from "antd";
import { IconAngleDownSquare, IconAngleUpSquare } from "../../../utils/Icons";

const InputNumberUi = ({
  name,
  id,
  label,
  errorMess,
  formItemClassName,
  required = true,
  ...props
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: required, message: errorMess }]}
      className={formItemClassName}
    >
      <InputNumber
        id={id}
        {...props}
        controls={{
          upIcon: <IconAngleUpSquare width="14" height="14" fill="#00c39a" />,
          downIcon: (
            <IconAngleDownSquare width="14" height="14" fill="#00c39a" />
          ),
        }}
      />
    </Form.Item>
  );
};

export default InputNumberUi;
