import React from "react";

const IconAnglesLeft = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          d="M12,20a1,1,0,0,1-.707-1.707L17.586,12,11.293,5.707a1,1,0,0,1,1.414-1.414l7,7a1,1,0,0,1,0,1.414l-7,7A1,1,0,0,1,12,20Z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M5,20a1,1,0,0,1-.707-1.707L10.586,12,4.293,5.707A1,1,0,0,1,5.707,4.293l7,7a1,1,0,0,1,0,1.414l-7,7A1,1,0,0,1,5,20Z"
        />
      </g>
    </svg>
  );
};

export default IconAnglesLeft;
