const IconInfo = ({ width, height, fill, ...props }) => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill={fill}
            d="M12,22.75A10.75,10.75,0,1,1,22.75,12,10.762,10.762,0,0,1,12,22.75Zm0-20A9.25,9.25,0,1,0,21.25,12,9.26,9.26,0,0,0,12,2.75Zm.75,13.75V11.929a.75.75,0,0,0-1.5,0V16.5a.75.75,0,0,0,1.5,0Zm.27-8a1,1,0,0,0-1-1h-.01a1,1,0,1,0,1.01,1Z"
        />
    </svg>
    );
};

export default IconInfo;