import React from "react";

const IconLightbulb = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M18,9.443A6.6,6.6,0,0,0,11.692,3,6.5,6.5,0,0,0,5,9.5a7.5,7.5,0,0,0,2.092,4.776A4.707,4.707,0,0,1,8.48,17h6.04a4.53,4.53,0,0,1,1.348-2.686A7.19,7.19,0,0,0,18,9.443Z"
        />
        <path
          fill={fill}
          d="M12.25,17V13.31l1.78-1.78a.75.75,0,0,0-1.06-1.06L11.5,11.94l-1.47-1.47a.75.75,0,0,0-1.06,1.06l1.78,1.78V17H8.48c.009.105.02.21.02.322V18a2.8,2.8,0,0,0,3,3h0a2.8,2.8,0,0,0,3-3v-.683c0-.111.01-.213.019-.317Z"
        />
      </g>
    </svg>
  );
};

export default IconLightbulb;
