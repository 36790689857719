import dayjs from "dayjs";
import React from "react";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

function Result({ type, state, mobile }) {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/htx/wallet/withdrawal/htx-withdrawal-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <div className="amount">
        {state.amount || "0.00"} {state.currency}
      </div>
      <div className="wallet">{state.wallet || "_______________"}</div>
      <div className="network">{state.network || "_______"}</div>
      <div className="fee">
        {state.fee || "0.00"} {state.currency}
      </div>
      <div className="hash">{state.hash || "_______________"}</div>
      <div className="date">
        {state?.time ? dayjs(state?.time).format("HH:mm MM/DD") : "00:00 01/01"}
      </div>
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img htx wallet withdrawal",
});
