import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Table, Button, Tooltip } from "antd";
import dayjs from "dayjs";
import EditPurchasePrise from "./EditPurchasePrise";
import FilterUserDropdown from "./FilterUserDropdown";
import getDocTitle from "../../../../utils/getDocTitle";
import PurchaseDeleteModal from "../../../../components/Modal/PurchaseDeleteModal";

import {
  IconRadiation,
  IconTrash,
  IconFilter,
  IconArrowBack,
} from "../../../../utils/Icons";
import PurchaseRestoreModal from "../../../../components/Modal/PurchaseRestoreModal";

const PurchasesList = ({
  data,
  params,
  setParams,
  isLoading,
  total,
  refetch,
}) => {
  // Translation
  const { t } = useTranslation();
  const basePath = "user.admin.purchaseList.table.";

  const [deletePurchase, setDeletePurchase] = useState(null);
  const [editPurchasePrise, setEditPurchasePrise] = useState(null);
  const clearDeletePurchase = () => setDeletePurchase(null);

  const [restorePurchase, setRestorePurchase] = useState(null);
  const clearRestorePurchase = () => setRestorePurchase(null);

  const columns = [
    {
      title: t(`${basePath}title`),
      dataIndex: "doc",
      key: "title",
      render: (doc) => getDocTitle(doc),
    },
    {
      title: t(`${basePath}user`),
      dataIndex: "user_login",
      key: "user_login",
      filterDropdown: () => (
        <FilterUserDropdown
          setPurchaseParams={setParams}
          purchaseParams={params}
        />
      ),
      filterIcon: <IconFilter width="20" height="20" fill="#00c39a" />,
    },
    {
      title: t(`${basePath}price`),
      dataIndex: "summa",
      key: "prise",
      render: (prise, record) => (
        <EditPurchasePrise
          prise={prise}
          record={record}
          editPurchasePrise={editPurchasePrise}
          setEditPurchasePrise={setEditPurchasePrise}
          refetch={refetch}
        />
      ),
    },
    {
      title: t(`${basePath}deleted`),
      dataIndex: "delete",
      key: "delete",
      render: (deleted) =>
        deleted ? t(`${basePath}deleteYes`) : t(`${basePath}deleteNo`),
    },
    {
      title: t(`${basePath}createdAt`),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: t(`${basePath}actions`),
      dataIndex: "actions",
      key: "actions",
      width: 100,
      fixed: "right",
      render: (_, record) => (
        <>
          {record.delete ? (
            <span className="main">
              <Tooltip placement="top" title={t(`${basePath}restore`)}>
                <Button
                  type="text"
                  onClick={() => setRestorePurchase(record)}
                  icon={<IconArrowBack width="20" height="20" fill="#00c39a" />}
                />
              </Tooltip>
            </span>
          ) : (
            <span className="delete">
              <Tooltip placement="top" title={t(`${basePath}delete`)}>
                <Button
                  type="text"
                  onClick={() => setDeletePurchase(record)}
                  icon={<IconTrash width="20" height="20" fill="#d94c48" />}
                />
              </Tooltip>
            </span>
          )}
        </>
      ),
    },
  ];

  const onPaginationChange = (page) => {
    setParams({ ...params, page });
    setEditPurchasePrise(null);
  };

  return (
    <Row>
      <Col span={24}>
        <Table
          tableLayout="auto"
          loading={{
            spinning: isLoading,
            indicator: <IconRadiation width="46" height="46" fill="#00c39a" />,
          }}
          dataSource={data}
          columns={columns}
          pagination={{
            pageSize: params.page_size,
            current: params.page,
            total: total,
            showSizeChanger: false,
            onChange: onPaginationChange,
            position: ["bottomCenter"],
          }}
          scroll={{ x: 1300 }}
        />
      </Col>

      {deletePurchase && (
        <PurchaseDeleteModal
          showModal={Boolean(deletePurchase)}
          onCancel={clearDeletePurchase}
          purchase={deletePurchase}
          refetch={refetch}
        />
      )}

      {restorePurchase && (
        <PurchaseRestoreModal
          showModal={Boolean(restorePurchase)}
          onCancel={clearRestorePurchase}
          purchase={restorePurchase}
          refetch={refetch}
        />
      )}
    </Row>
  );
};

export default PurchasesList;
