import React, { useState, useEffect } from "react";
import { Col, Row, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { Layout } from "../../components/Layout";
import { useLocation } from "react-router-dom";
import OKXList from "./OKX/OKXList";
import HTXList from "./HTX/HTXList";
import BybitList from "./Bybit/BybitList";
import BinanceList from "./Binance/BinanceList";
import {
  IconBinance,
  IconBybit,
  IconHTX,
  IconOKX,
  IconScanner,
} from "../../utils/Icons";

const Exchanges = () => {
  const { t } = useTranslation();
  const basePath = "home.itemList.exchanges.";
  const location = useLocation();
  const [activeTabKey, setActiveTabKey] = useState("1");

  useEffect(() => {
    if (location.state && location.state.tab) {
      setActiveTabKey(location.state.tab);
    }
  }, [location.state]);

  return (
    <Layout section="gen-page">
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="title-section">
            <span>
              <IconScanner width="24" height="24" fill="#00c39a" />
            </span>
            <h2>{t(`${basePath}title`)}</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Tabs
            activeKey={activeTabKey}
            onChange={setActiveTabKey}
            type="card"
            className="big-size"
          >
            <Tabs.TabPane
              tab={
                <>
                  <IconBinance width="18" height="18" fill="#fff" />
                  Binance
                </>
              }
              key="1"
            >
              <Row>
                <BinanceList />
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <>
                  <IconBybit width="18" height="18" fill="#fff" />
                  ByBit
                </>
              }
              key="2"
            >
              <Row>
                <BybitList />
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <>
                  <IconOKX width="18" height="18" fill="#fff" />
                  OKX
                </>
              }
              key="3"
            >
              <Row>
                <OKXList />
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <>
                  <IconHTX width="18" height="18" fill="#fff" />
                  HTX
                </>
              }
              key="4"
            >
              <Row>
                <HTXList />
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Layout>
  );
};

export default Exchanges;
