import React from "react";

const IconBybit = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1024 1024"
      {...props}
    >
      <g>
        <circle fill={fill} opacity={0.3} cx="512" cy="512" r="512" />
        <path
          fill={fill}
          d="M584.7 462.5h-1.2c-10.1.1-20 2.9-28.7 8.1v-67.4h-27.4v118.5c0 32.4 25.6 59.2 57.4 59.2 31.8 0 57.4-26.8 57.4-59.2-.2-33-25.1-59.2-57.5-59.2zm-1.3 88.5c-15.8-.3-28.7-13.5-28.7-29.3 0-16.2 12.5-29.9 28.7-29.9s28.7 13.7 28.7 29.9-12.5 29.3-28.7 29.3zM314 462.5c-10.3 0-20.5 2.8-29.3 8.1v-67.4H256v118.5c0 32.4 26.2 59.2 58 59.2 31.2 0 57.4-26.8 57.4-59.2 0-33-25.6-59.2-57.4-59.2zm0 88.5c-16.1 0-29.3-13.2-29.3-29.3 0-16.2 13.1-29.9 29.3-29.9 15.6 0 28.7 13.7 28.7 29.9S329.6 551 314 551zm362.3-103.5c3.7 0 7.5-1.2 10-4.4 3.1-2.5 4.4-6.2 4.4-10.6 0-3.7-1.2-7.5-4.4-10-2.5-3.1-6.2-4.4-10-4.4-3.7 0-7.5 1.2-10 4.4-3.1 2.5-4.4 6.2-4.4 10 .1 8.8 6.3 15 14.4 15M506.1 581.6c0 32.4-26.2 59.2-57.4 59.2v-29.9c16.2 0 28.7-13.1 28.7-29.3v-8.1a56.235 56.235 0 0 1-57.4 0c-17.8-10.9-28.7-30.3-28.7-51.1v-59.2H420v59.2c0 13.1 8.7 24.9 21.2 28.7 2.2.5 4.5.8 6.9.8 16.1 0 29.3-13.2 29.3-29.3v-59.4h28.7v118.4zm247.6-179v59.2H768v29.9h-14.3v88.6h-28.1v-88.6h-14.3v-29.9h14.3v-59.2h28.1zm-94.2 58h28.7v118.5h-28.7V460.6z"
        />
      </g>
    </svg>
  );
};

export default IconBybit;
