import React from "react";
import { Form, Switch } from "antd";

const SwitchUi = ({ label, name, beforeLabel, afterLabel, checkedIcon, unCheckedIcon, ...props }) => {
  return (
    <Form.Item name={name} label={label} className="custom-switch">
      {beforeLabel}
      <Switch defaultChecked checkedChildren={checkedIcon} unCheckedChildren={unCheckedIcon} {...props} />
      {afterLabel}
    </Form.Item>
  );
};

export default SwitchUi;
