import React from "react";

const IconExodus = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 591 565"
      {...props}
    >
      <g>
        <path
          fill={fill}
          d="M261.5,25.4L39,168.6l36.3,113.6L39,395.9l223.2,143.3v-79.9L119.6,368l16.5-52.2h125.5v-66.6H135.4
		l-16.5-52.2L261.5,106V26V25.4z M329.5,25.4v79.9l142.6,91.1l-16.5,52.2H329.5v66.7h126.1l16.5,52.2l-142.6,91.1v79.9L552,395.2
		l-36.3-113.6L552,168L329.5,24.8V25.4z"
        />
      </g>
    </svg>
  );
};

export default IconExodus;
