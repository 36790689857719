import React from "react";

const IconDollarReceive = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M22,12A10,10,0,0,1,11.715,22,10.11,10.11,0,0,1,2,12.285,10,10,0,0,1,12,2a9.762,9.762,0,0,1,3.747.733.305.305,0,0,1,.151.413,4.5,4.5,0,0,0,3.623,6.33,4.607,4.607,0,0,0,1.78-.164.3.3,0,0,1,.383.2A9.87,9.87,0,0,1,22,12Z"
        />
        <path
          fill={fill}
          d="M14.972,14.017a2.624,2.624,0,0,0-1.986-2.545l-1.608-.4a1.12,1.12,0,0,1-.617-.405,1.1,1.1,0,0,1-.233-.685A1.123,1.123,0,0,1,11.65,8.861h.7a1.125,1.125,0,0,1,1.116,1,.75.75,0,1,0,1.49-.167,2.617,2.617,0,0,0-2.228-2.3V7a.75.75,0,0,0-1.5,0v.4a2.618,2.618,0,0,0-.214,5.124l1.608.4a1.123,1.123,0,0,1-.272,2.212h-.7a1.125,1.125,0,0,1-1.116-1,.75.75,0,1,0-1.49.167A2.619,2.619,0,0,0,11.228,16.6V17a.75.75,0,0,0,1.5,0v-.4a2.608,2.608,0,0,0,2.244-2.585Z"
        />
        <path
          fill={fill}
          d="M22.53,4.47a.749.749,0,0,0-1.06,0l-.72.719V2a.75.75,0,0,0-1.5,0V5.189l-.72-.719a.75.75,0,0,0-1.06,1.06l2,2a.751.751,0,0,0,1.06,0l2-2A.749.749,0,0,0,22.53,4.47Z"
        />
      </g>
    </svg>
  );
};

export default IconDollarReceive;
