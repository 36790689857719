import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { PrivateRoute } from "../components/ui";
import { PRIVATE_ROUTERS, PUBLIC_ROUTERS } from "./components/RoutersArray";

function Routes() {
  const { user } = useAuth();

  return (
    <>
      <BrowserRouter>
        <Switch>
          {PUBLIC_ROUTERS.map((route, index) => (
            <Route path={route.path} exact={route.exact} key={`PUBLIC-ROUTE-${index}`}>
              {route.component}
            </Route>
          ))}

          {PRIVATE_ROUTERS.map((route, index) => (
            <PrivateRoute path={route.path} exact={route.exact} key={`PRIVATE_ROUTE-${index}`} additionalRedirectRule={() => route?.additionalRedirectRule ? route?.additionalRedirectRule({ user }) : undefined} >
              {route.component}
            </PrivateRoute>
          ))}
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Routes;
