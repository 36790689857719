import React from "react";

const IconClipboardText = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M16,5h1a2.652,2.652,0,0,1,3,3V18a2.652,2.652,0,0,1-3,3H7a2.652,2.652,0,0,1-3-3V8A2.652,2.652,0,0,1,7,5H8"
        />
        <path
          fill={fill}
          d="M16,4.5v1A1.326,1.326,0,0,1,14.5,7h-5A1.326,1.326,0,0,1,8,5.5v-1A1.326,1.326,0,0,1,9.5,3h5A1.326,1.326,0,0,1,16,4.5Z"
        />
        <path
          fill={fill}
          d="M15,12.75H9a.75.75,0,0,1,0-1.5h6a.75.75,0,0,1,0,1.5ZM13.75,16a.75.75,0,0,0-.75-.75H9a.75.75,0,0,0,0,1.5h4A.75.75,0,0,0,13.75,16Z"
        />
      </g>
    </svg>
  );
};

export default IconClipboardText;
