import React from "react";
import CoinsList from "./CoinsList";
import { IconInfo } from "../../../../../utils/Icons";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";
import { formattedNumber } from "../../../../../utils/formattedNumber";

function Result({
  type,
  state,
  balance,
  mobile,
  coins,
  coinsValues,
  usdBalance,
}) {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/bybit/wallet/main/bybit-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <div className="main-asset">
        <span>
          {formattedNumber(balance, { max: 2 })}
          <span className="currency">USD</span>
        </span>
        <span className="btc">
          ≈ {isNaN(usdBalance) ? "0.00000000" : usdBalance} BTC
          <IconInfo
            width="34px"
            height="34px"
            fill="#81858c"
            className="icon"
          />
        </span>
      </div>

      <div className="available-balance">
        <span className="value">
          {formattedNumber(balance, { max: 2 })}
          <span className="usd"> USD</span>
        </span>
        <span className="btc">
          ≈ {isNaN(usdBalance) ? "0.00000000" : usdBalance} BTC
        </span>
      </div>

      <CoinsList coins={coins} coinsValues={coinsValues} mode={state.theme} />
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img bybit wallet main",
});
