import React from "react";
import { sortedCoins } from "../../../../../utils/SortedCoins";

const CoinsList = ({ coins, coinsValues, mode }) => {
  if (!coins?.length) {
    return null;
  }

  const getSrc = (coin) =>
    mode === "dark" && coin?.icon_bybit_dark
      ? coin.icon_bybit_dark
      : coin.icon_bybit;

  return (
    <ul className="coin-list">
      {sortedCoins(coins, coinsValues).map((coin) => {
        const customNameCoin =
          coin.key === "tether"
            ? "Tether USDT"
            : coin.key === "tron"
            ? "TRON"
            : coin.title;

        return (
          <li className="item" key={coin.key}>
            <div className="info">
              <div className="icon">
                <img className="icon-img" src={getSrc(coin)} alt="" />
              </div>
              <div className="info-text">
                <span className="item__title">{coin.value}</span>
                <span className="item__name">{customNameCoin}</span>
              </div>
            </div>
            <div className="balance">
              <span className="value">
                {coinsValues[coin.key]?.amount || "0.0000"}
              </span>
              <span className="usd">
                ≈ {coinsValues[coin.key]?.totalPrice || "0.00"} USD
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default CoinsList;
