import React from "react";

const IconDollar = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M12.63,20H11.37a4.37,4.37,0,0,1-4.344-3.888,1,1,0,0,1,1.988-.224A2.372,2.372,0,0,0,11.37,18h1.26a2.371,2.371,0,0,0,.575-4.671l-2.9-.718A4.394,4.394,0,0,1,7.92,11.053,4.371,4.371,0,0,1,11.37,4h1.26a4.37,4.37,0,0,1,4.344,3.888,1,1,0,0,1-1.988.224A2.372,2.372,0,0,0,12.63,6H11.37A2.372,2.372,0,0,0,9,8.37a2.373,2.373,0,0,0,1.8,2.3l2.9.718A4.372,4.372,0,0,1,12.63,20Z"
        />
        <path
          fill={fill}
          d="M12.96,19.98V21a1,1,0,0,1-2,0V19.98c.13.01.27.02.41.02h1.26A1.829,1.829,0,0,0,12.96,19.98Z"
        />
        <path
          fill={fill}
          d="M12.96,3V4.02A1.829,1.829,0,0,0,12.63,4H11.37c-.14,0-.27.01-.41.02V3a1,1,0,0,1,2,0Z"
        />
      </g>
    </svg>
  );
};

export default IconDollar;
