import React from "react";

const IconOKX = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 266 286"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M156.8,97.7H92.1c-2.7,0-5,2.2-5,5v64.7c0,2.7,2.2,5,5,5h64.7c2.7,0,5-2.2,5-5v-64.7
	C161.8,99.9,159.5,97.7,156.8,97.7z"
        />
        <path
          fill={fill}
          d="M82.1,23H17.4c-2.7,0-5,2.2-5,5v64.7c0,2.7,2.2,5,5,5h64.7c2.8,0,5-2.2,5-5V28C87.1,25.2,84.8,23,82.1,23z"
        />
        <path
          fill={fill}
          d="M231.4,23h-64.7c-2.7,0-5,2.2-5,5v64.7c0,2.7,2.2,5,5,5h64.7c2.7,0,5-2.2,5-5V28C236.4,25.2,234.2,23,231.4,23z"
        />
        <path
          fill={fill}
          d="M82.1,172.3H17.4c-2.7,0-5,2.2-5,5V242c0,2.7,2.2,5,5,5h64.7c2.8,0,5-2.2,5-5v-64.7
	C87.1,174.6,84.8,172.3,82.1,172.3z"
        />
        <path
          fill={fill}
          d="M231.4,172.3h-64.7c-2.7,0-5,2.2-5,5V242c0,2.7,2.2,5,5,5h64.7c2.7,0,5-2.2,5-5v-64.7
	C236.4,174.6,234.2,172.3,231.4,172.3z"
        />
      </g>
    </svg>
  );
};

export default IconOKX;
