import React from "react";

const IconCPU = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M5,16V8A2.652,2.652,0,0,1,8,5h8a2.652,2.652,0,0,1,3,3v8a2.652,2.652,0,0,1-3,3H8A2.652,2.652,0,0,1,5,16Z"
        />
        <path
          fill={fill}
          d="M8.5,14V10A1.326,1.326,0,0,1,10,8.5h4A1.326,1.326,0,0,1,15.5,10v4A1.326,1.326,0,0,1,14,15.5H10A1.326,1.326,0,0,1,8.5,14Z"
        />
        <path
          fill={fill}
          d="M21.75,9a.749.749,0,0,1-.75.75H19V8.25h2A.749.749,0,0,1,21.75,9ZM21,14.25H19v1.5h2a.75.75,0,0,0,0-1.5Zm-18-6a.75.75,0,0,0,0,1.5H5V8.25Zm0,6a.75.75,0,0,0,0,1.5H5v-1.5Zm12-12a.755.755,0,0,0-.75.75V5h1.5V3A.749.749,0,0,0,15,2.25Zm-6,0A.755.755,0,0,0,8.25,3V5h1.5V3A.749.749,0,0,0,9,2.25ZM14.25,19v2a.75.75,0,0,0,1.5,0V19Zm-6,0v2a.75.75,0,0,0,1.5,0V19Z"
        />
      </g>
    </svg>
  );
};

export default IconCPU;
