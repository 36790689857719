import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "./useAuth";
import useDownload from "./useDownload";
import useMessage from "./useMessage";
import { scanlyApi, SCANLY_API_ROUTES } from "../api";
import getDocName from "../utils/getDocTitle";

const usePurchase = ({ product, onError }) => {
    const [signUpModalOpen, setSignUpModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const closeSignUpModal = () => setSignUpModalOpen(false);
    const openSignUpModal = () => setSignUpModalOpen(true);
    const { mutateUser } = useAuth();
    const { downloadImage } = useDownload();
    const { showSuccessMessage } = useMessage();
    const { t } = useTranslation();

    const [processing, setProcessing] = useState(false);
    const [ showWatermark, setShowWatermark ] = useState(true);
    const { id, type } = useParams();
    const mutation = useMutation({
        mutationFn: async (variables) => {
            setProcessing(true);
            await scanlyApi.post(SCANLY_API_ROUTES.SALE, { product_id: id, document: variables });
            showSuccessMessage(t(`formMain.message.success`));
        },
        mutationKey: ["sale", id],
        onSuccess: async () => {
            mutateUser();
            setShowWatermark(false);
            await downloadImage(`doc-image-${type}-${id}`, getDocName(product));
            setShowWatermark(true);
            setProcessing(false);
        },
        onError: (err) => {
            setProcessing(false);
            if(typeof onSuccess === "function") {
                onError(err);
            }
        }
    });

    return {
        ...mutation,
        showWatermark,
        processing,
        signUpModalOpen,
        closeSignUpModal,
        openSignUpModal,
        confirmModalOpen,
        setConfirmModalOpen,
    }
};

export default usePurchase;