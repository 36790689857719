import React from "react";

const IconExchangeHorizontal = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M3,17.625V6.375Q3,3,6.375,3h11.25Q21,3,21,6.375v11.25Q21,21,17.625,21H6.375Q3,21,3,17.625Z"
        />
        <path
          fill={fill}
          d="M13.8,16.8a.742.742,0,0,1-.53-.22.75.75,0,0,1,0-1.061L14.689,14.1H9.3a.75.75,0,0,1,0-1.5h7.2a.75.75,0,0,1,.53,1.28l-2.7,2.7A.744.744,0,0,1,13.8,16.8Zm1.65-6.15a.75.75,0,0,0-.75-.75H9.311l1.419-1.42A.75.75,0,0,0,9.67,7.421l-2.7,2.7A.75.75,0,0,0,7.5,11.4h7.2A.75.75,0,0,0,15.45,10.652Z"
        />
      </g>
    </svg>
  );
};

export default IconExchangeHorizontal;
