import React from "react";
import { sortedCoins } from "../../../../../utils/SortedCoins";
import { formattedNumber } from "../../../../../utils/formattedNumber";

const CoinsList = ({ coins, coinsValues }) => {
  if (!coins?.length) {
    return null;
  }

  return (
    <ul className="coin-list">
      {sortedCoins(coins, coinsValues).map((coin) => {
        const customNameCoin =
          coin.key === "tether"
            ? "TetherUS"
            : coin.key === "tron"
            ? "TRON"
            : coin.title;

        return (
          <li className="item" key={coin.key}>
            <div className="info">
              <div className="icon">
                <img className="icon-img" src={coin.icon_binance} alt="" />
              </div>
              <div className="info-text">
                <span className="item__title">{coin.value}</span>
                <span className="item__name">{customNameCoin}</span>
                <span className="item__amount">Средняя стоимость</span>
                <span className="item__pnl">PnL за сегодня</span>
              </div>
            </div>
            <div className="balance">
              <span className="value">
                {formattedNumber(coinsValues[coin.key]?.amount || 0, {
                  max: 20,
                })}
              </span>
              <span className="usdt">
                {formattedNumber(coinsValues[coin.key]?.totalPrice || 0, {
                  min: 2,
                  max: 20,
                })}{" "}
                USDT
              </span>
              <span className="cost">
                {formattedNumber(coin.cost || 0, { min: 2, max: 20 })} $
              </span>
              <span
                className={`pnl-item ${
                  coin.pnlItem?.charAt(0) === "-" ? "red" : "green"
                }`}
              >
                {coin.pnlItem || "+0,0 $(+0,00 %)"}
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default CoinsList;
