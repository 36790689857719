import React from "react";

const IconMoreHorizontal = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path fill={fill} d="M4.02,14a2,2,0,0,1-.01-4h.01a2,2,0,0,1,0,4Z" />
        <path
          fill={fill}
          opacity={0.6}
          d="M12.02,14a2,2,0,0,1-.01-4h.01a2,2,0,0,1,0,4Z"
        />
        <path
          fill={fill}
          opacity={0.5}
          d="M20.02,14a2,2,0,0,1-.01-4h.01a2,2,0,0,1,0,4Z"
        />
      </g>
    </svg>
  );
};

export default IconMoreHorizontal;
