import React from "react";

const IconFoldeTimes = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M21,10v7a2.652,2.652,0,0,1-3,3H6a2.652,2.652,0,0,1-3-3V7A2.652,2.652,0,0,1,6,4h4l3,3h5A2.652,2.652,0,0,1,21,10Z"
        />
        <path
          fill={fill}
          d="M13.061,13.5l1.469-1.47a.75.75,0,0,0-1.06-1.06L12,12.439,10.53,10.97a.75.75,0,0,0-1.06,1.06l1.469,1.47L9.47,14.97a.75.75,0,1,0,1.06,1.06L12,14.561l1.47,1.469a.75.75,0,0,0,1.06-1.06Z"
        />
      </g>
    </svg>
  );
};

export default IconFoldeTimes;
