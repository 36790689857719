import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../components/ui";
import { formattedNumber } from "../../../../utils/formattedNumber";

const Result = ({ type, state, mobile, codeLong, codeShort }) => {
  return (
    <>
      <img
        src={require(`../../../../assets/img/generator/bank/alfa/withdrawal/alfa-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi
        type={type}
        mode={state.theme}
        {...mobile}
        colorBattery={state.theme === "light" ? "#8d8d8d" : "#ffffff"}
      />

      <div className="type-icon">
        {state.operation === "type2" && (
          <img
            src={require(`../../../../assets/img/generator/bank/alfa/withdrawal/type-2.png`)}
            alt=""
          />
        )}
        {state.operation === "type3" && (
          <img
            src={require(`../../../../assets/img/generator/bank/alfa/withdrawal/type-3.png`)}
            alt=""
          />
        )}
      </div>
      <div className="balance">
        <b>{formattedNumber(state.amount || 0)}</b> ₽
      </div>
      <div className="code head">
        {state.senderСard?.slice(0, 6) || "______"}
        {"++++++"}
        {state.senderСard?.slice(state.senderСard?.length - 4) || "____"}{" "}
        {codeLong} /RU/
        <br />
        {"NEWCLICK_PUSH>MOSKVA "}
        <s>
          {state.fullDate
            ? dayjs(state.fullDate).format("DD.MM.YY")
            : "01.01.24"}{" "}
          {state.fullDate ? dayjs(state.fullDate).format("DD.MM.") : "01.01."}
          {"..."}
        </s>
      </div>
      <div className="date">
        {state.fullDate
          ? dayjs(state.fullDate).format("D MMMM, HH:mm")
          : "01 января, 00:00"}
      </div>
      <div className="last-card">{state.lastCard || "0000"}</div>
      <div className="code">
        {state.senderСard?.slice(0, 6) || "______"}
        {"++++++"}
        {state.senderСard?.slice(state.senderСard?.length - 4) || "____"}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{codeLong}
        &nbsp;&nbsp;&nbsp;/RU/
        <br />
        {"NEWCLICK_PUSH>MOSKVA"}
        <br />
        <s>
          {state.fullDate
            ? dayjs(state.fullDate).format("DD.MM.YY")
            : "01.01.24"}{" "}
          {state.fullDate
            ? dayjs(state.fullDate).format("DD.MM.YY")
            : "01.01.24"}{" "}
          {`${state?.amount || 0}`.replace(".", ",")}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RUR &nbsp;MCC
          {codeShort}
        </s>
      </div>
    </>
  );
};

export default withPurchase(Result, {
  className: `result-img alfa withdrawal`,
});
