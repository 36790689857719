import React from "react";

const IconGazprom = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 46.1 50"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.4}
          d="M0,25.2C0,37.8,10.4,48,23.1,48s23.1-10.2,23.1-22.8S35.9,2.5,23.1,2.5S0,12.7,0,25.2z M0.8,25.2
	c0-12.1,10-22,22.3-22s22.3,9.9,22.3,22c0,12.1-10,22-22.3,22C10.8,47.2,0.8,37.4,0.8,25.2z"
        />
        <path
          fill={fill}
          d="M7.2,19c5.9-3.8,12.5-6.1,23.6-4.1c0.6-0.3,0.9-0.7,1.1-1.4c-2.8-1-5.8-1.3-9.6-1.3
	c-3.5,0.1-9.8,1.4-13.1,3.3l1-1.3c2.4-1.4,8.5-3.1,13-3.3c3.2,0,4.9,0.1,8.7,1.1c-0.3-0.6-1.1-1-2.3-1.5C26,9,19,8.9,13.3,11.3
	c0.7-0.5,1.4-0.9,2.4-1.3c1.9-0.8,4.9-1.7,8.8-1.6c3.1,0,6.7,1.1,8.9,3.3c0.4,0.4,0.6,0.9,0.7,1.3c1.8,0.9,2.8,1.7,3.4,2.7
	c0.2,0.5,0.3,1.1,0.2,1.7c0.7,0.4,1.1,0.8,1.5,1.3c0.3,0.4,0.5,0.8,0.5,1.3c0.1,0.6,0.1,1.1-0.4,1.9c0.6,0.3,1.1,1.1,1.2,1.5
	c0.3,1.3-0.6,2.6-1.7,3.6c-1.3,1.1-4.2,2.5-6.5,3c-4.6,1.1-8.7,1.7-12,2.2c1.5,0.3,3.4,0.8,6.7,0.8c6.9,0,13-3.2,13-3.2
	s-0.1,0.4-0.3,0.8C35.2,33,28.2,34.1,25.3,34c-3.1-0.1-5.6-0.6-8.1-1.4c-0.8,0.2-1.5,0.4-2.2,0.8c3.8,1.7,6.7,2.1,9.9,2.3
	c3.3,0.3,8.8-0.6,13.4-2.2c-0.2,0.2-0.3,0.6-0.5,0.7c-2.4,1.2-7.4,2.5-9.9,2.5c-2.5,0.1-5.5,0-8.2-0.5c-2.3-0.4-4.2-1.1-6.1-2
	c-0.3,0.3-0.5,0.6-0.6,1c7.4,3.9,14.6,4,22.7,1.5l-0.7,0.7C26,41.1,19,39.7,13.6,37.1c-0.2-0.1-0.4-0.2-0.6-0.3
	c0.5,0.8,4.6,3.8,9.2,4.2c3.2,0.3,7-0.3,9.4-1.2l-1.4,0.7c-1.1,0.6-4.1,1.8-8.2,1.5c-5.5-0.4-8.6-2.8-10-4c-0.6-0.6-0.9-1.3-1.2-2.1
	c-1.2-0.6-2-1.3-2.7-2.3C7.9,33,8,32.4,8,31.8l0.1-0.5C7.6,30.9,7,30.4,6.7,30c-0.6-1-0.2-1.8,0.2-2.5c-0.3-0.4-0.6-0.7-0.8-1.1
	C6,26,5.9,25.5,6,25.1c0.1-0.8,0.6-1.6,1.1-2.2c1.2-1.4,3.1-2.4,6.4-3.5c3.2-1,4.4-1.2,6.8-1.7c2.8-0.6,6.1-0.8,8.9-1.8
	c-7.8-1.4-15.9-0.1-22.5,4.4L7.2,19z M33.7,14.3c-0.1,0.4-0.5,0.8-0.8,1.1c0.9,0.2,1.7,0.5,2.5,0.8C35.4,15.5,34.5,14.7,33.7,14.3z
	 M9.8,29.6c-0.5-0.3-0.9-0.5-1.2-0.8c-0.2,0.5,0.2,1,0.6,1.3C9.3,29.9,9.5,29.8,9.8,29.6z M36,19.6c0.6,0.3,1.1,0.5,1.5,0.8
	c0.3-0.7,0.1-1.1-0.6-1.7C36.7,19,36.4,19.3,36,19.6z M34.3,20.6c-1.1,0.5-2.8,1.1-5.1,1.7c-1.9,0.5-6.6,1.1-11.6,2.2
	c-1.6,0.3-5.9,1.4-8,3.1c0.6,0.5,0.9,0.6,1.6,0.9c0.9-0.6,2.1-1.1,3.8-1.5c3.1-0.8,5.4-1.1,8.2-1.6c2.8-0.4,7.9-1.5,10.8-2.5
	c0.9-0.3,1.8-0.8,2.4-1.3C36.3,21.4,34.4,20.5,34.3,20.6z M12.7,32.4c0.4-0.3,0.9-0.5,1.5-0.7c-0.8-0.3-1.6-0.7-2.4-1.1
	c-0.4,0.2-0.7,0.5-1,0.7C11.4,31.7,12,32,12.7,32.4z M10,32.6c0,0.5,0.3,1,1,1.5c0.1-0.3,0.2-0.5,0.4-0.7
	C10.9,33.2,10.5,32.9,10,32.6z M13.9,23.2c5.6-1.6,11.6-2.1,17.6-3.7c-2.4-0.6-3.6-0.9-6.1-0.6c-3.6,0.4-7.6,1.2-11,2.2
	c-1.3,0.4-8.3,2.3-6.3,5.1C8.1,26.2,9.3,24.6,13.9,23.2z M35.1,17.6c-0.9-0.4-2.1-0.8-3.3-1.1c-0.6,0.4-1.2,0.8-2.1,1.1
	c1.5,0.3,2.7,0.7,4,1.1C33.9,18.8,34.9,17.8,35.1,17.6z M37.9,22.9c-0.2,0.1-0.3,0.3-0.6,0.4c-1.3,1-3.3,1.7-6.1,2.5
	c-5.8,1.5-10,1.8-14.8,2.9c-0.9,0.2-1.9,0.6-2.8,0.9c0.9,0.5,1.8,0.8,2.8,1.2c1.9-0.6,2.9-0.8,5.7-1.3c1.1-0.2,4.4-0.8,6.8-1.3
	C33.2,27.5,39.5,25.5,37.9,22.9z"
        />
      </g>
    </svg>
  );
};

export default IconGazprom;
