import { useMutation } from "@tanstack/react-query";
import { scanlyApi, SCANLY_API_ROUTES } from "../api";

const useRefill = () => {
  return useMutation({
    mutationKey: ["payment"],
    mutationFn: async (data) => {
        try {
            const paymentData = await scanlyApi.post(SCANLY_API_ROUTES.PAYMENT, data)
            return paymentData
        } catch (err) {
            throw err
        }
    }
  });
};

export default useRefill;
