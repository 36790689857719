import React from "react";

const IconShoppingCart = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M19,6H6.941L6.732,4.614A2.729,2.729,0,0,0,4.01,2.25H3.75a.75.75,0,0,0,0,1.5h.26A1.243,1.243,0,0,1,5.249,4.832L6.75,15.28A1.994,1.994,0,0,0,8.73,17H17a2.579,2.579,0,0,0,2.95-2.46l1.01-6.18A2,2,0,0,0,19,6Z"
        />
        <path
          fill={fill}
          d="M9.021,22.25a1.25,1.25,0,0,1-.01-2.5h.01a1.25,1.25,0,0,1,0,2.5Z"
        />
        <path
          fill={fill}
          d="M17.021,22.25a1.25,1.25,0,0,1-.01-2.5h.01a1.25,1.25,0,0,1,0,2.5Z"
        />
        <path fill={fill} d="M20.321,12.25l-.24,1.5H13a.75.75,0,1,1,0-1.5Z" />
      </g>
    </svg>
  );
};

export default IconShoppingCart;
