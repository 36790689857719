import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconDocumentList, IconHome, IconUsersGroup, IconShoppingCart } from "../utils/Icons";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AdminBar = () => {
  const { t } = useTranslation();
  const basePath = "adminBar.";
  const location = useLocation();

  const items = [
    {
      key: "main",
      path: "/admin",
      label: <Link to="/admin">{t(`${basePath}menu.main`)}</Link>,
      icon: <IconHome width="22" height="22" fill="#fff" />,
    },
    {
      key: "docs",
      path: "/admin/docs",
      label: <Link to="/admin/docs">{t(`${basePath}menu.docs`)}</Link>,
      icon: <IconDocumentList width="22" height="22" fill="#fff" />,
    },
    {
      key: "users",
      path: "/admin/users",
      label: <Link to="/admin/users">{t(`${basePath}menu.users`)}</Link>,
      icon: <IconUsersGroup width="22" height="22" fill="#fff" />,
    },
    {
      key: "purchases",
      path: "/admin/purchases",
      label: <Link to="/admin/purchases">{t(`${basePath}menu.purchases`)}</Link>,
      icon: <IconShoppingCart width="22" height="22" fill="#fff" />,
    },
  ];

  const selectedKey = items.find((item) => item.path === location.pathname);

  return (
    <div className="admin-bar">
      <Menu
        defaultSelectedKeys={["main"]}
        selectedKeys={[selectedKey?.key]}
        mode="horizontal"
        items={items}
      />
    </div>
  );
};

export default AdminBar;
