import axios from "axios";

export const scanlyApi = axios.create({
    baseURL: "https://api.scanly.club/api/v1",
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Key": "c32b62bf65fc9cefebb334911cdc85f9"
    }
});

scanlyApi.interceptors.request.use(
    config => {
        const access_token = localStorage.getItem("access_token");
        if (access_token) {
            config.headers = { ...config.headers, "authorization": `Bearer ${access_token}` }
        }

        return config;
    },
    error => {
        console.error(error);
        return Promise.reject(error);
    }
);
