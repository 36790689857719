import React from "react";
import Chart from "react-apexcharts";
import withPurchase from "../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../components/ui";
import { formattedNumber } from "../../../../utils/formattedNumber";

const Result = ({
  type,
  state,
  mobile,
  coin,
  idCoin,
  mainSum,
  decimalPart,
  chartOptions,
  chartSeries,
  historicalData,
}) => {
  return (
    <>
      <img
        src={require(`../../../../assets/img/generator/wallets/exodus/coin/coin-${type}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi
        type={type}
        mode={state.theme}
        {...mobile}
        colorBattery={"#303250"}
      />

      <div className="name">
        {idCoin === "BNB" ? "BNB" : state.currency.title}{" "}
        {idCoin === "USDT" && "USD"}{" "}
        {idCoin !== "BNB" && `(${state.currency.value})`}
        {state.currency.chain && (
          <span className={`network ${state.chain}`}>{state.chain}</span>
        )}
      </div>
      <div className="icons">
        <img
          src={require("../../../../assets/img/generator/wallets/exodus/coin/icon-star.png")}
          alt=""
        />
        {idCoin === "BTC" && (
          <img
            src={require("../../../../assets/img/generator/wallets/exodus/coin/icon-setting.png")}
            alt=""
          />
        )}
        {idCoin === "TRX" && (
          <img
            src={require("../../../../assets/img/generator/wallets/exodus/coin/icon-add.png")}
            alt=""
          />
        )}
      </div>
      <div className="icon">
        <img src={state.currency.icon_exodus} alt="" />
        {state.currency.chain && state.chain !== "none" && (
          <span className={`network`}>
            <img
              src={require(`../../../../assets/img/cryptocurrency/exodus/${state.chain.toLowerCase()}_alt.svg`)}
              alt=""
            />
          </span>
        )}
      </div>
      <div className="price">
        <s>$</s>
        {formattedNumber(mainSum, { locale: "en" })}.
        <s>
          {mainSum > 0 ? decimalPart?.slice(0, 2) : decimalPart?.slice(0, 4)}
        </s>
      </div>
      <div className={`change-price ${coin?.priceChangePercent > 0 && "up"}`}>
        {coin?.priceChangePercent > 0 && "+"}
        {Number(coin?.priceChangePercent).toFixed(2)}%
      </div>
      <div className="chart">
        <p className="highest-price">
          <s>${formattedNumber(coin?.highPrice, { min: 2, locale: "en" })}</s>
        </p>
        <p className="lowest-price">
          <s>${formattedNumber(coin?.lowPrice, { min: 2, locale: "en" })}</s>
        </p>
        {historicalData.labels.length > 0 && (
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="line"
            height={450}
          />
        )}
      </div>
      <div
        className="amount"
        style={{
          color: state.currency.color,
        }}
      >
        {state.amount || "0"}
        <s>{state.currency.value}</s>
      </div>
      <div className="amount usd">
        $
        {formattedNumber(
          state.amount * coin?.lastPrice,
          { min: 2, locale: "en" } || "0.00"
        )}
        <s>Стоимость</s>
      </div>
      <div className="buttons">
        {idCoin === "BTC" ? (
          <img
            src={require("../../../../assets/img/generator/wallets/exodus/coin/button-type-2.png")}
            alt=""
          />
        ) : idCoin === "ETH" ? (
          <img
            src={require("../../../../assets/img/generator/wallets/exodus/coin/button-type-3.png")}
            alt=""
          />
        ) : (
          <img
            src={require("../../../../assets/img/generator/wallets/exodus/coin/button-type-1.png")}
            alt=""
          />
        )}
      </div>
      <div className="description">
        <p>{state.currency.description}</p>
        <img
          src={require("../../../../assets/img/generator/wallets/exodus/coin/social.png")}
          alt=""
        />
      </div>
    </>
  );
};

export default withPurchase(Result, {
  className: "result-img exodus coin",
});
