import React from "react";

const IconReceiptText = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M15.08,18.541l-2.019,2.02a1.5,1.5,0,0,1-2.122,0L8.92,18.541A1.5,1.5,0,0,0,6.959,18.4L5.6,19.421a1,1,0,0,1-1.6-.8V5.379a1,1,0,0,1,1.6-.8L6.959,5.6A1.5,1.5,0,0,0,8.92,5.459l2.019-2.02a1.5,1.5,0,0,1,2.122,0l2.019,2.02a1.5,1.5,0,0,0,1.961.139L18.4,4.579a1,1,0,0,1,1.6.8V18.621a1,1,0,0,1-1.6.8L17.041,18.4A1.5,1.5,0,0,0,15.08,18.541Z"
        />
        <path
          fill={fill}
          d="M16,10.75H8a.75.75,0,0,1,0-1.5h8a.75.75,0,0,1,0,1.5ZM13.75,14a.75.75,0,0,0-.75-.75H8a.75.75,0,0,0,0,1.5h5A.75.75,0,0,0,13.75,14Z"
        />
      </g>
    </svg>
  );
};

export default IconReceiptText;
