import React from "react";

const IconShieldUser = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M21,5a29.088,29.088,0,0,1-8.983-3A29.729,29.729,0,0,1,3,5v5.889c0,6.667,5.667,10,9,11.111,3.333-1.111,9-4.444,9-11.111Z"
        />
        <path
          fill={fill}
          d="M9.856,9.65a2.15,2.15,0,1,1,2.15,2.15A2.152,2.152,0,0,1,9.856,9.65ZM12.642,13H11.358A2.687,2.687,0,0,0,8.5,15.7V16a.5.5,0,0,0,.5.5h6a.5.5,0,0,0,.5-.5v-.3A2.687,2.687,0,0,0,12.642,13Z"
        />
      </g>
    </svg>
  );
};

export default IconShieldUser;
