import { useMutation } from '@tanstack/react-query';
import { useTranslation } from "react-i18next";

import useMessage from "../hooks/useMessage";
import { SCANLY_API_ROUTES, scanlyApi } from '../api';

const useUpdateProduct = (id, onSuccess) => {
    const { showErrorMessage, showSuccessMessage } = useMessage();
    const { t } = useTranslation();

    const mutationFn = async (variables) => {
        try {
            const url = SCANLY_API_ROUTES.EDIT_PRODUCT`${id}}`;
            await scanlyApi.patch(url, variables)
            showSuccessMessage(t('user.admin.docs.card.edit.updated'))
            onSuccess();
        } catch(err) {
            showErrorMessage(err?.response?.data?.message || err)
            console.error(err?.response?.data?.message || err);
        }
    }

    return useMutation({
        mutationFn,
        mutationKey: "updateProduct"
    })
}

export default useUpdateProduct;
