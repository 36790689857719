import { IconAlfabank, IconSberbank } from "../utils/Icons";

export const SBERBANK_ARRAY = [
  {
    id: 1,
    api_id: 29,
    title: "Сбербанк (Главная)",
    system: "Android | iOS",
    icon: <IconSberbank width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/bank/sberbank/main/preview.png"),
    modal: {
      ios: "/bank/sberbank/main/ios",
      android: "/bank/sberbank/main/android",
    },
  },
  {
    id: 2,
    api_id: 30,
    title: "Сбербанк (Карта)",
    system: "Android | iOS",
    icon: <IconSberbank width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/bank/sberbank/card/preview.png"),
    modal: {
      ios: "/bank/sberbank/card/ios",
      android: "/bank/sberbank/card/android",
    },
  },
  {
    id: 3,
    api_id: 31,
    title: "Сбербанк (Платежный счет)",
    system: "Android | iOS",
    icon: <IconSberbank width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/bank/sberbank/bill/preview.png"),
    modal: {
      ios: "/bank/sberbank/bill/ios",
      android: "/bank/sberbank/bill/android",
    },
  },
  {
    id: 4,
    api_id: 32,
    title: "Сбербанк (Перевод доставлен)",
    system: "Android | iOS",
    icon: <IconSberbank width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/bank/sberbank/delivered/preview.png"),
    modal: {
      ios: "/bank/sberbank/delivered/ios",
      android: "/bank/sberbank/delivered/android",
    },
  },
  {
    id: 5,
    api_id: 33,
    title: "Сбербанк (Перевод выполнен)",
    system: "Android | iOS",
    icon: <IconSberbank width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/bank/sberbank/completed/preview.png"),
    modal: {
      ios: "/bank/sberbank/completed/ios",
      android: "/bank/sberbank/completed/android",
    },
  },
];

export const ALFA_ARRAY = [
  {
    id: 1,
    api_id: 34,
    title: "Альфа-Банк (Главная)",
    system: "Android | iOS",
    icon: <IconAlfabank width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/bank/alfa/main/preview.png"),
    modal: {
      ios: "/bank/alfa/main/ios",
      android: "/bank/alfa/main/android",
    },
  },
  {
    id: 2,
    api_id: 35,
    title: "Альфа-Банк (Карта)",
    system: "Android | iOS",
    icon: <IconAlfabank width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/bank/alfa/card/preview.png"),
    modal: {
      ios: "/bank/alfa/card/ios",
      android: "/bank/alfa/card/android",
    },
  },
  {
    id: 3,
    api_id: 36,
    title: "Альфа-Банк (Пополнение)",
    system: "Android | iOS",
    icon: <IconAlfabank width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/bank/alfa/refill/preview.png"),
    modal: {
      ios: "/bank/alfa/refill/ios",
      android: "/bank/alfa/refill/android",
    },
  },
  {
    id: 4,
    api_id: 37,
    title: "Альфа-Банк (Вывод средств)",
    system: "Android | iOS",
    icon: <IconAlfabank width="24" height="24" fill="#00c39a" />,
    preImg: require("../assets/img/generator/bank/alfa/withdrawal/preview.png"),
    modal: {
      ios: "/bank/alfa/withdrawal/ios",
      android: "/bank/alfa/withdrawal/android",
    },
  },
];
