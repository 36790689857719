import React from "react";
import { DatePicker, Form } from "antd";
import {
  IconAngleLeft,
  IconAngleRight,
  IconAnglesLeft,
  IconAnglesRight,
  IconCalendar,
  IconTimesSquare,
} from "../../../utils/Icons";

const DatePickerUi = ({ name, id, label, format, errorMess, ...props }) => {
  return (
    <Form.Item name={name} label={label} rules={[{ required: true, message: errorMess }]}>
      <DatePicker
        id={id}
        format={format}
        suffixIcon={<IconCalendar width="20" height="20" fill="#00c39a" />}
        clearIcon={<IconTimesSquare width="20" height="20" fill="#d94c48" />}
        nextIcon={<IconAngleRight width="17" height="17" fill="#fff" />}
        prevIcon={<IconAngleLeft width="17" height="17" fill="#fff" />}
        superNextIcon={<IconAnglesRight width="17" height="17" fill="#fff" />}
        superPrevIcon={<IconAnglesLeft width="17" height="17" fill="#fff" />}
        {...props}
      />
    </Form.Item>
  );
};

export default DatePickerUi;
