import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";
import { formattedNumber } from "../../../../../utils/formattedNumber";

const Result = ({ type, state, mobile, coin }) => {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/binance/wallet/deposit/binance-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <h2 className="amount">{`+${state.amount || 0} ${state.currency}`}</h2>
      <div className="network">{state.network}</div>
      <p className="content">{state.wallet || "_______________"}</p>
      <p className="content txid">{state.txid || "_______________"}</p>
      <p className="content spot">Спотовый кошелек</p>
      <p className="content date">
        {state?.fullDate
          ? dayjs(state.fullDate).format("YYYY-MM-DD HH:mm:ss")
          : "2024-01-01 00:00:00"}
      </p>
      <p className="course">
        {formattedNumber(coin?.lastPrice, { min: 2 })}
        <span className={coin?.priceChangePercent > 0 ? "green" : "red"}>
          {coin?.usd_24h_change > 0 && "+"}
          {formattedNumber(coin?.priceChangePercent)}%
        </span>
      </p>
    </>
  );
};

export default withPurchase(Result, {
  className: `result-img binance wallet deposit`,
});
