import { createContext, useCallback } from "react";
import { message } from "antd";

import { IconCheckSquare, IconTimesSquare } from "../utils/Icons";

export const MessageContext = createContext(null);

const MessageProvider = ({ children }) => {
    const [messageApi, contextHolder] = message.useMessage();

    const showSuccessMessage = useCallback((content) => {
        messageApi.open({
          type: "success",
          duration: 5,
          content,
          icon: <IconCheckSquare width="18" height="18" fill="#00c39a" />,
        });
    }, [messageApi]);
    
      const showErrorMessage = useCallback((content) => {
        messageApi.open({
          type: "error",
          duration: 5,
          content,
          icon: <IconTimesSquare width="18" height="18" fill="#d94c48" />,
        });
    }, [messageApi]);

    return (
        <MessageContext.Provider value={{ messageApi, showSuccessMessage, showErrorMessage }}>
            {contextHolder}
            {children}
        </MessageContext.Provider>
    );
};

export default MessageProvider