import { useCallback, createContext, useMemo } from "react";
import useProducts from "../hooks/useProducts";
import { SUBSECTIONS_DOCS } from "../enum/subsections";

export const DocumentsContext = createContext();

const DocumentsProvider = ({ children }) => {
  const { data, refetch, dataUpdatedAt, isLoading, isFetching } = useProducts();
  const rawProducts = data?.data?.data || [];
  const products = rawProducts;

  const getAllProducts = useCallback((products) => {
    const resultProducts = products.map((subsectionProduct) => {
      const subsectionDoc = SUBSECTIONS_DOCS["All"].find(
        (subsectionDocument) => {
          return subsectionDocument.api_id === subsectionProduct.id;
        }
      );

      return { ...subsectionProduct, ...subsectionDoc };
    });

    return resultProducts;
  }, []);

  const getProductsBySubcategory = useCallback((subsection, products) => {
    const subsectionProducts = products.filter(
      (product) => product.subsection_name_en === subsection
    );
    const resultProducts = subsectionProducts.map((subsectionProduct) => {
      const subsectionDoc = SUBSECTIONS_DOCS[subsection].find(
        (subsectionDocument) => {
          return subsectionDocument.api_id === subsectionProduct.id;
        }
      );

      return { ...subsectionProduct, ...subsectionDoc };
    });

    return resultProducts;
  }, []);

  const getProductByApiId = useCallback(
    (apiId) => {
      const product = products.find((product) => {
        return product.id === apiId;
      });
      return product;
    },
    [products]
  );

  const value = useMemo(
    () => ({
      products,
      refetchProducts: refetch,
      getProductsBySubcategory,
      getProductByApiId,
      isLoading,
      isFetching,
      getAllProducts
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataUpdatedAt, isLoading, isFetching]
  );

  return (
    <DocumentsContext.Provider value={value}>
      {children}
    </DocumentsContext.Provider>
  );
};

export default DocumentsProvider;
