import React from "react";

const IconMobileNetwork = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 488 553"
      {...props}
    >
      <path
        fill={fill}
        d="M22.7,327.3h36.7c8.3,0,15,6.7,15,15v103.3c0,8.3-6.7,15-15,15H22.7c-8.3,0-15-6.7-15-15V342.3
	C7.7,334,14.4,327.3,22.7,327.3z"
      />
      <path
        fill={fill}
        d="M156,218h36.7c8.3,0,15,6.1,15,13.6V447c0,7.5-6.7,13.6-15,13.6H156c-8.3,0-15-6.1-15-13.6V231.6
	C141,224.1,147.7,218,156,218z"
      />
      <path
        fill={fill}
        d="M289.3,117H326c8.3,0,15,5.8,15,12.9v317.9c0,7.1-6.7,12.9-15,12.9h-36.7c-8.3,0-15-5.8-15-12.9V129.9
	C274.3,122.8,281,117,289.3,117z"
      />
      <path
        fill={fill}
        d="M422.7,9h36.7c8.3,0,15,5.7,15,12.7V448c0,7-6.7,12.7-15,12.7h-36.7c-8.3,0-15-5.7-15-12.7V21.7C407.7,14.7,414.4,9,422.7,9
	z"
      />
    </svg>
  );
};

export default IconMobileNetwork;
