import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../components/ui";
import { monthsShort } from "../../../../utils/monthsLists";
import { useCustomLocale } from "../../../../hooks/useCustomLocale";

const Result = ({
  type,
  state,
  mobile,
  datePeriod,
  resultTime,
  selectedDate,
  timePeriod,
}) => {
  useCustomLocale("ru", monthsShort);

  return (
    <>
      <img
        src={require(`../../../../assets/img/generator/wallets/exodus/sent/sent-${datePeriod}-${type}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi
        type={type}
        mode={state.theme}
        {...mobile}
        colorBattery={"#303250"}
      />

      <div className={`amount`}>
        {state.amount || "0"} {state.currency}
      </div>
      <div className={`fee`}>
        {state.fee || "0"} {state.currencyFee || "TRX"}
      </div>
      <div className="notes">{state.notes || <s>Добавить примеч...</s>}</div>
      <div className="date">
        {datePeriod === "now"
          ? `${resultTime}...`
          : `${dayjs(selectedDate).format("DD MMM YYYY, HH:ss")} ${timePeriod}`}
      </div>
      <div className="wallet">{state.wallet || "__________"}</div>
      <div className="txid">{state.txid || "__________"}</div>
      <div className="amount-now">${state.amountNow || "0.00"}</div>
      {datePeriod === "old" && (
        <div className="amount-old">
          <span>{dayjs(selectedDate).format("DD MMMM")}</span>
          <s>${state.amountOld || "0.00"}</s>
        </div>
      )}
    </>
  );
};

export default withPurchase(Result, {
  className: "result-img exodus sent",
});
