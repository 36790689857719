import { useEffect, useState } from "react";
import useUsers from "../../../hooks/useUsers";
import { SpinUi } from "../../../components/ui";
import { Layout } from "../../../components/Layout";
import AdminBar from "../../../components/AdminBar";

import UserList from "./components/UserList";

const Users = () => {
  const [tableData, setTableData] = useState([]);
  const [init, setInit] = useState(true);
  const [params, setParams] = useState({
    page_size: 10,
    page: 1,
  });

  const { data, isLoading, status } = useUsers({ params });
  const onSuccess = () => {
    setTableData(data?.data?.data);
    setInit(false);
  };

  useEffect(() => {
    if (status === "success") {
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, params.page, params?.search]);

  return (
    <Layout section={"user"} exact={false}>
      <div className="layout admin users-list">
        <AdminBar />
        {init && isLoading ? (
          <SpinUi />
        ) : (
          <UserList
            isLoading={isLoading}
            users={tableData}
            params={params}
            setParams={setParams}
            total={data?.data?.total || 1}
          />
        )}
      </div>
    </Layout>
  );
};
export default Users;
