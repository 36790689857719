import Home from "../../pages/Home";
import Bank from "../../pages/Bank/Bank";
import AllDocs from "../../pages/AllDocs";
import Wallets from "../../pages/Wallets/Wallets";
import Exchanges from "../../pages/Exchange/Exchanges";

// Binance Routers
import BinanceMain from "../../pages/Exchange/Binance/Wallet/Main/Generator";
import BinanceMailDeposit from "../../pages/Exchange/Binance/Mail/Deposit/Generator";
import BinanceMailWithdrawal from "../../pages/Exchange/Binance/Mail/Withdrawal/Generator";
import BinanceWalletDeposit from "../../pages/Exchange/Binance/Wallet/Deposit/Generator";
import BinanceWalletWithdrawal from "../../pages/Exchange/Binance/Wallet/Withdrawal/Generator";

// ByBit Routers
import BybitMain from "../../pages/Exchange/Bybit/Wallet/Main/Generator";
import BybitAssets from "../../pages/Exchange/Bybit/Wallet/Assets/Generator";
import BybitDeposit from "../../pages/Exchange/Bybit/Wallet/Deposit/Generator";
import BybitWithdrawal from "../../pages/Exchange/Bybit/Wallet/Withdrawal/Generator";
import BybitEmailDeposit from "../../pages/Exchange/Bybit/Mail/Deposit/Generator";
import BybitEmailWithdrawal from "../../pages/Exchange/Bybit/Mail/Withdrawal/Generator";

// OKX Routers
import OKXBalance from "../../pages/Exchange/OKX/Wallet/Balance/Generator";
import OKXDeposit from "../../pages/Exchange/OKX/Wallet/Deposit/Generator";
import OKXWithdrawal from "../../pages/Exchange/OKX/Wallet/Withdrawal/Generator";
import OKXDepositMail from "../../pages/Exchange/OKX/Mail/Deposit/Generator";
import OKXWithdrawalMail from "../../pages/Exchange/OKX/Mail/Withdrawal/Generator";

// HTX Routers
import HTXMain from "../../pages/Exchange/HTX/Wallet/Main/Generator";
import HTXDeposit from "../../pages/Exchange/HTX/Wallet/Deposit/Generator";
import HTXWithdrawal from "../../pages/Exchange/HTX/Wallet/Withdrawal/Generator";
import HTXDepositMail from "../../pages/Exchange/HTX/Mail/Deposit/Generator";
import HTXWithdrawalMail from "../../pages/Exchange/HTX/Mail/Withdrawal/Generator";

// Trust Wallet Routers
import TrustWalletMain from "../../pages/Wallets/TrustWallet/Main/Generator";
import TrustWalletCoin from "../../pages/Wallets/TrustWallet/Coin/Generator";
import TrustWalletHistory from "../../pages/Wallets/TrustWallet/History/Generator";
import TrustWalletTransfer from "../../pages/Wallets/TrustWallet/Transfer/Generator";

// Exodus Routers
import ExodusCoin from "../../pages/Wallets/Exodus/Coin/Generator";
import ExodusSent from "../../pages/Wallets/Exodus/Sent/Generator";
import ExodusReceived from "../../pages/Wallets/Exodus/Received/Generator";

// Sberbank Routers
import SberbankMain from "../../pages/Bank/Sberbank/Main/Generator";
import SberbankCard from "../../pages/Bank/Sberbank/Card/Generator";
import SberbankBill from "../../pages/Bank/Sberbank/Bill/Generator";
import SberbankDelivered from "../../pages/Bank/Sberbank/Delivered/Generator";
import SberbankCompleted from "../../pages/Bank/Sberbank/Completed/Generator";

// Alfa-Bank Routers
import AlfaMain from "../../pages/Bank/Alfa/Main/Generator";
import AlfaCard from "../../pages/Bank/Alfa/Card/Generator";
import AlfaRefill from "../../pages/Bank/Alfa/Refill/Generator";
import AlfaWithdrawal from "../../pages/Bank/Alfa/Withdrawal/Generator";

// Profile Routers
import Profile from "../../pages/User/Profile";
import PurchaseHistory from "../../pages/User/PurchaseHistory";
import Refill from "../../pages/User/Refill";

// Admin Routers
import Admin from "../../pages/Admin/Admin";
import Docs from "../../pages/Admin/Docs/Docs";
import Users from "../../pages/Admin/Users/Users";
import UserPage from "../../pages/Admin/UserPage/UserPage";
import PurchasesList from "../../pages/Admin/Purchases/PurchasesList";

export const PUBLIC_ROUTERS = [
  // Main Pages
  {
    path: "/",
    component: <Home />,
    exact: true,
  },
  {
    path: "/all-docs",
    component: <AllDocs />,
    exact: true,
  },
  {
    path: "/exchange",
    component: <Exchanges />,
    exact: true,
  },
  {
    path: "/wallets",
    component: <Wallets />,
    exact: true,
  },
  {
    path: "/bank",
    component: <Bank />,
    exact: true,
  },
  // Binance
  {
    path: "/exchange/binance/wallet/main/:type/:id",
    component: <BinanceMain />,
    exact: false,
  },
  {
    path: "/exchange/binance/wallet/deposit/:type/:id",
    component: <BinanceWalletDeposit />,
    exact: false,
  },
  {
    path: "/exchange/binance/wallet/withdrawal/:type/:id",
    component: <BinanceWalletWithdrawal />,
    exact: false,
  },
  {
    path: "/exchange/binance/mail/deposit/:type/:id",
    component: <BinanceMailDeposit />,
    exact: false,
  },
  {
    path: "/exchange/binance/mail/withdrawal/:type/:id",
    component: <BinanceMailWithdrawal />,
    exact: false,
  },
  // ByBit
  {
    path: "/exchange/bybit/wallet/main/:type/:id",
    component: <BybitMain />,
    exact: false,
  },
  {
    path: "/exchange/bybit/wallet/assets/:type/:id",
    component: <BybitAssets />,
    exact: false,
  },
  {
    path: "/exchange/bybit/wallet/deposit/:type/:id",
    component: <BybitDeposit />,
    exact: false,
  },
  {
    path: "/exchange/bybit/wallet/withdrawal/:type/:id",
    component: <BybitWithdrawal />,
    exact: false,
  },
  {
    path: "/exchange/bybit/mail/deposit/:type/:id",
    component: <BybitEmailDeposit />,
    exact: false,
  },
  {
    path: "/exchange/bybit/mail/withdrawal/:type/:id",
    component: <BybitEmailWithdrawal />,
    exact: false,
  },
  // OKX
  {
    path: "/exchange/okx/wallet/balance/:type/:id",
    component: <OKXBalance />,
    exact: false,
  },
  {
    path: "/exchange/okx/wallet/deposit/:type/:id",
    component: <OKXDeposit />,
    exact: false,
  },
  {
    path: "/exchange/okx/wallet/withdrawal/:type/:id",
    component: <OKXWithdrawal />,
    exact: false,
  },
  {
    path: "/exchange/okx/mail/deposit/:type/:id",
    component: <OKXDepositMail />,
    exact: false,
  },
  {
    path: "/exchange/okx/mail/withdrawal/:type/:id",
    component: <OKXWithdrawalMail />,
    exact: false,
  },
  // HTX
  {
    path: "/exchange/htx/wallet/main/:type/:id",
    component: <HTXMain />,
    exact: false,
  },
  {
    path: "/exchange/htx/wallet/deposit/:type/:id",
    component: <HTXDeposit />,
    exact: false,
  },
  {
    path: "/exchange/htx/wallet/withdrawal/:type/:id",
    component: <HTXWithdrawal />,
    exact: false,
  },
  {
    path: "/exchange/htx/mail/deposit/:type/:id",
    component: <HTXDepositMail />,
    exact: false,
  },
  {
    path: "/exchange/htx/mail/withdrawal/:type/:id",
    component: <HTXWithdrawalMail />,
    exact: false,
  },
  // Trust Wallet
  {
    path: "/wallets/trustwallet/main/:type/:id",
    component: <TrustWalletMain />,
    exact: false,
  },
  {
    path: "/wallets/trustwallet/coin/:type/:id",
    component: <TrustWalletCoin />,
    exact: false,
  },
  {
    path: "/wallets/trustwallet/transfer/:type/:id",
    component: <TrustWalletTransfer />,
    exact: false,
  },
  {
    path: "/wallets/trustwallet/history/:type/:id",
    component: <TrustWalletHistory />,
    exact: false,
  },
  // Exodus
  {
    path: "/wallets/exodus/coin/:type/:id",
    component: <ExodusCoin />,
    exact: false,
  },
  {
    path: "/wallets/exodus/sent/:type/:id",
    component: <ExodusSent />,
    exact: false,
  },
  {
    path: "/wallets/exodus/received/:type/:id",
    component: <ExodusReceived />,
    exact: false,
  },
  // Sberbank
  {
    path: "/bank/sberbank/main/:type/:id",
    component: <SberbankMain />,
    exact: false,
  },
  {
    path: "/bank/sberbank/card/:type/:id",
    component: <SberbankCard />,
    exact: false,
  },
  {
    path: "/bank/sberbank/bill/:type/:id",
    component: <SberbankBill />,
    exact: false,
  },
  {
    path: "/bank/sberbank/delivered/:type/:id",
    component: <SberbankDelivered />,
    exact: false,
  },
  {
    path: "/bank/sberbank/completed/:type/:id",
    component: <SberbankCompleted />,
    exact: false,
  },
  // Alfa-Bank
  {
    path: "/bank/alfa/main/:type/:id",
    component: <AlfaMain />,
    exact: false,
  },
  {
    path: "/bank/alfa/card/:type/:id",
    component: <AlfaCard />,
    exact: false,
  },
  {
    path: "/bank/alfa/refill/:type/:id",
    component: <AlfaRefill />,
    exact: false,
  },
  {
    path: "/bank/alfa/withdrawal/:type/:id",
    component: <AlfaWithdrawal />,
    exact: false,
  },
];

export const PRIVATE_ROUTERS = [
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/history",
    component: <PurchaseHistory />,
  },
  {
    path: "/refill",
    component: <Refill />,
  },
  {
    path: "/admin",
    component: <Admin />,
    exact: true,
  },
  {
    path: "/admin/docs",
    component: <Docs />,
    exact: false,
    additionalRedirectRule: (variables) =>
      !variables.user?.groups?.split(", ")?.includes("admin"),
  },
  {
    path: "/admin/users",
    component: <Users />,
    exact: true,
    additionalRedirectRule: (variables) =>
      !variables.user?.groups?.split(", ")?.includes("admin"),
  },
  {
    path: "/admin/purchases",
    component: <PurchasesList />,
    exact: true,
    additionalRedirectRule: (variables) =>
      !variables.user?.groups?.split(", ")?.includes("admin"),
  },
  {
    path: "/admin/users/:id",
    component: <UserPage />,
    exact: true,
    additionalRedirectRule: (variables) =>
      !variables.user?.groups?.split(", ")?.includes("admin"),
  },
];
