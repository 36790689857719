import { Form, Select } from "antd";
import React from "react";
import { IconAngleDownSquare } from "../../../utils/Icons";

const SelectUi = ({ name, id, label, errorMess, ...props }) => {
  return (
    <Form.Item name={name} label={label} rules={[{ required: true, message: errorMess }]}>
      <Select id={id} {...props} suffixIcon={<IconAngleDownSquare width="16" height="16" fill="#00c39a" />} />
    </Form.Item>
  );
};

export default SelectUi;
