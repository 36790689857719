import React from "react";

const IconDocumentList = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          d="M17,10h2.5A1.5,1.5,0,0,1,21,11.5V19a2.015,2.015,0,0,1-2,2,2.006,2.006,0,0,1-2-2Z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M6,21a2.652,2.652,0,0,1-3-3V6A2.652,2.652,0,0,1,6,3h8a2.652,2.652,0,0,1,3,3V19a2,2,0,0,0,2,2Z"
        />
        <path
          fill={fill}
          d="M14,8.75H10.5a.75.75,0,0,1,0-1.5H14a.75.75,0,0,1,0,1.5ZM14.75,12a.75.75,0,0,0-.75-.75H10.5a.75.75,0,0,0,0,1.5H14A.75.75,0,0,0,14.75,12Zm0,4a.75.75,0,0,0-.75-.75H10.5a.75.75,0,0,0,0,1.5H14A.75.75,0,0,0,14.75,16ZM7.03,9.03l1-1A.75.75,0,0,0,6.97,6.97L6.5,7.44A.75.75,0,0,0,5.47,8.53l.5.5a.748.748,0,0,0,1.06,0Zm0,4,1-1a.75.75,0,1,0-1.06-1.06l-.471.47A.75.75,0,0,0,5.47,12.53l.5.5a.748.748,0,0,0,1.06,0Zm0,4,1-1a.75.75,0,1,0-1.06-1.06l-.471.47A.75.75,0,0,0,5.47,16.53l.5.5a.748.748,0,0,0,1.06,0Z"
        />
      </g>
    </svg>
  );
};

export default IconDocumentList;
