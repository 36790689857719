export const SCANLY_API_ROUTES = {
    SIGN_UP: "/user-create",
    LOGIN: "/login",
    USER: "/user",
    PAYMENT: "/payment",
    PRODUCT: "/product",
    EDIT_PRODUCT: (_, id) => `product/${id}`,
    SALE: "/sale",
    EDIT_SALE_BY_ID: (_, id) => `sale/${id}`,
    USERS: "/users",
    EDIT_USER_BY_ADMIN: (_, id) => `user/${id}`,
    BOOST_BALANCE: '/boost-balance',
};
