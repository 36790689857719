import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

function Result({ type, state, mobile }) {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/okx/mail/deposit/okx-refill-mail-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />
      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <div className="time-arrival">
        Вы получили платеж в {state?.currency}
        <span>Входящие</span>
      </div>

      <div className="time">
        {state.timeMail ? dayjs(state.timeMail).format("HH:mm") : "00:00"}
      </div>
      <div className="mail-title">Вы получили платеж в {state?.currency}</div>
      <div className="mail-text">
        <span className="bold">
          {`${state?.amount || 0}`.replace(".", ",")} {state?.currency}
        </span>{" "}
        зачислено на ваш аккаунт в{" "}
        <span className="bold">
          {state?.time
            ? dayjs(state?.time).format("DD.MM.YYYY, HH:mm:ss")
            : "2024-01-01 00:00:00"}{" "}
          {state?.utc ? `(${state?.utc})` : "___"}.
        </span>
      </div>
      <div className="mail-uid">{state?.UID || "_______________"}</div>
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img okx mail deposit",
});
