import React from "react";

const IconEyeSlash = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g id="eye-slash">
        <path
          fill={fill}
          opacity={0.3}
          d="M20.59,10.52a13.974,13.974,0,0,0-1.076-1.556.305.305,0,0,0-.451-.027l-9.3,9.3a.3.3,0,0,0,.13.5A8.591,8.591,0,0,0,12,19c4.61,0,7.36-3.47,8.59-5.52A2.861,2.861,0,0,0,20.59,10.52Z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M17.25,6.75A8.784,8.784,0,0,0,12,5C7.39,5,4.64,8.47,3.41,10.52a2.861,2.861,0,0,0,0,2.96,13.011,13.011,0,0,0,3.34,3.77Z"
        />
        <path
          fill={fill}
          d="M21.53,2.47a.749.749,0,0,0-1.06,0L13.88,9.059A3.462,3.462,0,0,0,12,8.5a3.244,3.244,0,0,0-1.04.18,1.666,1.666,0,0,1,.31.96,1.63,1.63,0,0,1-1.63,1.63,1.666,1.666,0,0,1-.96-.31A3.244,3.244,0,0,0,8.5,12a3.462,3.462,0,0,0,.559,1.88L2.47,20.47a.75.75,0,1,0,1.06,1.06l18-18A.749.749,0,0,0,21.53,2.47Z"
        />
      </g>
    </svg>
  );
};

export default IconEyeSlash;
