export const sortedCoins = (coins, coinsValues) => {
  const coinsWithValues = coins.filter(
    (coin) => coinsValues[coin.key]?.amount > 0
  );
  const coinsWithoutValues = coins.filter(
    (coin) => coinsValues[coin.key]?.amount <= 0
  );

  coinsWithValues.sort(
    (a, b) => coinsValues[b.key].totalPrice - coinsValues[a.key].totalPrice
  );

  coinsWithoutValues.sort((a, b) => a.key.localeCompare(b.key));

  return [...coinsWithValues, ...coinsWithoutValues];
};
