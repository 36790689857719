import React from "react";

const IconCreditCard = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          d="M21,8v2H3V8A2.652,2.652,0,0,1,6,5H18A2.652,2.652,0,0,1,21,8Z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M21,10v6a2.652,2.652,0,0,1-3,3H6a2.652,2.652,0,0,1-3-3V10Z"
        />
        <path
          fill={fill}
          d="M10,15.75H7a.75.75,0,0,1,0-1.5h3a.75.75,0,0,1,0,1.5Z"
        />
      </g>
    </svg>
  );
};

export default IconCreditCard;
