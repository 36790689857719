import { Col } from "antd";
import { useTranslation } from "react-i18next";
import { IconEdit } from "../../../utils/Icons";

const DocCard = ({ product, onSetProduct }) => {
  // Translation
  const { t } = useTranslation();
  const basePath = "user.admin.docs.card.";

  return (
    <>
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <div className={`card-item edit`}>
          <div className="info" onClick={() => onSetProduct(product)}>
            <h5>{product.name_ru}</h5>
            <span>{product.name_en}</span>
          </div>

          <div className="preview" onClick={() => onSetProduct(product)}>
            <IconEdit width="20" height="20" fill="#00c39a" />
          </div>

          <div className="price">
            <div className="price-item">
              {t(`${basePath}price`)}
              <span className="price-value">
                <s>${product.price}</s>
              </span>
            </div>
            <div className="price-item">
              {t(`${basePath}priceDiscount`)}
              <span className={`price-value discount`}>
                <s>
                  {product.price_with_discount === null ? (
                    t(`${basePath}discountNone`)
                  ) : (
                    <>$ {product.price_with_discount}</>
                  )}
                </s>
              </span>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default DocCard;
