import { Layout } from "../components/Layout";

const withLayout = (Component, section) => (props) => {
    return (
        <Layout section={section}>
            <Component {...props} />
        </Layout>
    )
};

export default withLayout;
