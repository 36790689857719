import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

const Result = ({ type, state, mobile }) => {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/binance/mail/withdrawal/binance-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <h4 className="time-arrival">
        [Binance] {state.currency} Withdrawal <br /> Successful -{" "}
        {state.fullDate
          ? dayjs(state.fullDate).format("YYYY-MM-DD HH:mm:ss")
          : "2024-01-01 00:00:00"}
        (UTC)
        <span>Входящие</span>
      </h4>
      <h6 className="time-mail">
        {state.timeMail ? dayjs(state.timeMail).format("HH:mm") : "00:00"}
      </h6>
      <h5 className="status">{state.currency} Withdrawal Successful</h5>
      <p className="text">
        You've successfully withdrawn {state.amount || 0} {state.currency} from
        your account.
        <br />
        <br />
        <b>Withdrawal Address :</b>
        <br />
        {state.wallet || "_______________"}
        <br />
        <s>
          <b>Transaction ID : </b>
          {state.transaction || "_______________"}
        </s>
      </p>
      <p className="text v2">
        Don't recognize this activity? Please <span>reset your password</span>{" "}
        and contact <span>customer support</span> immediately.
        <br />
        <br />
        Please check with the receiving platform or wallet as the transaction is
        already confirmed on the blockchain explorer.
      </p>
      <p className="text v3">
        This is an automated message, please do not reply.
      </p>
    </>
  );
};

export default withPurchase(Result, {
  className: `result-img binance mail withdrawal`,
});
