import React from "react";

const IconInvoice = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M6,21a2.652,2.652,0,0,1-3-3V6A2.652,2.652,0,0,1,6,3h8a2.652,2.652,0,0,1,3,3V19a2,2,0,0,0,2,2Z"
        />
        <path
          fill={fill}
          d="M17,10h2.5A1.5,1.5,0,0,1,21,11.5V19a2.015,2.015,0,0,1-2,2,2.006,2.006,0,0,1-2-2Z"
        />
        <path
          fill={fill}
          d="M13,14.75H7a.75.75,0,0,1,0-1.5h6a.75.75,0,0,1,0,1.5Z"
        />
        <path
          fill={fill}
          d="M10,17.75H7a.75.75,0,0,1,0-1.5h3a.75.75,0,0,1,0,1.5Z"
        />
        <path
          fill={fill}
          d="M10.321,9.71A1.868,1.868,0,0,0,8.939,7.894l-.93-.24a.338.338,0,0,1-.184-.128A.4.4,0,0,1,7.75,7.29a.355.355,0,0,1,.333-.373h.4a.35.35,0,0,1,.331.331.75.75,0,0,0,1.492-.163,1.859,1.859,0,0,0-1.288-1.58v0a.75.75,0,0,0-1.5,0v.015A1.867,1.867,0,0,0,6.25,7.29a1.892,1.892,0,0,0,.378,1.14,1.839,1.839,0,0,0,1,.676l.929.24a.368.368,0,0,1,.259.364.392.392,0,0,1-.107.272.314.314,0,0,1-.226.1h-.4a.355.355,0,0,1-.331-.331.75.75,0,0,0-1.491.163,1.857,1.857,0,0,0,1.262,1.572V11.5a.75.75,0,0,0,1.5,0v-.007a1.812,1.812,0,0,0,.771-.47A1.884,1.884,0,0,0,10.321,9.71Z"
        />
      </g>
    </svg>
  );
};

export default IconInvoice;
