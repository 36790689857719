import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

function Result({ type, state, mobile }) {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/bybit/wallet/withdrawal/bybit-withdrawal-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <div className="amount">
        {state?.amount || 0} {state?.currency || "USDT"}
      </div>

      <div className="details">
        <div className="account">
          {state?.depositAccount || "_______________"}
        </div>
        <div className="commission">{state?.commission || "0.00"}</div>
        <div className="network">{state?.network || "______"}</div>
        <div className="time">
          {state?.date
            ? dayjs(state?.date).format("YYYY-MM-DD H:mm:ss")
            : "2024-01-01 00:00:00"}
        </div>
        <div className="address">{state?.address || "_______________"}</div>
        <div className="hash">{state?.hash || "_______________"}</div>
      </div>
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img bybit wallet withdrawal",
});
