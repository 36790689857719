import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import useMessage from "../hooks/useMessage";
import { SCANLY_API_ROUTES, scanlyApi } from "../api";

const useUpdateUserByAdmin = (id, onSuccess) => {
  const { showErrorMessage, showSuccessMessage } = useMessage();
  const { t } = useTranslation();

  const mutationFn = async (variables) => {
    try {
      const url = SCANLY_API_ROUTES.EDIT_USER_BY_ADMIN`${id}}`;
      await scanlyApi.patch(url, variables);
      await onSuccess();
      showSuccessMessage(t("user.admin.editUser.updateSuccess"));
    } catch (err) {
      showErrorMessage(err?.response?.data?.message || err);
      console.error(err?.response?.data?.message || err);
    }
  };

  return useMutation({
    mutationFn,
    mutationKey: "updateProduct",
  });
};

export default useUpdateUserByAdmin;
