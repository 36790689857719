import React from "react";

const IconCheckSquare = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M3,17.625V6.375Q3,3,6.375,3h11.25Q21,3,21,6.375v11.25Q21,21,17.625,21H6.375Q3,21,3,17.625Z"
        />
        <path
          fill={fill}
          d="M10.833,15.083a.751.751,0,0,1-.53-.219L7.97,12.53a.75.75,0,0,1,1.06-1.06l1.8,1.8L14.97,9.136A.75.75,0,0,1,16.03,10.2l-4.666,4.667A.751.751,0,0,1,10.833,15.083Z"
        />
      </g>
    </svg>
  );
};

export default IconCheckSquare;
