import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Tooltip } from "antd";
import { InputUi, SelectUi } from "../../../../components/ui";
import { IconCheckSquare, IconTrash } from "../../../../utils/Icons";

const DynamicForm = ({ transferList, setTransferList }) => {
  // Translation
  const { t } = useTranslation();
  const basePath = "bank.form.alfa.cards.";

  const handleChange = (e, index) => {
    const { id, value } = e.target;
    const list = [...transferList];
    list[index][id] = value;
    setTransferList(list);
  };

  const handleRemove = (index) => {
    const updatedList = transferList.filter((_, i) => i !== index);
    setTransferList(updatedList);
  };

  const handleAdd = () => {
    setTransferList([...transferList, {}]);
  };

  return (
    <>
      {transferList.map((item, index) => (
        <div key={index} className={`transfer-list alfa`}>
          <div className="item">
            <Row>
              <Col lg={12} md={12} sm={24} xs={24}>
                <InputUi
                  id={"lastCard"}
                  name={`lastCard-${index}`}
                  label={t(`${basePath}lastCard`)}
                  placeholder={"0000"}
                  value={item.lastCard}
                  onChange={(e) => handleChange(e, index)}
                  errorMess={t(`formMain.message.requiredField`)}
                />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <SelectUi
                  id={"type"}
                  name={`type-${index}`}
                  label={t(`${basePath}type`)}
                  placeholder={"Visa"}
                  value={item.type}
                  onChange={(e) =>
                    handleChange({ target: { value: e, id: "type" } }, index)
                  }
                  options={[
                    { value: "mir", label: `Мир` },
                    { value: "visa", label: `Visa` },
                  ]}
                  errorMess={t(`formMain.message.requiredField`)}
                />
              </Col>
            </Row>
          </div>
          <div className="second-division">
            {transferList.length - 1 === index && transferList.length < 3 && (
              <Button type="button" onClick={handleAdd} className="btn-st">
                <IconCheckSquare width="20" height="20" fill="#00c39a" />
                {t(`${basePath}btn.add`)}
              </Button>
            )}
            {transferList.length !== 1 && (
              <Tooltip title={t(`${basePath}btn.remove`)}>
                <Button
                  type="button"
                  onClick={() => handleRemove(index)}
                  className="btn-remove"
                >
                  <IconTrash width="20" height="20" fill="#d94c48" />
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default DynamicForm;
