import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  IconBezier,
  IconBolt,
  IconCPU,
  IconLeaf,
  IconShieldUser,
  IconSlidersVertical,
} from "../../utils/Icons";

function Advantages({ itemsIcon }) {
  const { t } = useTranslation();
  const basePath = "home.advantages.";
  const items = t(`${basePath}items`, { returnObjects: true });

  return (
    <div className="advantages">
      <Row align="middle">
        <Col lg={14} md={24} sm={24}>
          <Row align="middle">
            {items.map((item, i) => (
              <Col xl={12} lg={12} md={12} sm={24} xs={24} key={i}>
                <div className="item">
                  <div className="icon-element h-[55px] w-[55px]">
                    <span></span>
                    <i className="h-[52px] w-[52px]">{itemsIcon[item.icon]}</i>
                  </div>
                  <h4>{item.title}</h4>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col lg={10} md={24} sm={24} xs={24}>
          <img src={require("../../assets/img/advantage.svg").default} alt="" />
        </Col>
      </Row>
    </div>
  );
}

Advantages.propTypes = {
  itemsIcon: PropTypes.objectOf(PropTypes.node),
};

Advantages.defaultProps = {
  itemsIcon: {
    flex: <IconBezier width="22" height="22" fill="#00c39a" />,
    cont: <IconSlidersVertical width="22" height="22" fill="#00c39a" />,
    auto: <IconCPU width="22" height="22" fill="#00c39a" />,
    conv: <IconLeaf width="22" height="22" fill="#00c39a" />,
    fast: <IconBolt width="22" height="22" fill="#00c39a" />,
    supp: <IconShieldUser width="22" height="22" fill="#00c39a" />,
  },
};

export default Advantages;
