import React from "react";

const IconAngleUpSquare = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M3,17.625V6.375Q3,3,6.375,3h11.25Q21,3,21,6.375v11.25Q21,21,17.625,21H6.375Q3,21,3,17.625Z"
        />
        <path
          fill={fill}
          d="M15,13.75a.744.744,0,0,1-.53-.22L12,11.061,9.53,13.53a.75.75,0,0,1-1.06-1.06l3-3a.749.749,0,0,1,1.06,0l3,3A.75.75,0,0,1,15,13.75Z"
        />
      </g>
    </svg>
  );
};

export default IconAngleUpSquare;
