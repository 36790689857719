import React from "react";

const IconAlfabank = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 46.1 50"
      {...props}
    >
      <g transform="translate(214.4713,492.7487)">
        <path
          fill={fill}
          opacity={0.4}
          d="M-175.4-449.4h-31.5c-0.3,0-0.5,0.2-0.5,0.5v5.7c0,0.3,0.2,0.5,0.5,0.5h31.5c0.3,0,0.5-0.2,0.5-0.5v-5.7
			C-174.9-449.2-175.1-449.4-175.4-449.4z"
        />
        <path
          fill={fill}
          d="M-205.9-461.2c0.3,0.1,5,1.9,5.2,2c0.3,0.1,0.6-0.1,0.6-0.3c0.1-0.2,2-5.5,2.3-6.1h13.3
			c0.2,0.6,2.2,5.9,2.2,6.1c0.1,0.3,0.4,0.4,0.6,0.3c0.3-0.1,4.9-1.9,5.2-2c0.3-0.1,0.4-0.5,0.3-0.7c-0.3-0.7-9.2-24.9-9.6-26
			c-0.9-2.4-2-4.9-5.4-4.9c-3.5,0-4.6,2.5-5.5,4.9c-0.5,1.2-9.3,25.1-9.6,26C-206.3-461.6-206.2-461.3-205.9-461.2z M-191.2-484.8
			L-191.2-484.8l4.6,13.3h-9.1L-191.2-484.8z"
        />
      </g>
    </svg>
  );
};

export default IconAlfabank;
