import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

const Result = ({ type, state, mobile }) => {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/binance/wallet/withdrawal/binance-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <h2 className="amount">{`-${state.amount || 0} ${state.currency}`}</h2>
      <div className="network">{state.network}</div>
      <p className="content">{state.wallet || "_______________"}</p>
      <p className="content txid">{state.txid || "_______________"}</p>
      <p className="content entry-amount">
        {state.amount + state.commission || 0} {state.currency}
      </p>
      <p className="content commission">
        {state.commission || 0} {state.currency}
      </p>
      <p className="content spot">Спотовый кошелек</p>
      <p className="content date">
        {state.fullDate
          ? dayjs(state.fullDate).format("YYYY-MM-DD HH:mm:ss")
          : "2024-01-01 00:00:00"}
      </p>
    </>
  );
};

export default withPurchase(Result, {
  className: "result-img binance wallet withdrawal",
});
