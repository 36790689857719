import React from "react";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";

function Banner() {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.banner.";

  return (
    <div className="banner">
      <Row align="middle">
        <Col lg={12} md={12} sm={24} xs={24}>
          <img src={require("../../assets/img/banner.svg").default} alt="" />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <div className="d-flex justify-center-md">
            <h1>
              scanly
              <span></span>
            </h1>
          </div>
          <p>{t(`${basePath}desc.first`)}</p>
          <p>{t(`${basePath}desc.second`)}</p>
          <div className="d-flex mt-30 justify-center-md">
            <div className="btn-persv">
              <Button href="/all-docs">{t(`${basePath}btn`)}</Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Banner;
