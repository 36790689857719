import { sortedCoins } from "../../../../utils/SortedCoins";

const CurrencyList = ({ coins, coinsValues }) => {
  if (!coins?.length) {
    return null;
  }

  const getTag = (coin) => {
    if ("USDT" === coin.value) {
      return <span className="currency-item-tag">Tron</span>;
    }
  };

  return (
    <div className="currency-list">
      {sortedCoins(coins, coinsValues).map((coin) => {
        const isUp = coin.changePercent > 0 ? true : false;

        return (
          <div className="currency-item">
            <img className="currency-item-icon" src={coin.icon_trust} alt="" />
            <div className="currency-item-info">
              <div className="currency-item-name">
                {coin.value}
                {getTag(coin)}
              </div>
              <div className="currency-item-course">
                {Number(coin.price)
                  ?.toFixed(2)
                  .replace(".", ",")
                  .replace(".", ",")}{" "}
                $
                <span
                  className={`currency-item-course-delta ${
                    isUp ? "up" : "down"
                  }`}
                >
                  {isUp ? "+" : ""}
                  {Number(coin.changePercent)?.toFixed(2).replace(".", ",")}%
                </span>
              </div>
            </div>
            <div className="currency-item-balance">
              <div className="currency-item-balance-amount">
                {coinsValues[coin.key]?.amount || "0"}
              </div>
              <div className="currency-item-balance-usd">
                {coinsValues[coin.key]?.totalPrice.replace(".", ",") ||
                  "0.00".replace(".", ",")}{" "}
                $
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CurrencyList;
