import React from "react";

const IconArrowBack = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M16.5,19H6a1,1,0,0,1,0-2H16.5a3.5,3.5,0,0,0,0-7H3A1,1,0,0,1,3,8H16.5a5.5,5.5,0,0,1,0,11Z"
        />
        <path
          fill={fill}
          d="M6,13a1,1,0,0,1-.707-.293l-3-3a1,1,0,0,1,0-1.414l3-3A1,1,0,0,1,6.707,6.707L4.414,9l2.293,2.293A1,1,0,0,1,6,13Z"
        />
      </g>
    </svg>
  );
};

export default IconArrowBack;
