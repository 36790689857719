import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../components/ui";
import { formattedNumber } from "../../../../utils/formattedNumber";

const Result = ({ type, state, mobile, codeLong, codeShort }) => {
  return (
    <>
      <img
        src={require(`../../../../assets/img/generator/bank/alfa/refill/alfa-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi
        type={type}
        mode={state.theme}
        {...mobile}
        colorBattery={state.theme === "light" ? "#8d8d8d" : "#ffffff"}
      />

      <div className="balance">
        <b>{formattedNumber(state.amount || 0)}</b> ₽
      </div>
      <div className="date">
        {state.fullDate
          ? dayjs(state.fullDate).format("D MMMM, HH:mm")
          : "01 января, 00:00"}
      </div>
      <div className="last-card">{state.lastCard || "0000"}</div>
      <div className="code">
        {state.senderСard?.slice(0, 6) || "______"}
        {"++++++"}
        {state.senderСard?.slice(state.senderСard?.length - 4) || "____"}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{codeLong}
        &nbsp;&nbsp;&nbsp;&nbsp;/RU/
        <br />
        {"CARD2CARD AMOBILE>MOSKVA"}
        <br />
        <s>
          {state.fullDate
            ? dayjs(state.fullDate).format("DD.MM.YY")
            : "01.01.24"}{" "}
          {state.fullDate
            ? dayjs(state.fullDate).format("DD.MM.YY")
            : "01.01.24"}{" "}
          {`${state?.amount || 0}`.replace(".", ",")}&nbsp;&nbsp;&nbsp;RUR
        </s>
        <br />
        MMC{codeShort}
      </div>
    </>
  );
};

export default withPurchase(Result, {
  className: `result-img alfa refill`,
});
