import React from "react";

const IconBarcode = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24" />
        <path
          d="M13,5 L15,5 L15,20 L13,20 L13,5 Z M5,5 L5,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,6 C2,5.44771525 2.44771525,5 3,5 L5,5 Z M16,5 L18,5 L18,20 L16,20 L16,5 Z M20,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,19 C22,19.5522847 21.5522847,20 21,20 L20,20 L20,5 Z"
          id="Combined-Shape"
          fill={fill}
        />
        <polygon
          id="Path"
          fill={fill}
          opacity={0.3}
          points="9 5 9 20 7 20 7 5"
        />
      </g>
    </svg>
  );
};

export default IconBarcode;
