import React from "react";
import { sortedCoins } from "../../../../../utils/SortedCoins";

const CoinsList = ({ coins, coinsValues }) => {
  if (!coins?.length) {
    return null;
  }

  const getSrc = (coin) => coin?.icon_okx || coin?.icon_bybit;

  return (
    <ul className="coin-list">
      {sortedCoins(coins, coinsValues).map((coin) => {
        return (
          <li className="item" key={coin.key}>
            <div className="info">
              <div className="icon">
                <img className="icon-img" src={getSrc(coin)} alt="" />
              </div>
              <div className="info-text">
                <span className="item__title">{coin.value}</span>
              </div>
            </div>
            <div className="coin-balance">
              <div
                className={`balance-value ${
                  !coinsValues[coin.key]?.amount && "balance-value-single"
                }`}
              >
                {coinsValues[coin.key]?.amount || "0,0000"}
              </div>
              {Boolean(coinsValues[coin.key]?.amount) && (
                <div className="balance-usd">
                  ${`${coinsValues[coin.key]?.totalPrice}`.replace(".", ",")}
                </div>
              )}
            </div>
            <div className="dots">
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default CoinsList;
