import React from "react";

const IconScanQR = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M21,8.75A.75.75,0,0,1,20.25,8V4.5c0-.589-.161-.75-.75-.75H16a.75.75,0,0,1,0-1.5h3.5A2.067,2.067,0,0,1,21.75,4.5V8A.75.75,0,0,1,21,8.75ZM3.75,8V4.5c0-.589.161-.75.75-.75H8a.75.75,0,0,0,0-1.5H4.5A2.067,2.067,0,0,0,2.25,4.5V8a.75.75,0,0,0,1.5,0Zm5,13A.75.75,0,0,0,8,20.25H4.5c-.589,0-.75-.161-.75-.75V16a.75.75,0,0,0-1.5,0v3.5A2.067,2.067,0,0,0,4.5,21.75H8A.75.75,0,0,0,8.75,21Zm13-1.5V16a.75.75,0,0,0-1.5,0v3.5c0,.589-.161.75-.75.75H16a.75.75,0,0,0,0,1.5h3.5A2.067,2.067,0,0,0,21.75,19.5Z"
        />
        <path
          fill={fill}
          d="M13,10.25V7.75A.663.663,0,0,1,13.75,7h2.5a.663.663,0,0,1,.75.75v2.5a.663.663,0,0,1-.75.75h-2.5A.663.663,0,0,1,13,10.25ZM7.75,11h2.5a.663.663,0,0,0,.75-.75V7.75A.663.663,0,0,0,10.25,7H7.75A.663.663,0,0,0,7,7.75v2.5A.663.663,0,0,0,7.75,11Zm6,6h2.5a.663.663,0,0,0,.75-.75v-2.5a.663.663,0,0,0-.75-.75h-2.5a.663.663,0,0,0-.75.75v2.5A.663.663,0,0,0,13.75,17Zm-6,0h2.5a.663.663,0,0,0,.75-.75v-2.5a.663.663,0,0,0-.75-.75H7.75a.663.663,0,0,0-.75.75v2.5A.663.663,0,0,0,7.75,17Z"
        />
      </g>
    </svg>
  );
};

export default IconScanQR;
