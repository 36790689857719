import React from "react";

const IconBezier = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M19.74,14.52a.974.974,0,0,0-.24-.02h-1a1.1,1.1,0,0,0-.26.02c-.08-2.14-.74-5.32-3.81-6.4a2.454,2.454,0,0,0,.07-.62v-1a1.014,1.014,0,0,0-.02-.25h4.17a1.95,1.95,0,0,0,0,1.5H16.79C18.6,9.16,19.64,11.49,19.74,14.52ZM9.5,7.5v-1a1.014,1.014,0,0,1,.02-.25H5.35a1.95,1.95,0,0,1,0,1.5H7.21C5.4,9.16,4.36,11.49,4.26,14.52a.974.974,0,0,1,.24-.02h1a1.1,1.1,0,0,1,.26.02c.08-2.14.74-5.32,3.81-6.4A2.454,2.454,0,0,1,9.5,7.5Z"
        />
        <path
          fill={fill}
          d="M21.5,16.5v1a1.821,1.821,0,0,1-2,2h-1a1.821,1.821,0,0,1-2-2v-1a1.821,1.821,0,0,1,2-2h1A1.821,1.821,0,0,1,21.5,16.5Zm-16-2h-1a1.821,1.821,0,0,0-2,2v1a1.821,1.821,0,0,0,2,2h1a1.821,1.821,0,0,0,2-2v-1A1.821,1.821,0,0,0,5.5,14.5Zm7-10h-1a1.821,1.821,0,0,0-2,2v1a1.821,1.821,0,0,0,2,2h1a1.821,1.821,0,0,0,2-2v-1A1.821,1.821,0,0,0,12.5,4.5Z"
        />
        <path
          fill={fill}
          d="M22.5,7a2,2,0,1,1-2-2A2,2,0,0,1,22.5,7ZM3.5,5a2,2,0,1,0,2,2A2,2,0,0,0,3.5,5Z"
        />
      </g>
    </svg>
  );
};

export default IconBezier;
