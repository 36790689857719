import { Button } from "antd";
import { useState } from "react";
import { ModalUi } from "../ui";
import { useTranslation } from "react-i18next";
import getDocTitle from "../../utils/getDocTitle";
import useDownload from "../../hooks/useDownload";
import { ENUM_COMPONENTS_TO_PRODUCT_ID } from "../../enum/product";

const PurchaseHistoryModal = ({ historyData, showModal, setShowModal }) => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const basePath = "user.history.";
  const Component = ENUM_COMPONENTS_TO_PRODUCT_ID[historyData.product_id];
  const { downloadImage } = useDownload();

  const onClick = async () => {
    setProcessing(true);
    try {
      await downloadImage(
        `doc-image-${historyData.document.type}-${historyData.id}`,
        getDocTitle(historyData)
      );
    } catch (error) {
    } finally {
      setProcessing(false);
    }
  };

  return (
    <ModalUi
      title={getDocTitle(historyData)}
      showModal={showModal}
      className="modal-purchases"
      setShowModal={() => {
        setShowModal(false);
      }}
    >
      <div className="gen-page">
        <div className="custom-row">
          <Component {...historyData.document} processing={processing} />
        </div>
      </div>

      <div className="btn-group mt-30">
        <div className="btn-persv">
          <Button onClick={onClick}>{t(`${basePath}modal.btn`)}</Button>
        </div>
      </div>
    </ModalUi>
  );
};

export default PurchaseHistoryModal;
