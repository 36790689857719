import { useQuery } from "@tanstack/react-query";
import { scanlyApi, SCANLY_API_ROUTES } from "../api";

const useProducts = () => {
    const queryFn = async () => {
        return await scanlyApi.get(SCANLY_API_ROUTES.PRODUCT)  
    };
    
    return useQuery({
        queryKey: ["useProducts"],
        queryFn,
        refetchOnWindowFocus: false,
    });
};

export default useProducts;
