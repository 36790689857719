import { Button, Tooltip } from "antd";

const ActionValue = ({ action, value, btnTxt, icon, valueProps }) => {
  return (
    <div className="action-value">
      <span {...valueProps}>{value}</span>
      <Tooltip placement="top" title={btnTxt}>
        <Button type="dashed" onClick={action}>
          {icon}
        </Button>
      </Tooltip>
    </div>
  );
};

export default ActionValue;
