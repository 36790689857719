import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import UserNavBar from "./UserNavBar";
import BreadcrumbNavigation from "./Breadcrumb";

export const Layout = ({ children, section, exact = true }) => {
  const [isSidebarActive, setSidebarActive] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarActive(!isSidebarActive);
  };

  const isHomePage = location.pathname === "/";

  return (
    <>
      {exact ? (
        <NavBar />
      ) : (
        <UserNavBar
          toggleSidebar={toggleSidebar}
          isSidebarActive={isSidebarActive}
        />
      )}

      <section className={section}>
        {!exact && <SideBar isActive={isSidebarActive} />}
        {exact && !isHomePage && <BreadcrumbNavigation />}
        {children}
      </section>
    </>
  );
};
