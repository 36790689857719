import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import {
  IconAnglesRight,
  IconExodus,
  IconTrustWallet,
  IconWallet,
} from "../../utils/Icons";
import { useTranslation } from "react-i18next";

const WalletInfo = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.itemList.wallet.";

  return (
    <div className="сategories">
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="title-section">
            <span>
              <IconWallet width="24" height="24" fill="#00c39a" />
            </span>
            <h2>{t(`${basePath}title`)}</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <div className="image">
            <img
              src={require("../../assets/img/crypto-wallets.svg").default}
              alt=""
            />
            <div className="icon-element h-[55px] w-[55px]">
              <span></span>
              <i className="h-[52px] w-[52px]">
                <IconTrustWallet width="22" height="22" fill="#00c39a" />
              </i>
            </div>
            <div className="icon-element h-[55px] w-[55px]">
              <span></span>
              <i className="h-[52px] w-[52px]">
                <IconExodus width="22" height="22" fill="#00c39a" />
              </i>
            </div>
          </div>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <p>{t(`${basePath}descFirst`)}</p>
          <div className="platforms">
            <Link
              to={{ pathname: "/wallets", state: { tab: "1" } }}
              className="item"
            >
              <img
                src={require("../../assets/img/brands/trust.svg").default}
                alt=""
              />
            </Link>
            <Link
              to={{ pathname: "/wallets", state: { tab: "2" } }}
              className="item"
            >
              <img
                src={require("../../assets/img/brands/exodus.svg").default}
                alt=""
              />
            </Link>
          </div>
          <p>{t(`${basePath}descSecond`)}</p>

          <div className="d-flex mt-30 justify-center-md">
            <div className="btn-persv">
              <Link to="/wallets" className="ant-btn ant-btn-default">
                {t(`home.itemList.btn`)}
                <IconAnglesRight width="15" height="15" fill="#fff" />
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WalletInfo;
