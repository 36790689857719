import React from "react";
import dayjs from "dayjs";
import Chart from "react-apexcharts";
import { IconArrowUp } from "../../../../utils/Icons";
import withPurchase from "../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../components/ui";
import { monthsMedium } from "../../../../utils/monthsLists";
import { formattedNumber } from "../../../../utils/formattedNumber";
import { useCustomLocale } from "../../../../hooks/useCustomLocale";

function Result({
  type,
  state,
  mobile,
  coin,
  transferList,
  chartOptions,
  chartSeries,
}) {
  useCustomLocale("ru", monthsMedium);

  return (
    <>
      <img
        src={require(`../../../../assets/img/generator/wallets/trustwallet/coin/coin-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <h6 className={`name ${state.currency.network ? "network" : ""}`}>
        {state.currency.title}
        <span>
          <b>{state.currency.coin}</b>
          <b>{state.currency.network}</b>
        </span>
      </h6>
      <img
        className={`currency-icon ${state.currency.network ? "network" : ""}`}
        src={state.currency.icon_trust}
        alt={state.currency.title}
      />
      <h2 className="currency">
        {formattedNumber(state.amount || 0, { max: 20 })} {state.currency.value}
      </h2>
      <h6 className="total">
        <b>&asymp;</b>{" "}
        {formattedNumber(state.amount * coin?.lastPrice || 0.0, { min: 2 })} $
      </h6>
      {state.currency.network && <span className="other"></span>}
      <div className="transfers">
        {transferList &&
          transferList.map((item, i) => (
            <div className={`item ${item.today ? "today" : ""}`} key={i}>
              <b>
                {item.date
                  ? dayjs(item.date).format("MMM D YYYY") ===
                    dayjs().format("MMM D YYYY")
                    ? "Сегодня"
                    : dayjs(item.date).format("MMM D YYYY") ===
                      dayjs().add(-1, "day").format("MMM D YYYY")
                    ? "Вчера"
                    : dayjs(item.date).format("MMM D YYYY")
                  : "янв. 1 2024"}
              </b>
              <div className="content">
                <div className="left-side">
                  <i className={`icon ${item.type}`}>
                    <IconArrowUp width="43" height="43" fill="#848E9C" />
                  </i>
                  <span>
                    Перевод
                    <small>
                      {item.type === "received" ? "С: " : "На: "}
                      {item.wallet?.slice(0, 7)}
                      {"..."}
                      {item.wallet?.slice(item.wallet?.length - 7)}
                    </small>
                  </span>
                </div>
                <div className={`right-side ${item.type}`}>
                  {item.type === "received" ? "+" : "-"}
                  {new Intl.NumberFormat("ru", {
                    maximumSignificantDigits: 20,
                  }).format(item.amount || 0)}{" "}
                  {state.currency.value}
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="course">
        <b>Текущая цена {state.currency.value}</b>
        <s>
          {coin?.lastPrice >= 1
            ? formattedNumber(coin?.lastPrice)
            : formattedNumber(coin?.lastPrice, { max: 5 })}{" "}
          $
          <span className={coin?.priceChangePercent > 0 ? "green" : "red"}>
            {coin?.priceChangePercent > 0 && "+"}
            {Number(coin?.priceChangePercent).toFixed(2)}%
          </span>
        </s>
        <div className="chart-wrapper">
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="line"
            height={80}
          />
        </div>
      </div>
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img trust coin",
});
