import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { binanceApi } from "../api";

const useCoinMarketRate = ({ coinIdArray } = {}) => {
  const URL = "ticker/24hr";

  const queryFn = useCallback(async () => {
    const { data } = await binanceApi.get(URL);
    return data.filter((item) => {
      const symbolWithoutUSDT = item.symbol.replace("USDT", "");
      return coinIdArray.includes(symbolWithoutUSDT);
    });
  }, [coinIdArray]);

  return useQuery({
    queryKey: ["useCoinMarketRate", coinIdArray],
    queryFn,
    refetchOnWindowFocus: false,
  });
};

export default useCoinMarketRate;
