import React from "react";
import withPurchase from "../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../components/ui";
import { IconAngleRight2 } from "../../../../utils/Icons";
import { formattedNumber } from "../../../../utils/formattedNumber";

const Result = ({ type, state, mobile, mainSum, decimalPart }) => {
  return (
    <>
      <img
        src={require(`../../../../assets/img/generator/bank/alfa/main/alfa-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi type={type} mode={state.theme} {...mobile} />

      <div className="cardholder">
        {state.cardholder || "__________"}
        <IconAngleRight2 width="58" height="58" fill="#c5c5c7" />
      </div>
      <div className="balance">
        {formattedNumber(mainSum || "0")}
        <span>,{decimalPart || "00"} ₽</span>
      </div>
      <div className="last-card">{state.lastCard || "____"}</div>
    </>
  );
};

export default withPurchase(Result, {
  className: `result-img alfa main`,
});
