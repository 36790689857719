import React from "react";

const TitlePageUi = ({ name, addonIcon, suffixIcon }) => {
  return (
    <div className="title-page">
      <h4>
        <i>{addonIcon}</i>
        <b>{name}</b>
      </h4>
      {suffixIcon}
      <span></span>
    </div>
  );
};

export default TitlePageUi;
