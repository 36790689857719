import React from "react";

const IconArrowUp = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M0 10.975L2.828 13.822L9.004 7.646V29H12.996V7.646L19.172 13.822L22 10.975L11 0L0 10.975Z"
          fill={fill}
        ></path>
      </g>
    </svg>
  );
};

export default IconArrowUp;
